<template>
    <div v-if="isLoading" class="loading-overlay"><p>Carregando...</p></div>

    <div class="form-edit-page row-space">
        <div class="row row-space">
            <div class="form-item-char30">
                <label class="label-caption">Opções de Relatório</label>
                <select v-model="opcaoSelecionada">
                    <option v-for="opcao in lsOpcoes" :key="opcao.id" :value="opcao.id">{{ opcao.descricao }}</option>
                </select>
            </div>
            <EquipamentoGrupoSelect :ckAddTodos="true" v-model:grupoSelecionado="idGrupo" />
            <EquipamentoSelect :ckAddTodos="true" :flagTipo="1" v-model:equipamentoSelecionado="idEquipamento" />
        </div>


        <div class="row row-space">
            <RegiaoSelect :ckAddTodos="true" v-model:regiaoSelecionada="idRegiao" />
            <LocalidadeSelect :ckAddTodos="true" v-model:localidadeSelecionada="idLocalidade" />
            <ServicoSelect :ckAddTodos="true" v-model:servicoSelecionado="idServico" />
        </div>


        <div class="row-end">
            <button type="button" class="buttom-icon--printer" @click="openReport" />
            <button type="button" class="buttom-icon--close" @click="closeForm" />
        </div>
    </div>

    <MensagemAlerta v-if="errorMessage" :mensagem="errorMessage" />
</template>

<script>
import { ref } from 'vue';
import { genericReport } from '@/funcoes/api';
import { globalStore } from '@/globals';
import EquipamentoGrupoSelect from '@/selects/EquipamentoGrupoSelect.vue';
import EquipamentoSelect from '@/selects/EquipamentoSelect.vue';
import LocalidadeSelect from '@/selects/LocalidadeSelect.vue';
import MensagemAlerta from '@/components/MensagemAlerta.vue';
import RegiaoSelect from '@/selects/RegiaoSelect.vue';
import ServicoSelect from '@/selects/ServicoSelect.vue';

export default{
    components: { EquipamentoGrupoSelect, EquipamentoSelect, LocalidadeSelect, MensagemAlerta, RegiaoSelect, ServicoSelect },
    emits: ['close'],
    setup(_, {emit}){
        const errorMessage = ref(null);
        const idEquipamento = ref(null);
        const idGrupo = ref(null);
        const idLocalidade = ref(null);
        const idRegiao = ref(null);
        const idServico = ref(null);
        const isLoading = ref(false);

        const lsOpcoes = ref([
            {id: 'os_executar_grupo', descricao: 'Analítico por Grupo'},
            {id: 'os_executar_grupo_sintetico', descricao: 'Sintético por Grupo'},
        ]);
        const opcaoSelecionada = ref('os_executar_grupo');

        const closeForm = () => {emit('close');}

        const openReport = async() => {
            try{
                errorMessage.value = null;
                isLoading.value = true;
                const dados = {
                    nmrelatorio: opcaoSelecionada.value,
                    idmunicipio: globalStore.idmunicipio,
                    idequipamento: idEquipamento.value,
                    idequipamentogrupo: idGrupo.value,
                    idlocalidade: idLocalidade.value,
                    idregiao: idRegiao.value,
                    idservico: idServico.value,
                    idusuario: globalStore.idusuario};
                const response = await genericReport(dados);
                const pdfBlob = new Blob([response], { type: 'application/pdf' });
                const pdfUrl = URL.createObjectURL(pdfBlob);
                window.open(pdfUrl, '_blank');
            } catch (error){errorMessage.value = `ATENÇÃO: ${error.message}`;
            } finally {isLoading.value = false;}
        }

        return { 
            errorMessage, idEquipamento, idGrupo, idLocalidade, idRegiao, idServico, isLoading, lsOpcoes, opcaoSelecionada,
            closeForm, openReport };
    }
}
</script>