<template>
    <div v-if="isLoading" class="loading-overlay"><p>Carregando...</p></div>

    <div>
        <div class="form-edit-page" style="display: flex; gap: 10px; align-items: end;">
            <div class="form-item flexible">
                <label class="label-caption">Nome/CPF Pesquisa</label>
                <input type="text" v-model="nmPesquisa" maxlength="40" v-uppercase />
            </div>
            
            <button type="button" class="buttom-icon--search" @click="lerDados" />
            <button type="button" class="buttom-icon--close" @click="closePage" />
        </div>


        <div class="margin-top10">
            <table>
                <thead>
                    <tr>
                        <th class="col-width-integer">Código</th>
                        <th>Nome</th>
                        <th class="col-width-medium">Localidade</th>
                        <th class="col-width-doc">CPF/CNPJ</th>
                        <th class="col-width-doc">Fone</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(obj, index) in lsDados" :key="obj.id" :class="{'even-row': index % 2 === 0, 'odd-row': index % 2 !== 0, 'clickabe-row': true}" @click="selecionarPessoa(obj)">
                        <td class="col-width-integer right">{{ formatInteger(obj.cdpessoa) }}</td>
                        <td>{{ obj.nmpessoa }}</td>
                        <td class="col-width-medium">{{ obj.nmlocalidade }}</td>
                        <td class="col-width-doc center">{{ obj.cpfcnpj }}</td>
                        <td class="col-width-doc center">{{ obj.fone }}</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div v-if="showPagination" class="pagination">
            <button :disabled="currentPage === 1" class="buttom-pagination--first" @click="currentPage=1; updatePaginatedData();" />
            <button :disabled="currentPage === 1" class="buttom-pagination--prior" @click="currentPage--; updatePaginatedData();" />

            <div class="field-label field-label-center">{{ currentPage }} de {{ Math.ceil(lsDadosMain.length / itemsPerPage) }}</div>

            <button :disabled="currentPage === Math.ceil(lsDadosMain.length / itemsPerPage)" class="buttom-pagination--next" @click="currentPage++; updatePaginatedData();" />
            <button :disabled="currentPage === Math.ceil(lsDadosMain.length / itemsPerPage)" class="buttom-pagination--last" @click="currentPage = Math.ceil(lsDadosMain.length / itemsPerPage); updatePaginatedData();" />
        </div>
    </div>
</template>


<script>
import { genericPost } from '@/funcoes/api'; 
import { globalStore } from '@/globals';
import { ref } from 'vue';
import { formatInteger } from '@/funcoes/utils';

export default{
    emits: ['close', 'pessoaSelecionada'],
    setup(_, { emit }){
        const isLoading = ref(false);
        const lsDados = ref([]);
        const lsDadosMain = ref([]);
        const nmPesquisa = ref('');
        const showPagination = ref(false);

        const currentPage = ref(1);
        const itemsPerPage = ref(globalStore.itensPorPagina);

        const closePage = () =>{emit('close');};

        const lerDados = async() => {
            try{
                isLoading.value = true;
                const req = await genericPost('/listas/pessoas', {idmunicipio: globalStore.idmunicipio, nmpesquisar: nmPesquisa.value});
                lsDadosMain.value = req;
                currentPage.value=1;
                showPagination.value = (lsDadosMain.value.length > itemsPerPage.value);
                updatePaginatedData();
            } catch (error){console.error('Erro leitura dos dados', error);
            } finally {isLoading.value = false;}
        }

        const selecionarPessoa = (obj) => {
            emit('pessoaSelecionada', obj);
        };

        const updatePaginatedData = () => {
            const startIndex = (currentPage.value - 1) * itemsPerPage.value;
            const endIndex = (startIndex + itemsPerPage.value);
            lsDados.value = lsDadosMain.value.slice(startIndex, endIndex);
        }

        return {
            currentPage, isLoading, itemsPerPage, lsDados, lsDadosMain, nmPesquisa, showPagination,
            closePage, formatInteger, lerDados, selecionarPessoa, updatePaginatedData };
    }
}
</script>
