<template>
    <div v-if="mensagem" class="alerta">
        {{ mensagem }}
    </div>
</template>

<script>
export default{
    name: 'MensagemAlerta',
    props: {
        mensagem: {type: String, required: true}
    }
};
</script>

<style scoped>
.alerta{
    background-color: #f9e5c8;
    color: #7a4a02;
    padding: 5px;
    border-radius: 5px;
    border: 1px solid #e0b67b;
    font-weight: bold;
    font-size: large;
    text-align: center;
}
</style>