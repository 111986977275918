<template>
    <div v-if="isLoading" class="loading-overlay"><p>Carregando...</p></div>


    <div v-if="!modoEdicao">
        <div class="form-edit-page" style="display: flex; gap: 10px; align-items: end;">
            <div class="form-item flexible">
                <label class="label-caption" for="nmPesquisa">Nome Pesquisa</label>
                <input type="text" v-model="nmPesquisa" id="nmPesquisa" maxlength="80" v-uppercase />
            </div>
            <button type="button" class="buttom-icon--search" @click="lerDados" />
            <button type="button" class="buttom-icon--add" @click="novoCadastro" />
            <button type="button" class="buttom-icon--close" @click="closePage" />
        </div>

        <div class="margin-top10">
            <table>
                <thead>
                    <tr>
                        <th class="col-width-integer">Código</th>
                        <th>Nome</th>
                        <th>Equipamento</th>
                        <th class="col-width-doc">Fone</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(obj, index) in lsDados" :key="obj.id" :class="{'even-row': index % 2 === 0, 'odd-row': index % 2 !== 0, 'clickabe-row': true}" @click="editarCadastro(obj)">
                        <td class="col-width-integer right">{{ obj.cdoperador }}</td>
                        <td>{{ obj.nmoperador }}</td>
                        <td>{{ obj.nmequipamento }}</td>
                        <td class="col-width-doc center">{{ obj.fone }}</td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div v-if="showPagination" class="pagination">
            <button :disabled="currentPage === 1" class="buttom-pagination--first" @click="currentPage=1; updatePaginatedData();" />
            <button :disabled="currentPage === 1" class="buttom-pagination--prior" @click="currentPage--; updatePaginatedData();" />

            <div class="field-label field-label-center">{{ currentPage }} de {{ Math.ceil(lsDadosMain.length / itemsPerPage) }}</div>

            <button :disabled="currentPage === Math.ceil(lsDadosMain.length / itemsPerPage)" class="buttom-pagination--next" @click="currentPage++; updatePaginatedData();" />
            <button :disabled="currentPage === Math.ceil(lsDadosMain.length / itemsPerPage)" class="buttom-pagination--last" @click="currentPage = Math.ceil(lsDadosMain.length / itemsPerPage); updatePaginatedData();" />
        </div>
    </div>

    <div v-if="modoEdicao">
        <OperadorEditPage :idEdicao="idEdicao" @close="modoEdicao = false" @salvo="lerDados" />
    </div>
</template>


<script>
import { genericPost } from '@/funcoes/api'; 
import { globalStore } from '@/globals';
import { onMounted, ref } from 'vue';
import OperadorEditPage from '../formularios/OperadorEditPage.vue';

export default{
    components: { OperadorEditPage },
    emits:['close'],
    setup(_, { emit }){
        const idEdicao = ref(0);
        const isLoading = ref(false);
        const lsDados = ref([]);
        const modoEdicao = ref(false);
        const nmPesquisa = ref('');

        const currentPage = ref(1);
        const itemsPerPage = ref(globalStore.itensPorPagina);
        const lsDadosMain = ref([]);
        const showPagination = ref(false);

        const closePage = () =>{emit('close');};

        const editarCadastro = (obj) => {
            idEdicao.value = obj.id;
            modoEdicao.value = true;
        };

        const lerDados = async() => {
            try{
                isLoading.value = true;
                const dados = {
                    idmunicipio: globalStore.idmunicipio, 
                    nmpesquisar: nmPesquisa.value};
                const req = await genericPost('/listas/operadores', dados);
                lsDadosMain.value = req;
                currentPage.value=1;
                showPagination.value = (lsDadosMain.value.length > itemsPerPage.value);
                updatePaginatedData();
            } catch (error){console.error('Erro leitura dos dados', error);
            } finally {isLoading.value = false;}
        }

        const novoCadastro = () => {
            idEdicao.value = 0;
            modoEdicao.value = true;
        }

        const updatePaginatedData = () => {
            const startIndex = (currentPage.value - 1) * itemsPerPage.value;
            const endIndex = (startIndex + itemsPerPage.value);
            lsDados.value = lsDadosMain.value.slice(startIndex, endIndex);
        }

        onMounted(()=>{lerDados();})

        return { 
            currentPage, idEdicao, isLoading, itemsPerPage, lsDados, lsDadosMain, modoEdicao, nmPesquisa, showPagination, 
            closePage, editarCadastro, lerDados, novoCadastro, updatePaginatedData };
    }
}
</script>
