<template>
    <div v-if="isLoading" class="loading-overlay"><p>Carregando...</p></div>

    <MensagemAlerta class="row-space" v-if="errorMessage" :mensagem="errorMessage" />

    <div class="form-edit-page row-space">
        <div class="row row-space">
            <div class="form-item-codigo">
                <label class="label-caption">Cobrança</label>
                <div class="field-label field-label-end">{{ formatInteger(objView.cdcobranca) }}</div>
            </div>

            <div class="form-item-data">
                <label class="label-caption">Data</label>
                <div class="field-label field-label-center">{{ formatDateFromJson(objView.dtcobranca) }}</div>
            </div>

            <div class="form-item ">
                <label class="label-caption">Nome</label>
                <div class="field-label">{{ objView.nmpessoa }}</div>
            </div>

            <div class="form-item-char20">
                <label class="label-caption">CPF/CNPJ</label>
                <div :class="{
                    'field-label-alert': !objView.cpfcnpj,
                    'field-label': objView.cpfcnpj,
                    'field-label-center': true
                }">{{ objView.cpfcnpj }}</div>
            </div>

            <div class="form-item-char10">
                <label class="label-caption">CEP</label>
                <div :class="{
                    'field-label-alert': !objView.cep,
                    'field-label': objView.cep,
                    'field-label-center': true
                }">{{ objView.cep }}</div>
            </div>
        </div>


        <div class="row-start">
            <div class="form-item-char20">
                <label class="label-caption">Valor Total</label>
                <div class="field-label field-label-end">{{ formatDecimal(objView.vltotal) }}</div>
            </div>

            <div class="form-item-char20">
                <label class="label-caption">À Receber</label>
                <div class="field-label field-label-end">{{ formatDecimal(objView.vlareceber) }}</div>
            </div>

            <button @click="gerarBoleto">Gerar Boletos</button>
            <button type="button" @click="closeForm">Retornar</button>
        </div>
    </div>

    <table>
        <thead><tr>
            <th class="col-width-integer">Parcela</th>
            <th class="col-width-date">Vencimento</th>
            <th class="col-width-date">Pagamento</th>
            <th class="col-width-char30">Título</th>
            <th>Situação</th>
            <th class="col-width-number">Valor</th>
            <th class="col-width-number">À Receber</th>
        </tr></thead>
        <tbody>
            <tr v-for="(obj, index) in lsParcelas" :key="obj.id" :class="{'even-row': index % 2 === 0, 'odd-row': index % 2 !== 0}">
                <td class="col-width-integer right">{{ obj.parcela }}</td>
                <td class="col-width-date center">{{ formatDateFromJson(obj.dtvencimento) }}</td>
                <td class="col-width-date center">{{ formatDateFromJson(obj.dtpagamento) }}</td>
                <td class="col-width-char30">{{ obj.nrotitulo }}</td>
                <td>{{ obj.nmsituacao }}</td>
                <td class="col-width-number right">{{ formatDecimal(obj.vlparcela) }}</td>
                <td class="col-width-number right">{{ formatDecimal(obj.vlareceber) }}</td>
            </tr>
        </tbody>
    </table>
</template>

<script>
import { onMounted, ref } from 'vue';
import { formatDateFromJson, formatDecimal, formatInteger } from '@/funcoes/utils';
import { genericPost, getCarneBoletoPdf } from '@/funcoes/api';
import { globalStore } from '@/globals';
import MensagemAlerta from '@/components/MensagemAlerta.vue';

export default{
    components:{ MensagemAlerta },
    emits:['close','save'],
    props:{idView: {type: Number, default: 0}},
    setup(props, {emit}){
        const emitSave= ref(false);
        const errorMessage = ref(null);
        const isLoading = ref(false);
        const lsParcelas = ref([]);
        const objView = ref({});
        
        const closeForm = () => {
            emit('close'); 
            if (emitSave.value){emit('save')}
        }

        const gerarBoleto = async() =>{
            try{
                isLoading.value=true;
                errorMessage.value=null;

                // REGISTRAR BOLETOS PENDENTES
                for (const parc of lsParcelas.value){
                    if ((parc.nrotitulo == null) && (errorMessage.value==null)){
                        const r = await genericPost('/cobranca_api/incluir', {
                            idmunicipio: globalStore.idmunicipio,
                            id: parc.id, 
                            idlogin: globalStore.idlogin,
                            idusuario: globalStore.idusuario});
                        if (r.bolok==0){
                            errorMessage.value=r.erro;
                        }
                    }
                }

                // SE TODOS REGISTRADOS SEM ERRO ENTÃO CHAMA RELATÓRIO EM PDF
                const response = await getCarneBoletoPdf(objView.value.id);
                const pdfBlob = new Blob([response], { type: 'application/pdf' });
                const pdfUrl = URL.createObjectURL(pdfBlob);
                window.open(pdfUrl, '_blank');
            }catch (error){errorMessage.value=error;
            }finally{isLoading.value=false;}

        }

        const lerDados = async() => {
            try{
                isLoading.value = true;
                const req = await genericPost('/financeiro/cobrancas/lista', {idmunicipio: globalStore.idmunicipio, id: props.idView});
                Object.assign(objView.value, req[0]);
                if (objView.value){
                    const parc = await genericPost('/financeiro/lista/parcelas', {idmunicipio: globalStore.idmunicipio, intparam2: props.idView});
                    lsParcelas.value = parc;
                }
            }catch (error){alert(error);
            }finally{isLoading.value = false;}
        }

        onMounted(() => {lerDados()});

        return{
            errorMessage, 
            isLoading,
            lsParcelas,
            objView, 
            
            closeForm, 
            formatDateFromJson, 
            formatDecimal,
            gerarBoleto, 
            formatInteger}
    }
}
</script>