import { reactive } from 'vue';

export const criarLogin = () => {
    return reactive({
        id: 0,
        tipologin: 0,
        login: '',
        senha: '',
        appname: '',
        appver: '',
        idusuario: 0,
        idoperador: 0,
        idpessoa: 0,
        nome: '',
        email: '',
        fone: '',
        urlfoto: '',
        idmunicipio: 0,
        nmmunicipio: '',
        idfornecedor: 0,
        nmfornecedor: '',
        ckcalculohorimetro: '',
        ckoperadorossmall: '',
        nivel: 0,
        dtsenha: '',
        bolsenhaexpirada: 0,
        token: '',
        datacad: ''
    });
};