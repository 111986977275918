<template>
    <div v-if="isLoading" class="loading-overlay"><p>Carregando...</p></div>

    <div class="form-edit-page">
        <form @submit.prevent="submitForm">
            <div class="row row-space">
                <div class="form-item-char10">
                    <label class="label-caption">Código</label>
                    <div class="field-label field-label-end">{{ formatInteger(objEdicao.cdcobr_convenio) }}</div>
                </div>
                
                <div class="form-item">
                    <label class="label-caption">Identificação</label>
                    <input type="text" v-model="objEdicao.identificacao" required maxlength="100" v-uppercase />
                </div>

                <div class="form-item-char15">
                    <label class="label-caption">Cedente</label>
                    <input type="text" class="input-right" v-model="edCodigoCedente" v-number maxlength="12" />
                </div>

                <div class="form-item-char15">
                    <label class="label-caption">Convênio</label>
                    <input type="text" class="input-right" v-model="edConvenio" v-number maxlength="12" />
                </div>
            </div>


            <div class="row row-space">
                <div class="form-item">
                    <label class="label-caption">Nome Beneficiário</label>
                    <input type="text" v-model="objEdicao.nmempresa" required maxlength="100" v-uppercase />
                </div>

                <div class="form-item-char20">
                    <label class="label-caption">CNPJ</label>
                    <input type="text" class="input-center" v-model="objEdicao.cpfcnpj" required maxlength="18" v-uppercase />
                </div>

                <div class="form-item">
                    <label class="label-caption">Endereço</label>
                    <input type="text" v-model="objEdicao.endereco" required maxlength="100" v-uppercase />
                </div>

                <div class="form-item-char10">
                    <label class="label-caption">CEP</label>
                    <input type="text" class="input-center" v-model="objEdicao.cep" v-cep maxlength="10" />
                </div>
            </div>


            <div class="row row-space">
                <div class="form-item-char05">
                    <label class="label-caption">Banco</label>
                    <input type="text" class="input-right" v-model="edBanco" v-number maxlength="3" />
                </div>

                <div class="form-item-char05">
                    <label class="label-caption">DV</label>
                    <input type="text" class="input-center" v-model="objEdicao.banco_dv" v-cep maxlength="1" />
                </div>

                <div class="form-item">
                    <label class="label-caption">Nome Banco</label>
                    <input type="text" v-model="objEdicao.nmbanco" required maxlength="100" v-uppercase />
                </div>

                <div class="form-item-char10">
                    <label class="label-caption">Carteira</label>
                    <input type="text" class="input-right" v-model="edCarteira" v-number maxlength="3" />
                </div>

                <div class="form-item-char10">
                    <label class="label-caption">Variação</label>
                    <input type="text" class="input-right" v-model="edVariacao" v-number maxlength="3" />
                </div>

                <div class="form-item-char10">
                    <label class="label-caption">Agência</label>
                    <input type="text" class="input-right" v-model="edAgencia" v-number maxlength="5" />
                </div>

                <div class="form-item-char05">
                    <label class="label-caption">DV</label>
                    <input type="text" class="input-center" v-model="objEdicao.agencia_dv" v-cep maxlength="1" />
                </div>

                <div class="form-item-char15">
                    <label class="label-caption">Conta</label>
                    <input type="text" class="input-right" v-model="edConta" v-number maxlength="10" />
                </div>

                <div class="form-item-char05">
                    <label class="label-caption">DV</label>
                    <input type="text" class="input-center" v-model="objEdicao.conta_dv" v-cep maxlength="1" />
                </div>
            </div>


            <div class="row row-space">
                <div class="form-item-char15">
                    <label class="label-caption">Dias Protesto</label>
                    <input type="text" class="input-right" v-model="edDiasProtesto" v-number maxlength="2" />
                </div>

                <div class="form-item-char15">
                    <label class="label-caption">Dias Baixa</label>
                    <input type="text" class="input-right" v-model="edDiasBaixa" v-number maxlength="2" />
                </div>

                <div class="form-item">
                    <label class="label-caption">Local Pagamento</label>
                    <input type="text" v-model="objEdicao.localpagamento" required maxlength="100" v-uppercase />
                </div>

                <div class="form-item-char15">
                    <label class="label-caption">% Juros</label>
                    <input type="text" class="input-right" v-model="edAlJuros" v-number maxlength="5" />
                </div>

                <div class="form-item-char15">
                    <label class="label-caption">% Multa</label>
                    <input type="text" class="input-right" v-model="edAlMulta" v-number maxlength="5" />
                </div>
            </div>


            <div class="row row-space">
                <div class="form-item">
                    <label class="label-caption">Mensagem Padrão</label>
                    <textarea v-model="objEdicao.msg" maxlength="1000" rows="5" style="resize: none;"/>
                </div>
            </div>
                        


            <div class="row-end">
                <label for="ckAtivo">Ativo</label>
                <input id="ckAtivo" type="checkbox" class="input-checkbox" v-model="ckAtivo" />
            </div>

            <div class="row-end">
                <label for="ckAceiteVencido">Aceite boleto vencido</label>
                <input id="ckAceiteVencido" type="checkbox" class="input-checkbox" v-model="ckAceiteVencido" />
            </div>


            <div class="row-end margin-top10">
                <button class="btn-confirm" type="submit">Salvar</button>
                <button class="btn-cancel" type="cancel" @click="cancelForm">Cancelar</button>
            </div>
        </form>
    </div>
</template>
  
<script>
import { onMounted, ref } from 'vue';
import { criarCobrConvenio } from '@/models/CobrConvenio';
import { decimalFromString, formatDecimal, formatInteger, integerFromString } from '@/funcoes/utils';
import { genericPut, getCadastroById } from '@/funcoes/api';
import { globalStore } from '@/globals';

export default {
    emits: ['close', 'salvo'],
    props: {idEdicao: {type: Number, default: 0}},
    setup(props, { emit }) {
        const ckAceiteVencido = ref(false);
        const ckAtivo = ref(false);
        const edAgencia = ref(null);
        const edAlMulta = ref(null);
        const edAlJuros = ref(null);
        const edBanco = ref(null);
        const edCarteira = ref(null);
        const edCodigoCedente = ref(null);
        const edConta = ref(null);
        const edConvenio = ref(null);
        const edDiasBaixa = ref(null);
        const edDiasProtesto = ref(null);
        const edVariacao = ref(null);

        const errorMessage = ref(null);
        const objEdicao = criarCobrConvenio();
        const isLoading = ref(false);

        const submitForm = async () => {
            try {
                isLoading.value = true;
                errorMessage.value = null;

                objEdicao.ckaceite_vencido      = ckAceiteVencido.value ? 'S' : 'N';
                objEdicao.ativo                 = ckAtivo.value ? 'S' : 'N';
                objEdicao.agencia               = integerFromString(edAgencia.value);
                objEdicao.almulta               = decimalFromString(edAlMulta.value);
                objEdicao.aljuros               = decimalFromString(edAlJuros.value);
                objEdicao.banco                 = integerFromString(edBanco.value);
                objEdicao.carteira              = integerFromString(edCarteira.value);
                objEdicao.codigo_cedente        = integerFromString(edCodigoCedente.value);
                objEdicao.conta                 = integerFromString(edConta.value);
                objEdicao.convenio              = integerFromString(edConvenio.value);
                objEdicao.dias_baixa            = integerFromString(edDiasBaixa.value);
                objEdicao.dias_protesto         = integerFromString(edDiasProtesto.value);
                objEdicao.variacao              = integerFromString(edVariacao.value);

                const response = await genericPut('/cad/cobr_convenio', objEdicao);
                if (response.bolok === 1){
                    emit('salvo');
                    emit('close');
                }else{
                    errorMessage.value = response.erro;
                }
            } catch (error) {console.error('Error:', error);}
        };

        const cancelForm = () => {emit('close');};

        const lerDados = async() => {
            try{
                isLoading.value=true;
                errorMessage.value=null;
                if (props.idEdicao > 0) {
                    try{
                        const req = await getCadastroById('cobr_convenio', props.idEdicao);
                        Object.assign(objEdicao, req[0]);
                    }catch (error){console.error('Erro leitura dos dados', error);}
                }else{
                    objEdicao.idmunicipio = globalStore.idmunicipio;
                }
                objEdicao.idusuario       = globalStore.idusuario;
                
                ckAceiteVencido.value     = objEdicao.ckaceite_vencido === 'S';
                ckAtivo.value             = objEdicao.ativo === 'S';
                edAgencia.value           = formatInteger(objEdicao.agencia);
                edAlMulta.value           = formatDecimal(objEdicao.almulta);
                edAlJuros.value           = formatDecimal(objEdicao.aljuros);
                edBanco.value             = formatInteger(objEdicao.banco);
                edCarteira.value          = formatInteger(objEdicao.carteira);
                edCodigoCedente.value     = formatInteger(objEdicao.codigo_cedente);
                edConta.value             = formatInteger(objEdicao.conta);
                edConvenio.value          = formatInteger(objEdicao.convenio);
                edDiasBaixa.value         = formatInteger(objEdicao.dias_baixa);
                edDiasProtesto.value      = formatInteger(objEdicao.dias_protesto);
                edVariacao.value          = formatInteger(objEdicao.variacao);
            }finally{isLoading.value=false;}
        };

        onMounted(()=>{lerDados()})

        return {
            ckAceiteVencido, ckAtivo, 
            edAgencia, edAlMulta, edAlJuros, edBanco, edCarteira, edCodigoCedente, edConta, edConvenio, edDiasBaixa, edDiasProtesto, edVariacao,
            errorMessage, isLoading, objEdicao, 
            formatDecimal, formatInteger,
            submitForm, cancelForm };
    }
};
</script>