<template>
    <div v-if="isLoading" class="loading-overlay"><p>Carregando...</p></div>


    <div>
        <div class="form-edit-page" style="display: flex; align-items: end; gap: 6px;">
            <DateRangeSelect style="flex: 0 0 370px;" v-model="dateRange" />
            <button type="button" class="buttom-icon--search" @click="lerDados" />
            <button type="button" class="buttom-icon--printer" @click="imprimir" />
            <button type="button" class="buttom-icon--close" @click="closeForm" />
        </div>

        <MensagemAlerta class="margin-top10" v-if="errorMessage" :mensagem="errorMessage" />
        
        <table class="margin-top10">
            <thead>
                <tr>
                    <th class="col-width-integer">Número</th>
                    <th class="col-width-integer">OS</th>
                    <th class="col-width-date">Emissão</th>
                    <th class="col-width-date">Vencimento</th>
                    <th>Nome</th>
                    <th class="col-width-number">Valor</th>
                    <th class="col-width-tiny">Registrado</th>
                    <th class="col-width-tiny">Situação</th>
                    <th class="col-width-acoes-2">Ações</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(obj, index) in lsDados" :key="obj.id" :class="{'even-row': index % 2 === 0, 'odd-row': index % 2 !== 0}">
                    <td class="col-width-integer right">{{ obj.numeroboleto }}</td>
                    <td class="col-width-integer right">{{ obj.cdos }}</td>
                    <td class="col-width-date center">{{ formatDateDMY(obj.dtemissao) }}</td>
                    <td class="col-width-date center">{{ formatDateDMY(obj.dtvencimento) }}</td>
                    <td>{{ obj.nmpessoa }}</td>
                    <td class="col-width-number right">{{ formatDecimal(obj.vlboleto) }}</td>
                    <td class="col-width-tiny center">{{ obj.titulo_confirmado }}</td>
                    <td class="col-width-tiny">{{ obj.situacaonm }}</td>
                    <td class="col-width-acoes-2"><div class="row-left">
                        <button class="btn-icon btn-icon--printer" @click="emitirBoleto(obj)"></button>
                    </div></td>
                </tr>
            </tbody>
        </table>

        <div v-if="showPagination" class="pagination">
            <button :disabled="currentPage === 1" class="buttom-pagination--first" @click="currentPage=1; updatePaginatedData();" />
            <button :disabled="currentPage === 1" class="buttom-pagination--prior" @click="currentPage--; updatePaginatedData();" />

            <div class="field-label field-label-center">{{ currentPage }} de {{ Math.ceil(lsDadosMain.length / itemsPerPage) }}</div>

            <button :disabled="currentPage === Math.ceil(lsDadosMain.length / itemsPerPage)" class="buttom-pagination--next" @click="currentPage++; updatePaginatedData();" />
            <button :disabled="currentPage === Math.ceil(lsDadosMain.length / itemsPerPage)" class="buttom-pagination--last" @click="currentPage = Math.ceil(lsDadosMain.length / itemsPerPage); updatePaginatedData();" />
        </div>
    </div>
</template>


<script>
import { ref } from 'vue';
import { formatDateFromJson, formatDecimal } from '@/funcoes/utils';
import { getBoletoPdf, genericPost, genericReport } from '@/funcoes/api'; 
import { globalStore } from '@/globals';
import DateRangeSelect from '@/components/DateRangeSelect.vue';
import MensagemAlerta from '@/components/MensagemAlerta.vue';

export default{
    emits: ['close'],
    components:{DateRangeSelect, MensagemAlerta},

    setup(_, { emit }){
        const dateRange = ref({ startDate: "", endDate: "", selectedPeriod: "" });
        const errorMessage = ref(null);
        const isLoading = ref(false);
        const lsDados = ref([]);
        const lsDadosMain = ref([]);
        const showPagination = ref(false);

        const currentPage = ref(1);
        const itemsPerPage = ref(12);

        const closeForm = () => {emit('close');}

        const emitirBoleto = async(obj) => {
            try{
                isLoading.value=true;
                errorMessage.value=null;
                const response = await getBoletoPdf(obj.id);
                const pdfBlob = new Blob([response], { type: 'application/pdf' });
                const pdfUrl = URL.createObjectURL(pdfBlob);
                window.open(pdfUrl, '_blank');
            }catch (error){errorMessage.value = `ATENÇÃO: ${error.message}`;
            }finally{isLoading.value=false;}
        }

        const imprimir = async() => {
            try{
                if (!dateRange.value.startDate || !dateRange.value.endDate){
                    throw new Error('Necessário informar ambas as datas.');
                }
                const dataInicio = new Date(dateRange.value.startDate);
                const dataFim = new Date(dateRange.value.endDate);

                if (isNaN(dataInicio.getTime()) || isNaN(dataFim.getTime())){
                    throw new Error('Datas inválidas. Verifique o formato das datas.');
                }

                if (dataFim < dataInicio){
                    throw new Error('A data final deve ser maior ou igual à data inicial.');
                }

                errorMessage.value = null;
                isLoading.value = true;
                const dados = {
                    nmrelatorio: 'boletos',
                    idmunicipio: globalStore.idmunicipio,
                    idusuario: globalStore.idusuario,
                    datainicial: dateRange.value.startDate,
                    datafinal: dateRange.value.endDate};
                const response = await genericReport(dados);
                const pdfBlob = new Blob([response], { type: 'application/pdf' });
                const pdfUrl = URL.createObjectURL(pdfBlob);
                window.open(pdfUrl, '_blank');
            } catch (error){errorMessage.value = `ATENÇÃO: ${error.message}`;
            } finally {isLoading.value = false;}
        }

        const lerDados = async() => {
            if ((!dateRange.value.startDate) || (!dateRange.value.endDate)){
                errorMessage.value = 'Informe corretamente um período';
                return;
            }
            try{
                errorMessage.value = null;
                isLoading.value = true;
                const req = await genericPost('/cobranca_api/boletos', {
                    idmunicipio: globalStore.idmunicipio,
                    datainicial: dateRange.value.startDate, 
                    datafinal:dateRange.value.endDate});
                lsDadosMain.value = req;
                currentPage.value=1;
                showPagination.value = (lsDadosMain.value.length > itemsPerPage.value);
                updatePaginatedData();
            } catch (error){errorMessage.value=error;
            } finally {isLoading.value = false;}
        }

        const updatePaginatedData = () => {
            const startIndex = (currentPage.value - 1) * itemsPerPage.value;
            const endIndex = (startIndex + itemsPerPage.value);
            lsDados.value = lsDadosMain.value.slice(startIndex, endIndex);
        }

        return { dateRange, errorMessage, isLoading, lsDados, lsDadosMain, currentPage, itemsPerPage, showPagination,
            closeForm, emitirBoleto, formatDateDMY: formatDateFromJson, formatDecimal, imprimir, lerDados, updatePaginatedData };
    }
}
</script>
