<template>
    <div v-if="isLoading" class="loading-overlay"><p>Carregando...</p></div>


    <div v-if="!modoEdicao">
        <div class="form-edit-page row-end">
            <button type="button" class="buttom-icon--add" @click="novoCadastro" />
            <button type="button" class="buttom-icon--close" @click="closeForm" />
        </div>
        
        <table class="margin-top10">
            <thead>
                <tr>
                    <th class="col-width-integer">Código</th>
                    <th class="col-width-char30">Identificação</th>
                    <th>Nome Beneficiário</th>
                    <th class="col-width-char30">Banco</th>
                    <th class="col-width-char10">Situação</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(obj, index) in lsDados" :key="obj.id" :class="{'even-row': index % 2 === 0, 'odd-row': index % 2 !== 0, 'clickabe-row': true}" @click="editarCadastro(obj)">
                    <td class="col-width-integer right">{{ obj.cdcobr_convenio }}</td>
                    <td class="col-width-char30">{{ obj.identificacao }}</td>
                    <td>{{ obj.nmempresa }}</td>
                    <td class="col-width-char30">{{ obj.nmbanco }}</td>
                    <td class="col-width-char10">{{ obj.xativo }}</td>
                </tr>
            </tbody>
        </table>
    </div>


    <div v-if="modoEdicao">
        <CobrConvenioEditPage :idEdicao="idEdicao" @close="modoEdicao = false" @salvo="lerDados" />
    </div>
</template>


<script>
import { onMounted, ref } from 'vue';
import { genericPost } from '@/funcoes/api'; 
import { globalStore } from '@/globals';
import CobrConvenioEditPage from '../formularios/CobrConvenioEditPage.vue';

export default{
    emits: ['close'],
    components:{ CobrConvenioEditPage },

    setup(_, { emit }){
        const errorMessage = ref(null);
        const idEdicao = ref(0);
        const isLoading = ref(false);
        const lsDados = ref([]);
        const modoEdicao = ref(false);

        const closeForm = () => {emit('close');}

        const editarCadastro = (obj) => {
            idEdicao.value = obj.id;
            modoEdicao.value = true;
        };

        const lerDados = async() => {
            try{
                isLoading.value = true;
                errorMessage.value=null;
                const req = await genericPost('/listas/cobr_convenios', {idmunicipio: globalStore.idmunicipio });
                lsDados.value = req;
            } catch (error){errorMessage.value = error;
            } finally {isLoading.value = false;}
        }

        const novoCadastro = () => {
            idEdicao.value = 0;
            modoEdicao.value = true;
        }

        onMounted(()=>{lerDados();})

        return { 
            errorMessage, idEdicao, isLoading, lsDados, modoEdicao,
            closeForm, editarCadastro, lerDados, novoCadastro };
    }
}
</script>
