<template>
    <div class="dashboard">
        <div class="stats-row">
            <div class="stat-card">
                <h3>Total de Chamados</h3>
                <h2>{{ stats.ttos }}</h2>
            </div>

            <div class="stat-card">
                <h3>Chamados Aguardando</h3>
                <h2>{{ stats.ttosaguardando }}</h2>
            </div>

            <div class="stat-card">
                <h3>Chamados Atendidos</h3>
                <h2>{{ stats.ttosatendida }}</h2>
            </div>
        </div>
    </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { genericPost } from '@/funcoes/api';
import { globalStore } from '@/globals';

export default{
    setup(){
        const stats = ref({
            ttos: 0,
            ttosaguardando: 0,
            ttosatendida:0,
        });

        const loadStats = async () => {
            try{
                const response = await genericPost('/dashboard/ipos/resumo', {idmunicipio: globalStore.idmunicipio, idfornecedor: globalStore.idipprestador});
                stats.value = response[0];
            }catch(error){console.error('Erro ao carregar dados de estatísticas:', error);}
        };
        onMounted(()=>{
            loadStats();
        });

        return{stats};
    }
}
</script>