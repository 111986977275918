<template>
    <div v-if="isLoading" class="loading-overlay"><p>Carregando...</p></div>

    <div class="form-edit-page">
        <form @submit.prevent="submitForm">
            <div class="row row-space">
                <div class="form-item" style="flex: 0 0 90px;">
                    <label class="label-caption">Código</label>
                    <div class="field-label field-label-end">{{ objEdicao.cdlogradouro }}</div>
                </div>

                <div class="form-item flexible">
                    <label class="label-caption">Nome</label>
                    <input type="text" v-model="objEdicao.nmlogradouro" v-uppercase required maxlength="100" />
                </div>
            </div>


            <div class="row-end">
                <label>Ativo</label>
                <input type="checkbox" class="input-checkbox" v-model="ckAtivo" />
            </div>


            <div class="row-end margin-top10">
                <button class="btn-confirm" type="submit">Salvar</button>
                <button class="btn-cancel" type="cancel" @click="cancelForm">Cancelar</button>
            </div>
        </form>
    </div>
</template>
  
<script>
import { ref } from 'vue';
import { criarLogradouro } from '@/models/Logradouro';
import { genericPut, getCadastroById } from '@/funcoes/api';
import { globalStore } from '@/globals';

export default {
    emits: ['close', 'salvo'],
    props: {idEdicao: {type: Number, default: 0}},
    setup(props, { emit }) {
        const ckAtivo = ref(false);
        const objEdicao = criarLogradouro();
        const isLoading = ref(false);

        const submitForm = async () => {
            try {
                isLoading.value = true;
                objEdicao.ativo = ckAtivo.value ? 'S' : 'N';
                const response  = await genericPut('/cad/logradouro', objEdicao);
                if (response.bolok === 1){
                    emit('salvo');
                    emit('close');
                }else{
                    alert(response.erro);
                }
            } catch (error) {console.error('Error:', error);
            } finally{isLoading.value=false;}
        };

        const cancelForm = () => {emit('close');};

        const lerDados = async() => {
            try{
                isLoading.value=true;
                if (props.idEdicao > 0) {
                    try{
                        const req = await getCadastroById('logradouro', props.idEdicao);
                        Object.assign(objEdicao, req[0]);
                    }catch (error){console.error('Erro leitura dos dados', error);}
                }else{
                    objEdicao.idmunicipio = globalStore.idmunicipio;
                }
                objEdicao.idusuario = globalStore.idusuario;
                ckAtivo.value       = objEdicao.ativo === 'S';
            }finally{isLoading.value=false;}
        };

        lerDados();

        return {ckAtivo, isLoading, objEdicao, submitForm, cancelForm };
    }
};
</script>