<template>
    <div class="form-item">
        <label class="label-caption">Situação OS</label>
        <select :value="statusSelecionado" @change="atualizarTipo" class="form-control" v-bind="$attrs">
            <option v-for="tipo in lsTipos" :key="tipo.id" :value="tipo.id">{{ tipo.descricao }}</option>
        </select>
    </div>
</template>
  
<script>
export default {
    props: {
        statusSelecionado: {type: Number, default: null}
    },

    data() {
        return{
            lsTipos:[
                {id: 0, descricao: 'TODAS'},
                {id: 1, descricao: 'Aguardando Pagamento'},
                {id: 2, descricao: 'Aguardando Execução'},
                {id: 3, descricao: 'Em Execução'},
                {id: 4, descricao: 'Parcialmente Concluído'},
                {id: 5, descricao: 'Concluído'},
                {id: 6, descricao: 'Adiada'},
                {id: 7, descricao: 'Cancelada'},
                {id: 8, descricao: 'Baixa sistema'}
            ]
        };
    },

    methods: {
        atualizarTipo(event) {
            this.$emit('update:statusSelecionado', Number(event.target.value));
        }
    }
};
</script>