<template>
    <div>
        <div id="map" style="height: 550px; width: 100%;"></div>
    </div>
</template>

<script>
import L from 'leaflet';

export default {
    props: {
        coordinates: {
            type: Array,
            require: true,
            validator(value){
                return value.every(coord => 'latitude' in coord && 'longitude' in coord);
            }
        }
    },
    mounted() {
        if (this.coordinates.length === 0){
            console.warn("Nenhuma coordenada fornecida.");
            return;
        }

        const firstCoordinate = this.coordinates[0];
        this.map = L.map('map').setView([firstCoordinate.latitude, firstCoordinate.longitude], 15);

        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            maxZoom: 19,
        }).addTo(this.map);

        const customIcon = L.icon({
            iconUrl: require('@/assets/icons/mapMarker.svg'), // Caminho para sua imagem
            iconSize: [26, 26], // Tamanho do ícone (largura, altura)
            iconAnchor: [10, 20], // Ponto de ancoragem (base do ícone)
            popupAnchor: [0, -38] // Ponto onde o popup aparecerá
        });

        this.coordinates.forEach(coord => {
            L.marker([coord.latitude, coord.longitude], { icon: customIcon })
                .addTo(this.map)
                .bindPopup(`Latitude: ${coord.latitude}, Longitude: ${coord.longitude}`);
        });

        // Força o Leaflet a recalcular o tamanho do mapa
        this.$nextTick(() => {
            setTimeout(() => {
                this.map.invalidateSize();
            }, 100); // Pequeno atraso para garantir que o DOM foi atualizado
        });
    },
    data() {
        return {
            map: null
        };
    }
};
</script>