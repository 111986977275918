<template>
    <div v-if="isLoading" class="loading-overlay"><p>Carregando...</p></div>

    <div class="form-edit-page">
        <div class="row row-space">
            <div class="form-item" style="flex: 0 0 90px;">
                <label class="label-caption">Solicitação</label>
                <div class="field-label field-label-end">{{ objView.cdsolicitacao }}</div>
            </div>

            <div class="form-item" style="flex: 0 0 90px;">
                <label class="label-caption">Data</label>
                <div class="field-label field-label-center">{{ formatDateFromJson(objView.dtsolicitacao) }}</div>
            </div>

            <div class="form-item" style="flex: 0 0 60px;">
                <label class="label-caption">Horas</label>
                <div class="field-label field-label-center">{{ objView.hrsolicitadas }}</div>
            </div>

            <div class="form-item">
                <label class="label-caption">Nome</label>
                <div class="field-label">{{ objView.nmpessoa }}</div>
            </div>

            <div class="form-item" style="flex: 0 0 250px;">
                <label class="label-caption">Situação</label>
                <div class="field-label">{{ objView.xsituacao }}</div>
            </div>
        </div>


        <div class="row row-space">
            <div class="form-item">
                <label class="label-caption">Grupo</label>
                <div class="field-label">{{ objView.nmgrupo }}</div>
            </div>

            <div class="form-item">
                <label class="label-caption">Implemento</label>
                <div class="field-label">{{ objView.nmimplemento }}</div>
            </div>

            <div class="form-item">
                <label class="label-caption">Serviço</label>
                <div class="field-label">{{ objView.nmservico }}</div>
            </div>
        </div>
        

        <div class="row row-space">
            <div class="form-item">
                <label class="label-caption">Região</label>
                <div class="field-label">{{ objView.nmregiao }}</div>
            </div>

            <div class="form-item">
                <label class="label-caption">Localidade</label>
                <div class="field-label">{{ objView.nmlocalidade }}</div>
            </div>

            <div class="form-item">
                <label class="label-caption">Complemento/Referência</label>
                <div class="field-label">{{ objView.localidade_complemento }}</div>
            </div>
        </div>

        
        <div v-if="showControles" class="row-end margin-top10">
            <button v-if="objView.situacao===1 || objView.situacao===2" type="button" @click="setGeraOS(true)">Gerar OS</button>
            <button type="button" @click="closeForm">Retornar</button>
        </div>
    </div>

    <div v-if="showGeraOS" class="form-edit-page margin-top10">
        <EquipamentoSelect class="form-item" v-model:equipamentoSelecionado="idEquipamento" />

        <div class="row-end margin-top10">
            <button class="btn-confirm" type="button" @click="gerarOS">Confirmar</button>
            <button class="btn-cancel" type="button" @click="setGeraOS(false)">Cancelar</button>
        </div>
    </div>
</template>

<script>
import { onMounted, ref } from 'vue';
import { genericPost, genericPut } from '@/funcoes/api';
import { formatDateFromJson } from '@/funcoes/utils';
import { globalStore } from '@/globals';
import EquipamentoSelect from '@/selects/EquipamentoSelect.vue';

export default{
    components: {EquipamentoSelect},
    emits:['close', 'save'],
    props:{idView: {type: Number, default: 0}},
    setup(props, {emit}){
        const idEdicao = ref(0);
        const idEquipamento = ref(0);
        const isLoading = ref(false);
        const objView = ref({});
        const showControles = ref(true);
        const showGeraOS = ref(false);

        const closeForm = () => {emit('close');}

        const gerarOS = async() => {
            try{
                isLoading.value=true;
                const r = await genericPut('/os/ossolicitacao_gera_os', {id: objView.value.id, idusuario: globalStore.idusuario, idequipamento: idEquipamento.value});
                if (r.bolok===1){
                    emit('close');
                    emit('save');
                }else{
                    console.log(r.erro);
                }
            }finally{isLoading.value=false;}
        }

        const lerDados = async() => {
            try{
                isLoading.value = true;
                const req = await genericPost('/listas/ossolicitacoes', {idmunicipio: globalStore.idmunicipio, id: props.idView});
                Object.assign(objView.value, req[0]);
            }catch (error){alert(error);
            }finally{isLoading.value = false;}
        }

        const setGeraOS = (ativar)=>{
            showControles.value=!ativar;
            showGeraOS.value=ativar;
        }

        onMounted(()=>{lerDados();});

        return{idEdicao, idEquipamento, isLoading, objView, showControles, showGeraOS, 
            closeForm, formatDateFromJson, gerarOS, lerDados, setGeraOS}
    }
}
</script>