<template>
    <div v-if="isLoading" class="loading-overlay"><p>Carregando...</p></div>

    <div v-if="!modoEdicao" class="form-edit-page">
        <div class="row row-space">
            <div class="form-item" style="flex: 0 0 90px;">
                <label class="label-caption">Código</label>
                <div class="field-label field-label-end">{{ objView.cdusuario }}</div>
            </div>

            <div class="form-item">
                <label class="label-caption">Nome</label>
                <div class="field-label">{{ objView.nmusuario }}</div>
            </div>

            <div class="form-item" style="flex: 0 0 120px;">
                <label class="label-caption">Fone</label>
                <div class="field-label field-label-center">{{ objView.fone }}</div>
            </div>

            <div class="form-item" style="flex: 0 0 120px;">
                <label class="label-caption">Celular</label>
                <div class="field-label field-label-center">{{ objView.celular }}</div>
            </div>
        </div>

        <div class="row row-space">
            <div class="form-item" style="flex: 0 0 90px;">
                <label class="label-caption">Município</label>
                <div class="field-label field-label-end">{{ objView.idmunicipio }}</div>
            </div>

            <div class="form-item">
                <label class="label-caption">Nome Município</label>
                <div class="field-label">{{ objView.nmmunicipio }}</div>
            </div>

            <div class="form-item">
                <label class="label-caption">Email</label>
                <div class="field-label">{{ objView.email }}</div>
            </div>

            <div class="form-item" style="flex: 0 0 120px;">
                <label class="label-caption">Situação</label>
                <div class="field-label field-label-center">{{ objView.ativonm }}</div>
            </div>
        </div>

        <div class="row-end margin-top10">
            <button v-if="objView.nivel === 999" type="button" 
                @click="showModalMunicipio=true">Trocar Município</button>
            <button type="button" @click="editarCadastro">Alterar Dados</button>
            <button type="button" @click="showModalSenha=true">Alterar Login</button>
            <button type="button" @click="closeForm">Retornar</button>
        </div>
    </div>

    <div>
        <ModalRedefinirSenha
            :visible="showModalSenha"
            @update:visible="(value) => (showModalSenha = value)"
            @submit="redefinirLoginSenha" />
    </div>

    <div>
        <ModalSuporteTrocaMunicipio
            :showModal="showModalMunicipio"
            @close="showModalMunicipio = false"
            @municipioSelecionado="trocarMunicipio" />
    </div>

    <div v-if="modoEdicao">
        <UsuarioEditPage :idEdicao="idEdicao" @close="modoEdicao = false" @salvo="lerDados" />
    </div>
</template>

<script>
import { ref } from 'vue';
import { genericPost, genericPut, getCadastroById } from '@/funcoes/api';
import { globalStore } from '@/globals';
import { useLoginStore } from '@/store/login';
import ModalRedefinirSenha from '@/components/modals/ModalRedefinirSenha.vue';
import ModalSuporteTrocaMunicipio from '@/components/modals/ModalSuporteTrocaMunicipio.vue';
import UsuarioEditPage from '../formularios/UsuarioEditPage.vue';

export default{
    components: { ModalRedefinirSenha, ModalSuporteTrocaMunicipio, UsuarioEditPage },
    emits:['close'],
    props:{idView: {type: Number, default: 0}},
    setup(props, {emit}){
        const idEdicao = ref(0);
        const isLoading = ref(false);
        const login = useLoginStore();
        const modoEdicao = ref(false);
        const objView = ref({});
        const showModalMunicipio = ref(false);
        const showModalSenha = ref(false);

        const closeForm = () => {emit('close');}

        const editarCadastro = () => {
            idEdicao.value = objView.value.id;
            modoEdicao.value = true;
        };

        const lerDados = async() => {
            try{
                isLoading.value = true;
                const req = await genericPost('/listas/usuarios', {idmunicipio: login.userData.idmunicipio, id: props.idView});
                Object.assign(objView.value, req[0]);
            }catch (error){alert(error);
            }finally{isLoading.value = false;}
        }

        const redefinirLoginSenha = async({username, senha}) => {
            try{
                isLoading.value = true;
                const r = await genericPut('/cad/usuario', {id: objView.value.id, login: username, senha: senha});
                if (r.bolok == 1){alert("Nova senha alterada com sucesso");}else{alert(r.error);}
            }finally {isLoading.value = false;}
        }

        const trocarMunicipio = async(municipio) => {
            try{
                isLoading.value=true;
                // ALTERAR REGISTRO NO BANCO DE DADOS
                const r = await genericPut('/cad/usuario', {id: objView.value.id, idmunicipio: municipio.id});
                if (r.bolok==1){
                    login.userData.idmunicipio  = municipio.id;
                    login.userData.nmmunicipio  = municipio.nmmunicipio;
                    globalStore.idmunicipio     = municipio.id;
                    globalStore.nmmunicipio     = municipio.nmmunicipio;

                    const config = await getCadastroById('municipio_config', municipio.id);
                    if (config){
                        globalStore.ckpermitir_parcelar = config[0].ckpermitir_parcelar;
                        globalStore.cobr_prazo_inicial  = config[0].cobr_prazo_inicial;
                        globalStore.latitude            = config[0].latitude;
                        globalStore.longitude           = config[0].longitude;
                    }
                }
            }finally{isLoading.value=false;}
        }

        lerDados();

        return{idEdicao, isLoading, modoEdicao, objView, showModalMunicipio, showModalSenha,
            closeForm, editarCadastro, lerDados, redefinirLoginSenha, trocarMunicipio}
    }
}
</script>