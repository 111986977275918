<template>
    <div v-if="isLoading" class="loading-overlay"><p>Carregando...</p></div>


    <div class="form-edit-page row-space">
        <div class="row-start">
            <div class="form-item flexible">
                <label class="label-caption">Pesquisar (Nome ou Número OS)</label>
                <input type="text" v-model="termoPesquisa" maxlength="60" v-uppercase />
            </div>
            <div class="form-item-rangedata"><DateRangeSelect v-model="dateRange" /></div>
            <button type="button" class="buttom-icon--search" @click="lerDados" />
            <button type="button" class="buttom-icon--close" @click="closeForm" />
        </div>
    </div>

    <MensagemAlerta class="row-space" v-if="errorMessage" :mensagem="errorMessage" />
    
    <table>
        <thead>
            <tr>
                <th class="col-width-integer">OS</th>
                <th class="col-width-date">Emissão</th>
                <th>Nome</th>
                <th class="col-width-char30">Grupo</th>
                <th class="col-width-number">Valor</th>
                <th class="col-width-char30">Situação OS</th>
                <th class="col-width-doc">Ação</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(obj, index) in lsDados" :key="obj.id" :class="{'even-row': index % 2 === 0, 'odd-row': index % 2 !== 0}">
                <td class="col-width-integer right">{{ obj.cdos }}</td>
                <td class="col-width-date center">{{ formatDateFromJson(obj.dtinclusao) }}</td>
                <td>{{ obj.nmpessoa }}</td>
                <td class="col-width-char30">{{ obj.nmgrupo }}</td>
                <td class="col-width-number right">{{ formatDecimal(obj.vltotal) }}</td>
                <td class="col-width-char30">{{ obj.nmsituacao }}</td>
                <td class="col-width-doc">
                    <button class="btn-confirm" @click="liberarOS(obj)">Liberar</button>
                </td>
            </tr>
        </tbody>
    </table>

    <div v-if="showPagination" class="pagination">
        <button :disabled="currentPage === 1" class="buttom-pagination--first" @click="currentPage=1; updatePaginatedData();" />
        <button :disabled="currentPage === 1" class="buttom-pagination--prior" @click="currentPage--; updatePaginatedData();" />

        <div class="field-label field-label-center">{{ currentPage }} de {{ Math.ceil(lsDadosMain.length / itemsPerPage) }}</div>

        <button :disabled="currentPage === Math.ceil(lsDadosMain.length / itemsPerPage)" class="buttom-pagination--next" @click="currentPage++; updatePaginatedData();" />
        <button :disabled="currentPage === Math.ceil(lsDadosMain.length / itemsPerPage)" class="buttom-pagination--last" @click="currentPage = Math.ceil(lsDadosMain.length / itemsPerPage); updatePaginatedData();" />
    </div>
</template>


<script>
import { ref } from 'vue';
import { formatDateFromJson, formatDecimal } from '@/funcoes/utils';
import { genericPost, genericPut } from '@/funcoes/api'; 
import { globalStore } from '@/globals';
import DateRangeSelect from '@/components/DateRangeSelect.vue';
import MensagemAlerta from '@/components/MensagemAlerta.vue';

export default{
    emits: ['close'],
    components:{ DateRangeSelect, MensagemAlerta },

    setup(_, { emit }){
        const dateRange = ref({ startDate: "", endDate: "", selectedPeriod: "" });
        const errorMessage = ref(null);
        const isLoading = ref(false);
        const lsDados = ref([]);
        const termoPesquisa = ref(null);

        const currentPage = ref(1);
        const itemsPerPage = ref(globalStore.itensPorPagina);
        const lsDadosMain = ref([]);
        const showPagination = ref(false);

        const closeForm = () => {emit('close');}

        const lerDados = async() => {
            try{
                errorMessage.value  = null;
                isLoading.value     = true;
                const termo         = termoPesquisa.value ? termoPesquisa.value.trim() : '';
                const parsedValue   = parseInt(termo, 10);
                const req = await genericPost('/financeiro/os/liberar', {
                    idmunicipio: globalStore.idmunicipio,
                    datainicial: dateRange.value.startDate, 
                    datafinal:dateRange.value.endDate,
                    nmpesquisar: Number.isInteger(parsedValue) ? '' : termo,
                    cdcadastro: Number.isInteger(parsedValue) ? parsedValue : 0});
                lsDadosMain.value = req;
                currentPage.value=1;
                showPagination.value = (lsDadosMain.value.length > itemsPerPage.value);
                updatePaginatedData();
            } catch (error){errorMessage.value=error;
            } finally {isLoading.value = false;}
        }

        const liberarOS = async(obj) =>{
            try{
                errorMessage.value          = null;
                isLoading.value             = true;
                const req = await genericPut('/cad/os', {
                    id: obj.id,
                    lgautcobranca: globalStore.idlogin,
                    idusuario: globalStore.idusuario});
                if (req.bolok === 1){
                    lerDados();
                }
            } catch (error){errorMessage.value=error;
            } finally {isLoading.value = false;}
        }

        const updatePaginatedData = () => {
            const startIndex = (currentPage.value - 1) * itemsPerPage.value;
            const endIndex = (startIndex + itemsPerPage.value);
            lsDados.value = lsDadosMain.value.slice(startIndex, endIndex);
        }

        return { 
            dateRange, errorMessage, isLoading, lsDados, termoPesquisa, 
            currentPage, itemsPerPage, lsDadosMain, showPagination,
            closeForm, formatDateFromJson, formatDecimal, lerDados, liberarOS, updatePaginatedData };
    }
}
</script>
