<template>
    <div v-if="visible" class="modal-overlay">
        <div class="modal-content">
            <h4>Redefinir Login e Senha</h4>
            <form @submit.prevent="onSubmit">
                <div class="row row-space">
                    <div class="form-item">
                        <label class="label-caption">Login</label>
                        <input type="text" maxlength="30" v-model="novoLogin" required />
                    </div>
                </div>

                <div class="row row-space">
                    <div class="form-item">
                        <label class="label-caption">Nova Senha</label>
                        <input type="password" autocomplete="new-password" maxlength="20" v-model="novaSenha" required />
                    </div>
                </div>

                <div class="row row-space">
                    <div class="form-item">
                        <label class="label-caption">Confirmar Senha</label>
                        <input type="password" autocomplete="new-password" maxlength="20" v-model="novaSenha2" required />
                    </div>
                </div>

                <div class="row-end">
                    <button type="submit" class="btn-confirm">Salvar</button>
                    <button type="button" class="btn-cancel" @click="closeModal">Cancelar</button>
                </div>
            </form>
        </div>
    </div>
</template>
  
<script>
import { ref, computed } from 'vue';

export default {
    props: {visible: {type: Boolean, required: true,},},
    emits: ['update:visible', 'submit'],
        
    setup(props, { emit }) {
        const novoLogin = ref('');
        const novaSenha = ref('');
        const novaSenha2 = ref('');

        const isVisible = computed(() => props.visible);

        const onSubmit = () => {
            if (novaSenha.value !== novaSenha2.value) {
                alert('As senhas não coincidem.');
                return;
            }
            emit('submit', { username: novoLogin.value, senha: novaSenha.value });
            closeModal();
        };

        const closeModal = () => {
            emit('update:visible', false);
        };

        return { isVisible, novoLogin, novaSenha, novaSenha2, onSubmit, closeModal, };
    }
};
</script>

<style scoped>
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}
.modal-content {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 400px;
}
</style>
  