<template>
    <div v-if="isLoading" class="loading-overlay"><p>Carregando...</p></div>


    <div v-show="true">
        <div class="form-edit-page row-space">
            <div class="row row-space">
                <div class="form-item">
                    <label class="label-caption">Grupo</label>
                    <div class="field-label">{{ nmEquipamentoGrupo }}</div>
                </div>

                <div class="form-item">
                    <label class="label-caption">Equipamento</label>
                    <div class="field-label">{{ nmEquipamento }}</div>
                </div>

                <button type="button" class="buttom-icon--search" @click="lerDados" />
                <button type="button" class="buttom-icon--close" @click="closeForm" />
            </div>
        </div>


        <MensagemAlerta class="row-space" v-if="errorMessage" :mensagem="errorMessage" />

        
        <table>
            <thead>
                <tr>
                    <th class="col-width-integer">OS</th>
                    <th class="col-width-date">Agendado</th>
                    <th>Nome</th>
                    <th class="col-width-small">Localidade</th>
                    <th class="col-width-medium">Serviço</th>
                    <th class="col-width-date">Horas</th>
                    <th class="col-width-doc">Situação</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(obj, index) in lsDados" :key="obj.id" :class="{'even-row': index % 2 === 0, 'odd-row': index % 2 !== 0}">
                    <td class="col-width-integer right">{{ formatInteger(obj.cdos) }}</td>
                    <td class="col-width-date center">{{ formatDateFromJson(obj.dtinicioprevisto) }}</td>
                    <td>{{ obj.nmpessoa }}</td>
                    <td class="col-width-small">{{ obj.nmlocalidade }}</td>
                    <td class="col-width-medium">{{ obj.nmservico }}</td>
                    <td class="col-width-date center">{{ obj.hrcontratadas }}</td>
                    <td class="col-width-doc">
                        <button v-if="obj.idequipamento === idEquipamento" class="btn-confirm" @click="updateOS(obj, false)">Atribuido</button>
                        <button v-if="obj.idequipamento !== idEquipamento" class="btn-cancel" @click="updateOS(obj, true)">Pendente</button>
                    </td>
                </tr>
            </tbody>
        </table>
        

        <div v-if="showPagination" class="pagination">
            <button :disabled="currentPage === 1" class="buttom-pagination--first" @click="currentPage=1; updatePaginatedData();" />
            <button :disabled="currentPage === 1" class="buttom-pagination--prior" @click="currentPage--; updatePaginatedData();" />

            <div class="field-label field-label-center">{{ currentPage }} de {{ Math.ceil(lsDadosMain.length / itemsPerPage) }}</div>

            <button :disabled="currentPage === Math.ceil(lsDadosMain.length / itemsPerPage)" class="buttom-pagination--next" @click="currentPage++; updatePaginatedData();" />
            <button :disabled="currentPage === Math.ceil(lsDadosMain.length / itemsPerPage)" class="buttom-pagination--last" @click="currentPage = Math.ceil(lsDadosMain.length / itemsPerPage); updatePaginatedData();" />
        </div>
    </div>
</template>


<script>
import { onMounted, ref } from 'vue';
import { formatDateFromJson, formatInteger } from '@/funcoes/utils';
import { genericPost, genericPut } from '@/funcoes/api'; 
import { globalStore } from '@/globals';
import MensagemAlerta from '@/components/MensagemAlerta.vue';

export default{
    emits: ['close', 'save'],
    components:{ MensagemAlerta },
    props:{
        idEquipamento: {type: Number, default: 0},
        nmEquipamento: {type: String, default: ""},
        idEquipamentoGrupo: {type: Number, default: 0},
        nmEquipamentoGrupo: {type: String, default: ""}},

    setup(props, { emit }){
        const emitSave = ref(false);
        const errorMessage = ref(null);
        const isLoading = ref(false);
        const lsDados = ref([]);
        const lsDadosMain = ref([]);
        const showPagination = ref(false);

        const currentPage = ref(1);
        const itemsPerPage = ref(globalStore.itensPorPagina);

        const closeForm = () => {
            emit('close');
            if (emitSave.value){emit('save');}
        }

        const lerDados = async() => {
            try{
                errorMessage.value = null;
                isLoading.value = true;
                const req = await genericPost('/os/garagem/agendamento/equipamento', {
                    idmunicipio: globalStore.idmunicipio,
                    idequipamento: props.idEquipamento,
                    idequipamentogrupo: props.idEquipamentoGrupo});
                lsDadosMain.value = req;
                currentPage.value=1;
                showPagination.value = (lsDadosMain.value.length > itemsPerPage.value);
                updatePaginatedData();
            } catch (error){errorMessage.value=error;
            } finally {isLoading.value = false;}
        }

        const updatePaginatedData = () => {
            const startIndex = (currentPage.value - 1) * itemsPerPage.value;
            const endIndex = (startIndex + itemsPerPage.value);
            lsDados.value = lsDadosMain.value.slice(startIndex, endIndex);
        }

        const updateOS = async(obj, atribuir) =>{
            try{
                errorMessage.value = null;
                isLoading.value = true;
                const req = await genericPut('/cad/os', {
                    id: obj.id,
                    idequipamento: atribuir ? props.idEquipamento : 1,
                    idusuario: globalStore.idusuario});
                if (req.bolok === 1){
                    emitSave.value      = true;
                    obj.idequipamento   = atribuir ? props.idEquipamento : 1;
                }
            } catch (error){errorMessage.value=error;
            } finally {isLoading.value = false;}
        }

        onMounted(() => {
            lerDados();
        });
        
        return { 
            errorMessage, isLoading, lsDados, lsDadosMain,
            currentPage, itemsPerPage, showPagination,
            closeForm, formatDateFromJson, formatInteger, lerDados, updatePaginatedData, updateOS };
    }
}
</script>
