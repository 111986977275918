<template>
    <div class="layout">
        <aside class="sidebar" :class="{ collapsed: isCollapsed }">
            <ul>
                <li @click="toggleMenu">
                    <img :src="menuIcon" alt="Toggle Menu" />
                    <span>{{ isCollapsed ? 'Expandir' : 'Recolher' }}</span>
                </li>

                <li @click="setComponenteAtivo('IpOsEditPage', 'ABERTURA DE CHAMADO')">
                    <img src="@/assets/icons/menuCall.svg" />
                    <span>Abrir Chamado</span>
                </li>

                <li @click="setComponenteAtivo('IpOsPrestadorTabPage', 'ORDENS DE SERVIÇO')">
                    <img src="@/assets/icons/menuIpOs.svg" />
                    <span>Ordens de Serviço</span>
                </li>

                <li @click="setComponenteAtivo('IpOSExecutarBairroReportPage', 'OS A EXECUTAR POR BAIRRO')">
                    <img src="@/assets/icons/menuPrinter.svg" />
                    <span>OS a Executar</span>
                </li>

                <li @click="setComponenteAtivo('UsuarioViewPage', 'DADOS DO USUÁRIO')">
                    <img src="@/assets/icons/menuUser.svg" />
                    <span>Meus Dados</span>
                </li>

                <li @click="confirmLogout">
                    <img src="@/assets/icons/menuLogout.svg" />
                    <span>Desconectar</span>
                </li>
            </ul>
        </aside>

        <main class="content">
            <header class="header">
                <div class="header-title">{{ nmTitulo ? nmTitulo : 'SGOS - PRESTADOR SERVIÇO DE ILUMINAÇÃO PÚBLICA' }}</div>
                <div style="align-items: center; display: flex;">
                    <div class="user-info">
                        <div class="user-name">{{ loginStore.userData.nome }}</div>
                        <div class="establishment-name">{{ loginStore.userData.nmmunicipio }}</div>
                    </div>
                    <div class="token-info">{{ timeRemaining }}</div>
                </div>
            </header>
            <div class="main-content">
                <slot>
                    <div v-if="nmComponenteAtivo=='IpOsPrestadorTabPage'"><IpOsPrestadorTabPage @close="setComponenteAtivo(null, null)"/></div>
                    <div v-else-if="nmComponenteAtivo=='IpOsEditPage'"><IpOsEditPage @close="setComponenteAtivo(null, null)"/></div>
                    <div v-else-if="nmComponenteAtivo=='IpOSExecutarBairroReportPage'"><IpOSExecutarBairroReportPage @close="setComponenteAtivo(null, null)"/></div>
                    <div v-else-if="nmComponenteAtivo=='UsuarioViewPage'"><UsuarioViewPage :idView=loginStore.userData.idusuario @close="setComponenteAtivo(null, null)"/></div>
                    <div v-else><IpOSPrestadorDash /></div>
                </slot>
            </div>
        </main>
    </div>
</template>

<script>
import { ref, onMounted, onBeforeUnmount } from 'vue';
import { jwtDecode } from 'jwt-decode';
import { useLoginStore } from '@/store/login';
import { useRouter } from 'vue-router';

import IpOsEditPage from '../ip/IpOsEditPage.vue';
import IpOSExecutarBairroReportPage from '../reports/IpOSExecutarBairroReportPage.vue';
import IpOSPrestadorDash from '../dashboard/IpOSPrestadorDash.vue';
import IpOsPrestadorTabPage from '../ip/IpOsPrestadorTabPage.vue';
import UsuarioViewPage from '../cadastros/visualizacoes/UsuarioViewPage.vue';

export default {
    components: {
        IpOsEditPage,
        IpOSExecutarBairroReportPage,
        IpOSPrestadorDash,
        IpOsPrestadorTabPage,
        UsuarioViewPage
    },
    computed: {
        menuIcon() {
            return this.isCollapsed
                ? require('@/assets/icons/menuRight.svg')
                : require('@/assets/icons/menuLeft.svg');
        }
    },
    setup() {
        const isCollapsed = ref(true);
        const nmComponenteAtivo = ref(null);
        const nmTitulo = ref(null);
        const router = useRouter();
        const timeRemaining = ref(null);
        const loginStore = useLoginStore();
        let timer = null;

        const confirmLogout = () => {resetLogin();}

        const resetLogin = () => {
            loginStore.clearLogin();
            router.push('/login');
        }

        const toggleMenu = () => { isCollapsed.value = !isCollapsed.value; }

        const selectOption = (page, titulo) => {
            nmComponenteAtivo.value = page;
            nmTitulo.value = titulo;
        }

        const setComponenteAtivo = (nome, titulo) => {
          nmComponenteAtivo.value = nome;
          nmTitulo.value = titulo;
        }

        const updateTimeRemaining = () => {
            const token = localStorage.getItem('jwtToken');
            if (token){
                try{
                    const decodedToken = jwtDecode(token);
                    const expiration = decodedToken.exp * 1000;
                    const now = Date.now();
                    const difference = expiration - now;

                    if (difference > 0){
                        const hours         = Math.floor((difference / (1000 * 60 * 60)) % 24);
                        const minutes       = Math.floor((difference / (1000 * 60)) % 60);
                        const seconds       = Math.floor((difference / 1000) % 60);
                        const formattedTime = [
                            hours.toString().padStart(2, '0'),
                            minutes.toString().padStart(2, '0'),
                            seconds.toString().padStart(2, '0')
                        ].join(':');
                        timeRemaining.value = formattedTime;
                    }else{
                        timeRemaining.value = "Token expirado";
                        clearInterval(timer);
                        // TODO: REDIRECIONAR PARA TELA DE LOGIN OU EMITIR ALERTA E REATIVAR A SESSÃO
                    }
                }catch(error){
                    console.error('Erro ao decodificar o token:', error);
                    timeRemaining.value = "Erro ao verificar o token";
                    clearInterval(timer);
                }
            } else {
                timeRemaining.value = "Token não encontrado";
                clearInterval(timer);
                // TODO: REDIRECIONAR PARA TELA DE LOGIN OU EMITIR ALERTA E REATIVAR A SESSÃO
            }
        };

        onMounted(() => {
            updateTimeRemaining();
            timer = setInterval(updateTimeRemaining, 1000);
        });

        onBeforeUnmount(() => {
            clearInterval(timer);
        });

        return {
            isCollapsed,
            loginStore,
            nmComponenteAtivo,
            nmTitulo,
            timeRemaining,
            confirmLogout,
            toggleMenu,
            selectOption,
            setComponenteAtivo
        };
    }
};
</script>

<style scoped src="@/assets/css/main.css"></style>
