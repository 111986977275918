<template>
    <div v-if="isLoading" class="loading-overlay"><p>Carregando...</p></div>
    <MensagemAlerta class="margin-bottom05" v-if="errorMessage" :mensagem="errorMessage" />

    <div class="form-edit-page">
        <div class="row row-space">
            <div class="form-item" style="flex: 0 0 90px;">
                <label class="label-caption">Nro OS</label>
                <div class="field-label field-label-end">{{ ipOsView.cdipos }}</div>
            </div>

            <div class="form-item form-item-data">
                <label class="label-caption">Data</label>
                <div class="field-label field-label-center">{{ formatDateDMY(ipOsView.dtipos) }}</div>
            </div>

            <div class="form-item">
                <label class="label-caption">Bairro</label>
                <div class="field-label">{{ ipOsView.nmbairro }}</div>
            </div>

            <div class="form-item">
                <label class="label-caption">Logradouro</label>
                <div class="field-label">{{ ipOsView.nmlogradouro }}</div>
            </div>
        </div>
        

        <form v-if="!showEncerramento" @submit.prevent="submitForm">
            <div class="row row-space">
                <ProdutoSelect class="form-item" v-model:produtoSelecionado="produtoSelecionado" required />

                <div class="form-item" style="flex: 0 0 150px;">
                    <label class="label-caption">Quantidade</label>
                    <input type="text" class="input-right" v-model="edQuantidade" v-decimal-mask maxlength="10" required/>
                </div>
            </div>

            <div class="row-end margin-top10">
                <button type="submit">Salvar Ítem</button>
                <button type="button" @click="showEncerramento=true">Encerrar OS</button>
                <button type="cancel" @click="cancelForm">Sair</button>
            </div>
        </form>
    </div>


    <div v-if="showEncerramento" class="form-edit-page margin-top10">
        <form @submit.prevent="submitFormEncerramento">
            <div class="row row-space">
                <div class="form-item form-item-data">
                    <label class="label-caption">Data Início</label>
                    <input type="date" v-model="edDtInicio" required />
                </div>

                <div class="form-item" style="flex: 0 0 80px;">
                    <label class="label-caption">Hora</label>
                    <input type="text" class="input-center" v-time-mask v-model="edHrInicio" required />
                </div>

                <div class="form-item form-item-data">
                    <label class="label-caption">Data Fim</label>
                    <input type="date" v-model="edDtFim" required />
                </div>

                <div class="form-item" style="flex: 0 0 80px;">
                    <label class="label-caption">Hora</label>
                    <input type="text" class="input-center" v-time-mask v-model="edHrFim" required />
                </div>

                <div class="form-item flexible">
                    <label class="label-caption">Observações</label>
                    <input type="text" v-model="edObsExecucao" v-uppercase required maxlength="100" />
                </div>
            </div>

            <div class="row-end margin-top10">
                <button type="submit">Confirmar Encerramento</button>
                <button type="cancel" @click="showEncerramento=false">Cancelar</button>
            </div>
        </form>
    </div>


    <div class="form-edit-page margin-top10">
        <table>
            <thead>
                <tr>
                    <th class="col-width-integer">Produto</th>
                    <th>Descrição</th>
                    <th class="col-width-number">Quantidade</th>
                    <th class="col-width-acoes-2">Ações</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(obj, index) in lsItens" :key="obj.id" :class="{'even-row': index % 2 === 0, 'odd-row': index % 2 !== 0}">
                    <td class="col-width-integer right">{{ obj.cdproduto }}</td>
                    <td>{{ obj.nmproduto }}</td>
                    <td class="col-width-number right">{{ formatDecimal(obj.qtd) }}</td>
                    <td class="col-width-acoes-2"><div class="row-left">
                        <button v-if="!showEncerramento" class="btn-icon btn-icon--trash" @click="excluiItem(obj)"></button>
                    </div></td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
  
<script>
import { ref } from 'vue';
import { decimalFromString, formatDateFromJson, formatDecimal } from '@/funcoes/utils';
import { genericDelete, genericPost, genericPut } from '@/funcoes/api';
import { globalStore } from '@/globals';
import MensagemAlerta from '@/components/MensagemAlerta.vue';
import ProdutoSelect from '@/selects/ProdutoSelect.vue';

export default {
    components:{MensagemAlerta, ProdutoSelect},
    emits: ['close', 'salvo'],
    props: {ipOsView: {type: Object}},
    setup(props, { emit }) {
        const edDtInicio = ref(null);
        const edDtFim = ref(null);
        const edHrInicio = ref(null);
        const edHrFim = ref(null);
        const edQuantidade = ref(null);
        const edObsExecucao = ref(null);
        const errorMessage = ref(null);
        const isLoading = ref(false);
        const lsItens = ref([]);
        const produtoSelecionado = ref(null);
        const showEncerramento = ref(false);

        const cancelForm = () => {emit('close');};

        const excluiItem = async(item) => {
            const confirmacao = window.confirm("Confirma exclusão do ítem?");
            if (confirmacao){
                try{
                    isLoading.value=true;
                    const r = await genericDelete('ipos_itens', item.id, globalStore.idusuario);
                    if (r) {lerDados();}
                } catch(error){errorMessage.value=error;
                }finally{isLoading.value=false;}
            }
        }

        const lerDados = async() => {
            try{
                isLoading.value=true;
                const req = await genericPost('/ip/ipos/itens', {idos: props.ipOsView.id});
                lsItens.value = req;
            }catch(error){errorMessage.value = error;
            }finally{isLoading.value=false;}
        };

        const submitForm = async () => {
            try {
                isLoading.value = true;
                errorMessage.value = null;
                const response  = await genericPut('/cad/ipos_item', {id: 0, idipos: props.ipOsView.id, idproduto: produtoSelecionado.value,
                    qtd: decimalFromString(edQuantidade.value), idusuario: globalStore.idusuario});
                if (response.bolok === 1){
                    edQuantidade.value = null;
                    lerDados();
                }else{
                    errorMessage.value=response.erro;
                }
            } catch (error) {errorMessage.value=error;
            } finally{isLoading.value=false;}
        };

        const submitFormEncerramento = async() => {
            try{
                isLoading.value=true;
                const r1 = await genericPut('/cad/ipos_evento', {
                    id: 0,
                    idipos: props.ipOsView.id,
                    idresponsavel: globalStore.idusuario,
                    dtevento: edDtInicio.value,
                    hrevento: edHrInicio.value,
                    tpevento: 1,
                    origem_sistema: 'WEB',
                    idusuario: globalStore.idusuario});
                if (r1.bolok===1){
                    const r2 = await genericPut('/cad/ipos_evento', {
                        id: 0,
                        idipos: props.ipOsView.id,
                        idresponsavel: globalStore.idusuario,
                        dtevento: edDtFim.value,
                        hrevento: edHrFim.value,
                        tpevento: 3,
                        detalhamento: edObsExecucao.value,
                        origem_sistema: 'WEB',
                        idusuario: globalStore.idusuario});
                    if (r2.bolok===1){
                        emit('salvo');
                        emit('close');
                    }else{errorMessage.value=r2.erro;}
                }else{errorMessage.value=r1.erro;}
            }finally{isLoading.value=false;}
        }

        lerDados();

        return {edDtFim, edDtInicio, edHrFim, edHrInicio, edQuantidade, edObsExecucao, errorMessage, isLoading, lsItens, produtoSelecionado, showEncerramento,
            cancelForm, excluiItem, formatDateDMY: formatDateFromJson, formatDecimal, submitForm, submitFormEncerramento };
    }
};
</script>