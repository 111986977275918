<template>
    <div v-if="isLoading" class="loading-overlay"><p>Carregando...</p></div>
    <MensagemAlerta class="row-space" v-if="errorMessage" :mensagem="errorMessage" />

    <div class="form-edit-page row-space">
        <div class="row row-space">
            <div class="form-item-char10">
                <label class="label-caption">OS</label>
                <div class="field-label field-label-end">{{ formatInteger(objView.cdos) }}</div>
            </div>

            <div class="form-item-char10">
                <label class="label-caption">Inclusão</label>
                <div class="field-label field-label-center">{{ formatDateFromJson(objView.dtinclusao) }}</div>
            </div>

            <div class="form-item">
                <label class="label-caption">Nome</label>
                <div class="field-label">{{ objView.nmpessoa }}</div>
            </div>

            <div class="form-item-char40">
                <label class="label-caption">Localidade</label>
                <div class="field-label">{{ objView.nmlocalidade }}</div>
            </div>

            <button type="button" class="buttom-icon--close" @click="closeForm" />
        </div>


        <div class="row row-space">
            <div class="form-item">
                <label class="label-caption">Grupo</label>
                <div class="field-label">{{ objView.nmgrupo }}</div>
            </div>

            <div class="form-item">
                <label class="label-caption">Equipamento</label>
                <div class="field-label">{{ objView.nmequipamento }}</div>
            </div>

            <div class="form-item">
                <label class="label-caption">Implemento</label>
                <div class="field-label">{{ objView.nmimplemento }}</div>
            </div>
        </div>
        

        <div class="row row-space">
            <div class="form-item">
                <label class="label-caption">Serviço</label>
                <div class="field-label">{{ objView.nmservico }}</div>
            </div>

            <div class="form-item-char10">
                <label class="label-caption">Contratado</label>
                <div class="field-label field-label-center">{{ objView.hrcontratadas }}</div>
            </div>

            <div class="form-item-char10">
                <label class="label-caption">Executado</label>
                <div class="field-label field-label-center">{{ objView.hrtrabalhadas }}</div>
            </div>

            <div class="form-item-char20">
                <label class="label-caption">Data/Hora Início</label>
                <div class="field-label field-label-center">{{ formatDateFromJson(objView.dtinicio) }} - {{ formatTimeFromJson(objView.hrinicio) }}</div>
            </div>

            <div class="form-item-char20">
                <label class="label-caption">Data/Hora Fim</label>
                <div class="field-label field-label-center">{{ formatDateFromJson(objView.dtfim) }} - {{ formatTimeFromJson(objView.hrfim) }}</div>
            </div>
        </div>
    </div>

    <table>
        <thead>
            <tr>
                <th class="col-width-date">Data</th>
                <th class="col-width-date">Hora</th>
                <th>Descrição</th>
                <th class="col-width-char30">Operador</th>
                <th class="col-width-char30">Observações</th>
                <th class="col-width-char10">Horas/Km</th>
                <th class="col-width-char30">Coordenadas</th>
                <th class="col-width-char10">Imagens</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(obj, index) in lsDados" :key="obj.id" :class="{'even-row': index % 2 === 0, 'odd-row': index % 2 !== 0, 'clickabe-row': true}">
                <td class="col-width-date center">{{ formatDateFromJson(obj.dtevento) }}</td>
                <td class="col-width-date center">{{ formatTimeFromJson(obj.hrevento) }}</td>
                <td>{{ obj.xtpevento }}</td>
                <td class="col-width-char30">{{ obj.nmoperador }}</td>
                <td class="col-width-char30">{{ obj.obs }}</td>
                <td class="col-width-char10 right">{{ formatDecimal(obj.horaskm) }}</td>
                <td class="col-width-char30 center" @click="abrirMapa(obj)">{{ obj.latitude }} x {{ obj.longitude }}</td>
                <td class="col-width-char10 center" @click="abrirFotos(obj)">{{ (obj.idanexo) || (obj.idanexo2) ? 'SIM' : 'NÃO' }}</td>
            </tr>
        </tbody>
    </table>

    <div v-if="imageSrcH">
        <div class="row-space"></div>
        <div class="form-edit-page">
            <div v-if="imageSrcH">
                <img :src="imageSrcH" alt="Imagem carregada"/>
            </div>
        </div>
    </div>

    <div v-if="imageSrcL">
        <div class="row-space"></div>
        <div class="form-edit-page">
            <div v-if="imageSrcL">
                <img :src="imageSrcL" alt="Imagem carregada"/>
            </div>
        </div>
    </div>
</template>

<script>
import { onMounted, ref } from 'vue';
import { genericGet, genericPost } from '@/funcoes/api';
import { formatDateFromJson, formatDecimal, formatInteger, formatTimeFromJson } from '@/funcoes/utils';
import { globalStore } from '@/globals';
import MensagemAlerta from '@/components/MensagemAlerta.vue';

export default{
    components:{ MensagemAlerta },
    emits:['close'],
    props:{idView: {type: Number, default: 0}},
    setup(props, {emit}){
        const errorMessage = ref(null);
        const imageSrcH = ref(null);
        const imageSrcL = ref(null);
        const isLoading = ref(false);
        const lsDados = ref([]);
        const objView = ref({});

        const abrirFotos = async(obj) =>{
            if ((!obj.idanexo) && (!obj.idanexo2)){
                errorMessage.value = 'Evento não possui fotos anexadas';
            }else{
                errorMessage.value = null;
                if (obj.idanexo){
                    const reqH = await genericGet('/main/anexo_view_base64', {id: obj.idanexo});
                    if (reqH){
                        imageSrcH.value = `data:image/jpeg;base64,${reqH.anexo}`;
                    }
                }
                if (obj.idanexo2){
                    const reqL = await genericGet('/main/anexo_view_base64', {id: obj.idanexo2});
                    if (reqL){
                        imageSrcL.value = `data:image/jpeg;base64,${reqL.anexo}`;
                    }
                }
            }
        }

        const abrirMapa = async(obj) =>{
            if ((!obj.latitude) || (!obj.longitude)){
                errorMessage.value = 'Evento não possui coordenadas';
            }else{
                errorMessage.value = null;
                const zoom = 15;
                // const url = `https://www.google.com/maps/search/?api=1&query=${obj.latitude},${obj.longitude}`;
                // const url = `https://www.google.com/maps/@?api=1&map_action=map&center=${obj.latitude},${obj.longitude}&zoom=${zoom}&basemap=satellite`;
                const url = `https://www.google.com/maps?q=${obj.latitude},${obj.longitude}&t=k&z=${zoom}`;
                window.open(url, '_blank');
            }
        }

        const closeForm = () => {emit('close');}

        const lerDados = async() => {
            try{
                errorMessage.value = null;
                isLoading.value    = true;
                const req = await genericPost('/os/vwos', {idmunicipio: globalStore.idmunicipio, idos: props.idView});
                Object.assign(objView.value, req[0]);

                const h = await genericGet('/os/historico', {id: props.idView});
                lsDados.value = h;
            }catch (error){errorMessage.value = error;
            }finally{isLoading.value = false;}
        }

        onMounted(()=>{lerDados();});

        return{
            errorMessage, imageSrcH, imageSrcL, isLoading, lsDados, objView,
            abrirFotos, abrirMapa, closeForm, formatDateFromJson, formatDecimal, formatInteger, formatTimeFromJson}
    }
}
</script>