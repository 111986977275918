<template>
    <div v-if="showModal" class="modal-overlay">
        <div class="modal">
            <h3>Selecione o Município</h3>

            <table class="municipio-table">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Município</th>
                        <th>Ação</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="municipio in municipios" :key="municipio.id">
                        <td>{{ municipio.id }}</td>
                        <td>{{ municipio.nmmunicipio }}</td>
                        <td><button @click="selecionarMunicipio(municipio)">Selecionar</button></td>
                    </tr>
                </tbody>
            </table>
            <button class="close-button" @click="closeModal">Fechar</button>
        </div>
    </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { genericPost } from '@/funcoes/api'; // Ajuste conforme sua função de API

export default {
    name: 'ModalSuporteTrocaMunicipio',
    props: {
        showModal: { type: Boolean, required: true },
        listaMunicipios: { type: Array, default: null }
    },
    emits: ['close', 'municipioSelecionado'],
    setup(props, { emit }) {
        const municipios = ref([]);

        const carregarMunicipios = async () => {
            if (props.listaMunicipios) {
                municipios.value = props.listaMunicipios;
            } else {
                try {
                    const response = await genericPost('/listas/municipios_ativos', {nmpesquisar: ''});
                    municipios.value = response || [];
                } catch (error) {
                    console.error('Erro ao carregar municípios:', error);
                }
            }
        };

        const selecionarMunicipio = (municipio) => {
            emit('municipioSelecionado', municipio); // Emite o município selecionado para o componente pai
            closeModal();
        };

        const closeModal = () => {emit('close');};

        onMounted(() => {carregarMunicipios();});

        return { municipios, selecionarMunicipio, closeModal };
    }
};
</script>

<style scoped>
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal {
    background: white;
    padding: 20px;
    border-radius: 8px;
    max-width: 500px;
    width: 100%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.municipio-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
}

.municipio-table th,
.municipio-table td {
    border: 1px solid #ccc;
    padding: 8px;
    text-align: left;
}

.municipio-table th {
    background: #f4f4f4;
}

.close-button {
    margin-top: 20px;
    background: #f44;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 4px;
}

.close-button:hover {
    background: #d33;
}
</style>
  