<template>
    <div class="content-row">
        <div class="content-data">
            <div class="form-edit-page">
                <div class="row row-space">
                    <div class="form-item" style="flex: 0 0 90px;">
                        <label class="label-caption">Solicitação</label>
                        <div class="field-label field-label-end">{{ formatInteger(objView.cdipossolicitacao) }}</div>
                    </div>

                    <div class="form-item form-item-data">
                        <label class="label-caption">Data</label>
                        <div class="field-label field-label-center">{{ formatDateFromJson(objView.dtsolicitacao) }}</div>
                    </div>

                    <div class="form-item">
                        <label class="label-caption">Tipo Serviço</label>
                        <div class="field-label">{{ objView.nmipservico }}</div>
                    </div>

                    <div class="form-item" style="flex: 0 0 220px;">
                        <label class="label-caption">Situação</label>
                        <div class="field-label field-label-center">{{ objView.statusnm }}</div>
                    </div>
                </div>


                <div class="row row-space">
                    <div class="form-item ">
                        <label class="label-caption">Solicitante</label>
                        <div class="field-label">{{ objView.nmpessoa }}</div>
                    </div>

                    <div class="form-item" style="flex: 0 0 220px;">
                        <label class="label-caption">Coordenadas</label>
                        <div class="field-label field-label-center">{{ objView.latitude }} x {{ objView.longitude }}</div>
                    </div>
                </div>


                <div class="row row-space">
                    <div class="form-item">
                        <label class="label-caption">Bairro</label>
                        <div class="field-label">{{ objView.nmbairro }}</div>
                    </div>

                    <div class="form-item ">
                        <label class="label-caption">Logradouro</label>
                        <div class="field-label">{{ objView.nmlogradouro }}</div>
                    </div>

                    <div class="form-item">
                        <label class="label-caption">Referência</label>
                        <div class="field-label">{{ objView.referencia }}</div>
                    </div>
                </div>


                <div class="row row-space">
                    <div class="form-item">
                        <label class="label-caption">Detalhamento</label>
                        <div class="field-label">{{ objView.detalhamento }}</div>
                    </div>            
                </div>


                <div class="row-end">
                    <button v-if="objView.status==1" type="button" class="btn-confirm" @click="gerarOS">Gerar OS</button>
                    <button v-if="objView.status==1" type="button" class="btn-cancel" @click="anularSolicitacao">Excluir</button>
                    <button type="button" @click="closeForm">Retornar</button>
                </div>
            </div>
        </div>

        <div v-if="imageSrc" class="content-foto">
            <div class="form-edit-page">
                <div v-if="imageSrc">
                    <img :src="imageSrc" alt="Imagem carregada" @click="showModalImage = true" />
                </div>
            </div>
        </div>
    </div>


    <div>
        <ImageModal v-if="showModalImage" :imageSrc="imageSrc" :visible="showModalImage" @close="showModalImage=false" />
    </div>
    

    <div v-if="showMap && !showModalImage" class="margin-top10">
        <MapComponent :latitude="objView.latitude" :longitude="objView.longitude" />
    </div>
</template>

<script>
import { ref } from 'vue';
import { formatDateFromJson, formatDateTimeFromJson, formatDecimal, formatInteger } from '@/funcoes/utils';
import { genericGet, genericPost, genericPut } from '@/funcoes/api';
import { globalStore } from '@/globals';
import ImageModal from '@/components/modals/ImageModal.vue';
import MapComponent from '@/components/MapComponent.vue';

export default{
    components:{ImageModal, MapComponent},
    emits:['close','save'],
    props:{idView: {type: Number, default: 0}},
    setup(props, {emit}){
        const imageSrc = ref(null);
        const isLoading = ref(false);
        const objView = ref({});
        const showMap = ref(false);
        const showModalImage = ref(false);

        const anularSolicitacao = async() => {
            try{
                isLoading.value=true;
                const r = await genericPut('/cad/ipos_solicitacao', {id: objView.value.id, lganulacao: globalStore.idlogin, obanulacao: 'ANULADO VIA WEB-APP'});
                if (r.bolok==1){
                    emit('close');
                    emit('save');
                }
            }finally{isLoading.value=false;}
        }

        const closeForm = () => {emit('close');}

        const gerarOS = async() => {
            try{
                isLoading.value=true;
                const r = await genericPost('/ip/ipossolicitacoes/gera_os', {id: objView.value.id, idusuario: globalStore.idusuario});
                if (r.bolok==1){
                    emit('close');
                    emit('save');
                }
            }finally{isLoading.value=false;}
        }

        const lerDados = async() => {
            try{
                isLoading.value = true;
                const req = await genericPost('/ip/ipossolicitacoes', {idmunicipio: globalStore.idmunicipio, id: props.idView});
                Object.assign(objView.value, req[0]);
                showMap.value = ((objView.value) && (objView.value.latitude) && (objView.value.longitude));

                // LISTA DE ANEXOS  --  MOSTRANDO APENAS O PRIMEIRO
                const lsImagens = await genericPost('/ip/ipossolicitacoes/anexos', {idmunicipio: globalStore.idmunicipio, id: objView.value.id});
                if (lsImagens.length > 0){
                    const reqFoto = await genericGet('/main/anexo_view_base64', {id: lsImagens[0].idanexo});
                    if (reqFoto){
                        imageSrc.value = `data:image/jpeg;base64,${reqFoto.anexo}`;
                    }
                }
            }catch (error){alert(error);
            }finally{isLoading.value = false;}
        }

        lerDados();

        return{
            imageSrc, isLoading, objView, showMap, showModalImage,
            anularSolicitacao, closeForm, formatDateFromJson, formatDateTimeFromJson, formatDecimal, formatInteger, gerarOS}
    }
}
</script>

<style scoped>
.content-row{
    display: flex;
    gap: 10px;
}
.content-data{
    flex: 1;
}
.content-foto{
    width: 300px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
.content-foto img{
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
    border-radius: 8px;
    cursor: pointer;
}
</style>