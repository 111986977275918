<template>
    <div v-if="isLoading" class="loading-overlay"><p>Carregando...</p></div>


    <div v-if="((!modoEdicao) && (!modoVisualizacao))">
        <div class="form-edit-page row-space">
            <div class="row">
                <div class="form-item-rangedata">
                    <DateRangeSelect v-model="dateRange" />
                </div>

                <IpOsStatusSelect class="form-item" v-model:statusSelecionado="statusSelecionado" />

                <button type="button" class="buttom-icon--search" @click="lerDados" />
                <button type="button" class="buttom-icon--add" @click="novoCadastro"></button>
                <button type="button" class="buttom-icon--printer" @click="imprimir" />
                <button type="button" class="buttom-icon--close" @click="closeForm" />
            </div>
        </div>

        <MensagemAlerta class="margin-top10" v-if="errorMessage" :mensagem="errorMessage" />
        
        <table class="margin-top10">
            <thead>
                <tr>
                    <th class="col-width-integer">Código</th>
                    <th class="col-width-date">Data</th>
                    <th>Bairro</th>
                    <th>Logradouro</th>
                    <th class="col-width-medium">Situação</th>
                    <th class="col-width-acoes-2">Ações</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(obj, index) in lsDados" :key="obj.id" :class="{'even-row': index % 2 === 0, 'odd-row': index % 2 !== 0}">
                    <td class="col-width-integer right">{{ obj.cdipos }}</td>
                    <td class="col-width-date center">{{ formatDateDMY(obj.dtipos) }}</td>
                    <td>{{ obj.nmbairro }}</td>
                    <td>{{ obj.nmlogradouro }}</td>
                    <td class="col-width-medium">{{ obj.statusnm }}</td>
                    <td class="col-width-acoes-2"><div class="row-left">
                        <button v-if="obj.status<5" class="btn-icon btn-icon--edit" @click="editarCadastro(obj)"></button>
                        <button v-if="obj.status==1" class="btn-icon btn-icon--checkMark" @click="autorizarOs(obj)"></button>
                        <button class="btn-icon btn-icon--view" @click="visualizarIpOS(obj)"></button>
                    </div></td>
                </tr>
            </tbody>
        </table>
    </div>


    <div v-if="modoEdicao">
        <IpOsEditPage :idEdicao="idEdicao" @close="modoEdicao = false" @salvo="lerDados" />
    </div>


    <div v-if="modoVisualizacao">
        <IpOSViewPage :idView="idView" @close="modoVisualizacao = false" />
    </div>
</template>


<script>
import { ref } from 'vue';
import { formatDateFromJson, strDataHoraAtual } from '@/funcoes/utils';
import { genericPost, genericPut, genericReport } from '@/funcoes/api'; 
import { globalStore } from '@/globals';
import DateRangeSelect from '@/components/DateRangeSelect.vue';
import IpOsEditPage from './IpOsEditPage.vue';
import IpOsStatusSelect from '@/selects/IpOsStatusSelect.vue';
import IpOSViewPage from '../cadastros/visualizacoes/IpOSViewPage.vue';
import MensagemAlerta from '@/components/MensagemAlerta.vue';

export default{
    emits: ['close'],
    components:{DateRangeSelect, IpOsEditPage, IpOsStatusSelect, IpOSViewPage, MensagemAlerta},

    setup(_, { emit }){
        const dateRange = ref({ startDate: "", endDate: "", selectedPeriod: "" });
        const errorMessage = ref(null);
        const idEdicao = ref(0);
        const idView = ref(0);
        const isLoading = ref(false);
        const lsDados = ref([]);
        const modoEdicao = ref(false);
        const modoVisualizacao = ref(false);
        const nmPesquisa = ref(null);
        const statusSelecionado = ref(null);

        const autorizarOs = async(obj) => {
            try{
                isLoading.value=true;
                const r = await genericPut('/cad/ipos', {
                    id: obj.id, 
                    dhautorizacao: strDataHoraAtual(), 
                    lgautorizacao: globalStore.idlogin});
                if (r.bolok === 1){
                    lerDados();
                }else{errorMessage.value = r.error;}
            }finally{isLoading.value=false;}

        }

        const closeForm = () => {emit('close');}

        const editarCadastro = (obj) => {
            idEdicao.value = obj.id;
            modoEdicao.value = true;
        };

        const imprimir = async() => {
            try{
                if (!dateRange.value.startDate || !dateRange.value.endDate){
                    throw new Error('Necessário informar ambas as datas.');
                }
                const dataInicio = new Date(dateRange.value.startDate);
                const dataFim = new Date(dateRange.value.endDate);

                if (isNaN(dataInicio.getTime()) || isNaN(dataFim.getTime())){
                    throw new Error('Datas inválidas. Verifique o formato das datas.');
                }

                if (dataFim < dataInicio){
                    throw new Error('A data final deve ser maior ou igual à data inicial.');
                }

                errorMessage.value = null;
                isLoading.value = true;
                const dados = {
                    nmrelatorio: 'ipos_dtipos',
                    idmunicipio: globalStore.idmunicipio,
                    idusuario: globalStore.idusuario,
                    datainicial: dateRange.value.startDate,
                    datafinal: dateRange.value.endDate,
                    intp1: statusSelecionado.value};
                const response = await genericReport(dados);
                const pdfBlob = new Blob([response], { type: 'application/pdf' });
                const pdfUrl = URL.createObjectURL(pdfBlob);
                window.open(pdfUrl, '_blank');
            } catch (error){errorMessage.value = `ATENÇÃO: ${error.message}`;
            } finally {isLoading.value = false;}
        }

        const lerDados = async() => {
            if ((!dateRange.value.startDate) || (!dateRange.value.endDate)){
                errorMessage.value = 'Informe corretamente um período';
                return;
            }
            try{
                errorMessage.value = null;
                isLoading.value = true;
                const req = await genericPost('/ip/ipos', {
                    idmunicipio: globalStore.idmunicipio,
                    datainicial: dateRange.value.startDate, 
                    datafinal:dateRange.value.endDate,
                    intparam1: statusSelecionado.value});
                lsDados.value = req;
            } catch (error){errorMessage.value=error;
            } finally {isLoading.value = false;}
        }

        const novoCadastro = () => {
            idEdicao.value = 0;
            modoEdicao.value = true;
        }

        const visualizarIpOS = (obj) =>{
            idView.value = obj.id;
            modoVisualizacao.value = true;
        }

        return { dateRange, errorMessage, idEdicao, isLoading, idView, lsDados, modoEdicao, modoVisualizacao, nmPesquisa, statusSelecionado,
            autorizarOs, closeForm, editarCadastro, formatDateDMY: formatDateFromJson, imprimir, lerDados, novoCadastro, visualizarIpOS };
    }
}
</script>
