<template>
    <div v-if="isLoading" class="loading-overlay"><p>Carregando...</p></div>


    <div class="form-edit-page row-space">
        <div class="row">
            <KeepAlive>
                <div class="form-item">
                    <EquipamentoGrupoSelect class="form-item" :ckAddTodos="false" v-model:grupoSelecionado="idGrupo" />
                </div>
            </KeepAlive>
            
            <button type="button" class="buttom-icon--search" @click="lerDados" />
            <button type="button" class="buttom-icon--close" @click="closeForm" />
        </div>
    </div>


    <MensagemAlerta class="row-space" v-if="errorMessage" :mensagem="errorMessage" />

        
    <div class="content-dash">
        <div v-if="lsDados.length!=0" class="row necessario-criar-estilo-para-adequar">
            <CalendarioAgendamentoComponent :data="lsDados" />
        </div>
    </div>
</template>


<script>
import { ref, watch } from 'vue';
import { genericPost } from '@/funcoes/api'; 
import { globalStore } from '@/globals';
import CalendarioAgendamentoComponent from '@/components/CalendarioAgendamentoComponent.vue';
import EquipamentoGrupoSelect from '@/selects/EquipamentoGrupoSelect.vue';
import MensagemAlerta from '@/components/MensagemAlerta.vue';

export default{
    emits: ['close'],
    components:{ CalendarioAgendamentoComponent, EquipamentoGrupoSelect, MensagemAlerta },

    setup(_, { emit }){
        const errorMessage = ref(null);
        const idGrupo = ref(null);
        const isLoading = ref(false);
        const lsDados = ref([]);

        const closeForm = () => {emit('close');}

        const lerDados = async() => {
            try{
                errorMessage.value = null;
                isLoading.value = true;
                const req = await genericPost('/os/calendario/grupo', {
                    idmunicipio: globalStore.idmunicipio, 
                    idequipamentogrupo: idGrupo.value});
                lsDados.value = req;
                if (lsDados.value.length===0){errorMessage.value='Nenhuma OS agendada para o grupo selecionado'}
            } catch (error){errorMessage.value=error;
            } finally {isLoading.value = false;}
        }

        watch(idGrupo, () => {
            lerDados();
        });
        
        return { 
            errorMessage, idGrupo, isLoading, lsDados,
            closeForm, lerDados
        };
    }
}
</script>

<style scoped>
.content-dash{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
}
.necessario-criar-estilo-para-adequar{
    width: 100%;
}
</style>
