import { reactive } from 'vue';

export const criarLogradouro = () => {
    return reactive({
        id: 0,
        idmunicipio: 0,
        cdlogradouro: 0,
        nmlogradouro: '',
        ativo: 'S',
        idusuario: 0
    });
};