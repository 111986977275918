export function decimalFromString(value) {
    if (!value) return 0;
    return parseFloat(value.toString().replace(/\./g, '').replace(',', '.')) || 0;
}

export function decimalFromTimeString(horaString) {
    if (!horaString || horaString.length != 5) return 0;
    const [horas, minutos] = horaString.split(":").map(Number);
    return horas + minutos / 60;
}

export function formatDate(date) {
    const day = String(date.getDate()).padStart(2, '0')
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const year = date.getFullYear()
    return `${day}/${month}/${year}`
}

export function formatDateFromJson(dataStr){
    if (!dataStr) return '';
    const datePart = dataStr.includes('T') ? dataStr.split('T')[0] : dataStr;
    const [year, month, day] = datePart.split('-');
    return `${day}/${month}/${year}`;
}

export function formatDateTimeFromJson(dataStr) {
    if (!dataStr) return '';
    const [datePart, timePart] = dataStr.includes('T') ? dataStr.split('T') : [dataStr, ''];
    const [year, month, day] = datePart.split('-');
    const [hour, minute] = timePart.split(':');
    return `${day}/${month}/${year} ${hour}:${minute}`;
}

export function formatDecimal(value) {
    if (value===0) return '0,00';
    if (!value) return '';
    return parseFloat(value.toString().replace(',', '.')).toLocaleString('pt-BR', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });
}

export function formatInteger(value) {
    if (!value) return '';
    return parseInt(value).toLocaleString('pt-BR');
}

export function formatTimeFromDecimal(value){
    if (value === 0){return '00:00';}
    let hours = Math.floor(value);
    let minutes = Math.round((value - hours) * 60);
    if (minutes === 60) {
        hours++;
        minutes = 0;
    }
    const hoursStr = String(hours).padStart(2, '0');
    const minutesStr = String(minutes).padStart(2, '0');
    return `${hoursStr}:${minutesStr}`;
}

export function formatTimeFromJson(value){
    if (!value) return '';
    return String(value).substring(0,5);
}

export function integerFromString(value) {
    if (!value) return 0;
    return parseInt(value.toString().replace(/\./g, '')) || 0;
}

export function strDataAtual(){
    const now = new Date();
    const day = String(now.getDate()).padStart(2, '0');
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const year = now.getFullYear();
    return `${day}/${month}/${year}`;
}

export function strDataHoraAtual(){
    const now = new Date();
    const day = String(now.getDate()).padStart(2, '0');
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const year = now.getFullYear();
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    return `${day}/${month}/${year} ${hours}:${minutes}`;
}