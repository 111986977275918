<template>
    <div class="dashboard">
        <div class="stats-row">
            <div class="stat-card">
                <h3>Total de Chamados</h3>
                <h2>{{ stats.ttos }}</h2>
            </div>

            <div class="stat-card">
                <h3>Chamados Aguardando</h3>
                <h2>{{ stats.ttosaguardando }}</h2>
            </div>

            <div class="stat-card">
                <h3>Chamados Atendidos</h3>
                <h2>{{ stats.ttosatendida }}</h2>
            </div>
        </div>


        <div class="chart-row">
            <IpOSChamadosMensalDash :data="chartData" />
        </div>
    </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { genericPost } from '@/funcoes/api';
import { globalStore } from '@/globals';
import IpOSChamadosMensalDash from './IpOSChamadosMensalDash.vue';

export default{
    components: {IpOSChamadosMensalDash},
    setup(){
        const stats = ref({
            ttos: 0,
            ttosaguardando: 0,
            ttosatendida:0,
        });
        const chartData = ref([]);

        const loadStats = async () => {
            try{
                const response = await genericPost('/dashboard/ipos/resumo', {idmunicipio: globalStore.idmunicipio});
                stats.value = response[0];
            }catch(error){console.error('Erro ao carregar dados de estatísticas:', error);}
        };

        const loadChartData = async () => {
            const now = new Date();
            let inicio, fim;
            try{
                inicio = new Date(now.getFullYear(), now.getMonth() -11, 1);
                fim = new Date(now.getFullYear(), now.getMonth() + 1, 0);

                const response = await genericPost('/dashboard/ipos/chamados_mensal', {
                    idmunicipio: globalStore.idmunicipio, 
                    datainicial: inicio.toISOString().split('T')[0], 
                    datafinal: fim.toISOString().split('T')[0]
                });

                const rAtendimentos = await genericPost('/dashboard/ipos/atendimentos_mensal', {
                    idmunicipio: globalStore.idmunicipio, 
                    datainicial: inicio.toISOString().split('T')[0], 
                    datafinal: fim.toISOString().split('T')[0]
                });

                chartData.value = [
                    response.map(item => ({
                        x: `${item.ano}-${String(item.mes).padStart(2, '0')}`,
                        y: item.qtdd,})),
                    rAtendimentos.map(item => ({
                        x: `${item.ano}-${String(item.mes).padStart(2, '0')}`,
                        y: item.qtdd,})),
                ];
            }catch (error){console.error('Erro ao carregar dados do gráfico:', error);}
        };

        onMounted(()=>{
            loadStats();
            loadChartData();
        });

        return{stats, chartData};
    }
}
</script>