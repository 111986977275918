<template>
    <div v-if="isLoading" class="loading-overlay"><p>Carregando...</p></div>


    <div v-show="!modoVisualizacao">
        <div class="form-edit-page row-space">
            <div class="row-start">
                <div class="form-item">
                    <label class="label-caption">Pesquisa Nome</label>
                    <input type="text" v-model="nmPesquisa" maxlength="60" v-uppercase />
                </div>
                <div class="form-item-rangedata"><DateRangeSelect v-model="dateRange" /></div>
                <div class="form-item-char30"><CobrancaParcelaStatusSelect v-model:statusSelecionado="idStatus" /></div>
                <button type="button" class="buttom-icon--search" @click="lerDados" />
                <button type="button" class="buttom-icon--close" @click="closeForm" />
            </div>
        </div>

        <MensagemAlerta class="row-space" v-if="errorMessage" :mensagem="errorMessage" />
        
        <table>
            <thead>
                <tr>
                    <th class="col-width-integer">Cobrança</th>
                    <th class="col-width-integer">Parcela</th>
                    <th class="col-width-date">Vencimento</th>
                    <th class="col-width-char15">Título nro</th>
                    <th>Nome</th>
                    <th class="col-width-number">R$ Valor</th>
                    <th class="col-width-date">Recebimento</th>
                    <th class="col-width-number">R$ Recebido</th>
                    <th class="col-width-char30">Situação</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(obj, index) in lsDados" :key="obj.id" :class="{'even-row': index % 2 === 0, 'odd-row': index % 2 !== 0, 'clickabe-row': true}"  @click="setVisualizar(obj)">
                    <td class="col-width-integer right">{{ formatInteger(obj.cdcobranca) }}</td>
                    <td class="col-width-integer right">{{ formatInteger(obj.parcela) }}</td>
                    <td class="col-width-date center">{{ formatDateFromJson(obj.dtvencimento) }}</td>
                    <td class="col-width-char15 right">{{ obj.nrotitulo }}</td>
                    <td>{{ obj.nmpessoa }}</td>
                    <td class="col-width-number right">{{ formatDecimal(obj.vlparcela) }}</td>
                    <td class="col-width-date center">{{ formatDateFromJson(obj.dtpagamento) }}</td>
                    <td class="col-width-number right">{{ formatDecimal(obj.vlrecebido) }}</td>
                    <td class="col-width-char30">{{ obj.nmsituacao }}</td>
                </tr>
            </tbody>
        </table>
        

        <div v-if="showPagination" class="pagination">
            <button :disabled="currentPage === 1" class="buttom-pagination--first" @click="currentPage=1; updatePaginatedData();" />
            <button :disabled="currentPage === 1" class="buttom-pagination--prior" @click="currentPage--; updatePaginatedData();" />

            <div class="field-label field-label-center">{{ currentPage }} de {{ Math.ceil(lsDadosMain.length / itemsPerPage) }}</div>

            <button :disabled="currentPage === Math.ceil(lsDadosMain.length / itemsPerPage)" class="buttom-pagination--next" @click="currentPage++; updatePaginatedData();" />
            <button :disabled="currentPage === Math.ceil(lsDadosMain.length / itemsPerPage)" class="buttom-pagination--last" @click="currentPage = Math.ceil(lsDadosMain.length / itemsPerPage); updatePaginatedData();" />
        </div>
    </div>


    <div v-if="modoVisualizacao">
        <CobrancaParcelaViewPage :idView="idView" @close="modoVisualizacao = false" @save="lerDados()" />
    </div>
</template>


<script>
import { ref } from 'vue';
import { formatDateFromJson, formatDecimal, formatInteger } from '@/funcoes/utils';
import { genericPost } from '@/funcoes/api'; 
import { globalStore } from '@/globals';
import CobrancaParcelaStatusSelect from '@/selects/estaticos/CobrancaParcelaStatusSelect.vue';
import CobrancaParcelaViewPage from './CobrancaParcelaViewPage.vue';
import DateRangeSelect from '@/components/DateRangeSelect.vue';
import MensagemAlerta from '@/components/MensagemAlerta.vue';

export default{
    emits: ['close'],
    components:{ CobrancaParcelaStatusSelect, CobrancaParcelaViewPage, DateRangeSelect, MensagemAlerta },

    setup(_, { emit }){
        const dateRange = ref({ startDate: "", endDate: "", selectedPeriod: "" });
        const errorMessage = ref(null);
        const idStatus = ref(0);
        const idView = ref(0);
        const isLoading = ref(false);
        const lsDados = ref([]);
        const lsDadosMain = ref([]);
        const modoVisualizacao = ref(false);
        const nmPesquisa = ref(null);
        const showPagination = ref(false);

        const currentPage = ref(1);
        const itemsPerPage = ref(globalStore.itensPorPagina);

        const closeForm = () => {emit('close');}

        const lerDados = async() => {
            try{
                errorMessage.value = null;
                isLoading.value = true;
                const req = await genericPost('/financeiro/lista/parcelas', {
                    idmunicipio: globalStore.idmunicipio,
                    datainicial: dateRange.value.startDate, 
                    datafinal:dateRange.value.endDate,
                    nmpesquisar: nmPesquisa.value,
                    intparam1: idStatus.value});
                lsDadosMain.value = req;
                currentPage.value=1;
                showPagination.value = (lsDadosMain.value.length > itemsPerPage.value);
                updatePaginatedData();
            } catch (error){errorMessage.value=error;
            } finally {isLoading.value = false;}
        }

        const setVisualizar = (obj) =>{
            idView.value = obj.id;
            modoVisualizacao.value = true;
        }

        const updatePaginatedData = () => {
            const startIndex = (currentPage.value - 1) * itemsPerPage.value;
            const endIndex = (startIndex + itemsPerPage.value);
            lsDados.value = lsDadosMain.value.slice(startIndex, endIndex);
        }

        return { 
            dateRange, errorMessage, idStatus, idView, isLoading, lsDados, modoVisualizacao, nmPesquisa,
            currentPage, itemsPerPage, lsDadosMain, showPagination,
            closeForm, formatDateFromJson, formatDecimal, formatInteger, lerDados, setVisualizar, updatePaginatedData };
    }
}
</script>
