<template>
    <div v-if="isLoading" class="loading-overlay"><p>Carregando...</p></div>

    <MensagemAlerta class="row-space" v-if="errorMessage" :mensagem="errorMessage" />


    <div v-show="!modoVisualizacao">
        <div class="form-edit-page row-space">
            <div class="row-start">
                <div class="form-item-rangedata"><DateRangeSelect v-model="dateRange" /></div>
                <div class="form-item">
                    <label class="label-caption">Pesquisa Nome</label>
                    <input type="text" v-model="nmPesquisa" maxlength="60" v-uppercase />
                </div>
                <div class="form-item"><EquipamentoSelect :ckAddTodos="true" :flagTipo="1" v-model:equipamentoSelecionado="idEquipamento" /></div>
                <button type="button" class="buttom-icon--search" @click="lerDados" />
                <button type="button" class="buttom-icon--close" @click="closeForm" />
            </div>
        </div>

        
        <table>
            <thead>
                <tr>
                    <th class="col-width-integer">OS</th>
                    <th class="col-width-date">Solicitação</th>
                    <th class="col-width-date">Execução</th>
                    <th>Nome</th>
                    <th class="col-width-char30">Serviço</th>
                    <th class="col-width-char10">Contratado</th>
                    <th class="col-width-char10">Executado</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(obj, index) in lsDados" :key="obj.id" :class="{'even-row': index % 2 === 0, 'odd-row': index % 2 !== 0, 'clickabe-row': true}"  @click="setVisualizar(obj)">
                    <td class="col-width-integer right">{{ formatInteger(obj.cdos) }}</td>
                    <td class="col-width-date center">{{ formatDateFromJson(obj.dtinclusao) }}</td>
                    <td class="col-width-date center">{{ formatDateFromJson(obj.dtfim) }}</td>
                    <td>{{ obj.nmpessoa }}</td>
                    <td class="col-width-char30">{{ obj.nmservico }}</td>
                    <td class="col-width-char10 center">{{ formatTimeFromJson(obj.hrcontratadas) }}</td>
                    <td class="col-width-char10 center">{{ formatTimeFromJson(obj.hrtrabalhadas) }}</td>
                </tr>
            </tbody>
        </table>


        <div v-if="showPagination" class="pagination">
            <button :disabled="currentPage === 1" class="buttom-pagination--first" @click="currentPage=1; updatePaginatedData();" />
            <button :disabled="currentPage === 1" class="buttom-pagination--prior" @click="currentPage--; updatePaginatedData();" />

            <div class="field-label field-label-center">{{ currentPage }} de {{ Math.ceil(lsDadosMain.length / itemsPerPage) }}</div>

            <button :disabled="currentPage === Math.ceil(lsDadosMain.length / itemsPerPage)" class="buttom-pagination--next" @click="currentPage++; updatePaginatedData();" />
            <button :disabled="currentPage === Math.ceil(lsDadosMain.length / itemsPerPage)" class="buttom-pagination--last" @click="currentPage = Math.ceil(lsDadosMain.length / itemsPerPage); updatePaginatedData();" />
        </div>
    </div>


    <div v-if="modoVisualizacao">
        <OsExecutadaViewPage :idView="idView" @close="modoVisualizacao = false" />
    </div>
</template>


<script>
import { ref } from 'vue';
import { formatDateFromJson, formatDecimal, formatInteger, formatTimeFromJson } from '@/funcoes/utils';
import { genericPost } from '@/funcoes/api'; 
import { globalStore } from '@/globals';
import DateRangeSelect from '@/components/DateRangeSelect.vue';
import EquipamentoSelect from '@/selects/EquipamentoSelect.vue';
import MensagemAlerta from '@/components/MensagemAlerta.vue';
import OsExecutadaViewPage from './OsExecutadaViewPage.vue';

export default{
    emits: ['close'],
    components:{ DateRangeSelect, EquipamentoSelect, MensagemAlerta, OsExecutadaViewPage },

    setup(_, { emit }){
        const dateRange = ref({ startDate: "", endDate: "", selectedPeriod: "" });
        const errorMessage = ref(null);
        const idEquipamento = ref(null);
        const idView = ref(0);
        const isLoading = ref(false);
        const lsDados = ref([]);
        const modoVisualizacao = ref(false);
        const nmPesquisa = ref(null);

        const currentPage = ref(1);
        const itemsPerPage = ref(globalStore.itensPorPagina);
        const lsDadosMain = ref([]);
        const showPagination = ref(false);

        const closeForm = () => {emit('close');}

        const lerDados = async() => {
            try{
                errorMessage.value = null;
                if (!dateRange.value.startDate || !dateRange.value.endDate){
                    throw new Error('Necessário informar ambas as datas.');
                }
                const dataInicio = new Date(dateRange.value.startDate);
                const dataFim = new Date(dateRange.value.endDate);

                if (isNaN(dataInicio.getTime()) || isNaN(dataFim.getTime())){
                    throw new Error('Datas inválidas. Verifique o formato das datas.');
                }

                if (dataFim < dataInicio){
                    throw new Error('A data final deve ser maior ou igual à data inicial.');
                }
                isLoading.value = true;
                const req = await genericPost('/os/vwos/executadas', {
                    idmunicipio: globalStore.idmunicipio,
                    datainicial: dateRange.value.startDate, 
                    datafinal:dateRange.value.endDate,
                    nmpesquisar: nmPesquisa.value,
                    idequipamento: idEquipamento.value});
                lsDadosMain.value = req;
                currentPage.value=1;
                showPagination.value = (lsDadosMain.value.length > itemsPerPage.value);
                updatePaginatedData();
            } catch (error){errorMessage.value=error;
            } finally {isLoading.value = false;}
        }

        const setVisualizar = (obj) =>{
            idView.value = obj.id;
            modoVisualizacao.value = true;
        }

        const updatePaginatedData = () => {
            const startIndex = (currentPage.value - 1) * itemsPerPage.value;
            const endIndex = (startIndex + itemsPerPage.value);
            lsDados.value = lsDadosMain.value.slice(startIndex, endIndex);
        }

        return { 
            dateRange, errorMessage, idEquipamento, idView, isLoading, lsDados, modoVisualizacao, nmPesquisa,
            currentPage, itemsPerPage, lsDadosMain, showPagination,
            closeForm, formatDateFromJson, formatDecimal, formatInteger, formatTimeFromJson, lerDados, setVisualizar, updatePaginatedData };
    }
}
</script>
