<template>
    <div v-if="isLoading" class="loading-overlay"><p>Carregando...</p></div>

    <div class="form-edit-page row-space">
        <div class="row-start">
            <div class="form-item-char30"><CobrancaParcelaStatusSelect v-model:statusSelecionado="idCobrancaStatus" /></div>
            <div class="form-item-rangedata"><DateRangeSelect v-model="dateRange" /></div>
            <button type="button" class="buttom-icon--printer" @click="openReport" />
            <button type="button" class="buttom-icon--close" @click="closeForm" />
        </div>
    </div>

    <MensagemAlerta v-if="errorMessage" :mensagem="errorMessage" />
</template>

<script>
import { ref } from 'vue';
import { genericReport } from '@/funcoes/api';
import { globalStore } from '@/globals';
import CobrancaParcelaStatusSelect from '@/selects/estaticos/CobrancaParcelaStatusSelect.vue';
import DateRangeSelect from '@/components/DateRangeSelect.vue';
import MensagemAlerta from '@/components/MensagemAlerta.vue';

export default{
    components: { CobrancaParcelaStatusSelect, DateRangeSelect, MensagemAlerta },
    emits: ['close'],
    setup(_, {emit}){
        const dateRange = ref({ startDate: "", endDate: "", selectedPeriod: "" });
        const errorMessage = ref(null);
        const idCobrancaStatus = ref(0);
        const isLoading = ref(false);

        const closeForm = () => {emit('close');}

        const openReport = async() => {
            try{
                if (dateRange.value.startDate || dateRange.value.endDate){
                    const dataInicio = new Date(dateRange.value.startDate);
                    const dataFim = new Date(dateRange.value.endDate);

                    if (isNaN(dataInicio.getTime()) || isNaN(dataFim.getTime())){
                        throw new Error('Datas inválidas. Verifique o formato das datas.');
                    }

                    if (dataFim < dataInicio){
                        throw new Error('A data final deve ser maior ou igual à data inicial.');
                    }
                }

                errorMessage.value = null;
                isLoading.value = true;
                const dados = {
                    nmrelatorio: 'cobranca_parelas',
                    idmunicipio: globalStore.idmunicipio,
                    datainicial: dateRange.value.startDate,
                    datafinal: dateRange.value.endDate,
                    intp1: idCobrancaStatus.value,
                    idusuario: globalStore.idusuario};
                const response = await genericReport(dados);
                const pdfBlob = new Blob([response], { type: 'application/pdf' });
                const pdfUrl = URL.createObjectURL(pdfBlob);
                window.open(pdfUrl, '_blank');
            } catch (error){errorMessage.value = `ATENÇÃO: ${error.message}`;
            } finally {isLoading.value = false;}
        }

        return { dateRange, errorMessage, idCobrancaStatus, isLoading, closeForm, openReport };
    }
}
</script>