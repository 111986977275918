<template>
    <div v-if="isLoading" class="loading-overlay"><p>Carregando...</p></div>

    <div class="form-edit-page">
        <div class="row row-space">
            <div class="form-item" style="flex: 0 0 90px;">
                <label class="label-caption">OS</label>
                <div class="field-label field-label-end">{{ formatInteger(objView.cdos) }}</div>
            </div>

            <div class="form-item-char10">
                <label class="label-caption">Inclusão</label>
                <div class="field-label field-label-center">{{ formatDateFromJson(objView.dtinclusao) }}</div>
            </div>

            <div class="form-item">
                <label class="label-caption">Nome</label>
                <div class="field-label">{{ objView.nmpessoa }}</div>
            </div>

            <div class="form-item" style="flex: 0 0 250px;">
                <label class="label-caption">Situação</label>
                <div class="field-label">{{ objView.nmsituacao }}</div>
            </div>
        </div>


        <div class="row row-space">
            <div class="form-item">
                <label class="label-caption">Grupo</label>
                <div class="field-label">{{ objView.nmgrupo }}</div>
            </div>

            <div class="form-item">
                <label class="label-caption">Equipamento</label>
                <div class="field-label">{{ objView.nmequipamento }}</div>
            </div>

            <div class="form-item">
                <label class="label-caption">Implemento</label>
                <div class="field-label">{{ objView.nmimplemento }}</div>
            </div>
        </div>
        

        <div class="row row-space">
            <div class="form-item">
                <label class="label-caption">Região</label>
                <div class="field-label">{{ objView.nmregiao }}</div>
            </div>

            <div class="form-item">
                <label class="label-caption">Localidade</label>
                <div class="field-label">{{ objView.nmlocalidade }}</div>
            </div>

            <div class="form-item">
                <label class="label-caption">Complemento/Referência</label>
                <div class="field-label">{{ objView.localidade_complemento }}</div>
            </div>
        </div>


        <div class="row row-space">
            <div class="form-item">
                <label class="label-caption">Serviço</label>
                <div class="field-label">{{ objView.nmservico }}</div>
            </div>

            <div class="form-item">
                <label class="label-caption">Descrição</label>
                <div class="field-label">{{ objView.descricao }}</div>
            </div>
        </div>

        <div class="row row-space">
            <div class="form-item" style="flex: 0 0 120px;">
                <label class="label-caption">Hr Contratadas</label>
                <div class="field-label field-label-center">{{ objView.hrcontratadas }}</div>
            </div>

            <div class="form-item" style="flex: 0 0 120px;">
                <label class="label-caption">Hr Banco</label>
                <div class="field-label field-label-center">{{ objView.hrctbanco }}</div>
            </div>

            <div class="form-item" style="flex: 0 0 120px;">
                <label class="label-caption">Hr Pagto</label>
                <div class="field-label field-label-center">{{ formatTimeFromJson(objView.hrctpagamento) }}</div>
            </div>

            <div class="form-item">
                <label class="label-caption">Vl Hora 1</label>
                <div class="field-label field-label-end">{{ formatDecimal(objView.vlhora1) }}</div>
            </div>

            <div class="form-item">
                <label class="label-caption">Vl Hora 2</label>
                <div class="field-label field-label-end">{{ formatDecimal(objView.vlhora2) }}</div>
            </div>

            <div class="form-item">
                <label class="label-caption">Vl Hora 3</label>
                <div class="field-label field-label-end">{{ formatDecimal(objView.vlhora3) }}</div>
            </div>

            <div class="form-item">
                <label class="label-caption">Vl Implemento</label>
                <div class="field-label field-label-end">{{ formatDecimal(objView.vlhr_implemento) }}</div>
            </div>

            <div class="form-item">
                <label class="label-caption">Vl Taxado</label>
                <div class="field-label field-label-end">{{ formatDecimal(objView.vlhr_taxado) }}</div>
            </div>

            <div class="form-item">
                <label class="label-caption">Vl Total</label>
                <div class="field-label field-label-end">{{ formatDecimal(objView.vlhr_total) }}</div>
            </div>
        </div>

        
        <div class="row-end">
            <button type="button" @click="imprimir">Imprimir</button>
            <button type="button" @click="closeForm">Retornar</button>
        </div>
    </div>
</template>

<script>
import { onMounted, ref } from 'vue';
import { genericPost, genericReport } from '@/funcoes/api';
import { formatDateFromJson, formatDecimal, formatInteger, formatTimeFromJson } from '@/funcoes/utils';
import { globalStore } from '@/globals';

export default{
    emits:['close'],
    props:{idView: {type: Number, default: 0}},
    setup(props, {emit}){
        const isLoading = ref(false);
        const objView = ref({});

        const closeForm = () => {emit('close');}

        const imprimir = async() => {
            try{
                isLoading.value = true;
                const dados = {
                    nmrelatorio: 'os_emissao',
                    id: objView.value.id,
                    idmunicipio: globalStore.idmunicipio,
                    idusuario: globalStore.idusuario};
                const response = await genericReport(dados);
                const pdfBlob = new Blob([response], { type: 'application/pdf' });
                const pdfUrl = URL.createObjectURL(pdfBlob);
                window.open(pdfUrl, '_blank');
            } finally {isLoading.value = false;}
        }

        const lerDados = async() => {
            try{
                isLoading.value = true;
                const req = await genericPost('/os/vwos', {idmunicipio: globalStore.idmunicipio, idos: props.idView});
                Object.assign(objView.value, req[0]);
            }catch (error){alert(error);
            }finally{isLoading.value = false;}
        }

        onMounted(()=>{lerDados();});

        return{
            isLoading, objView,
            closeForm, formatDateFromJson, formatDecimal, formatInteger, formatTimeFromJson, imprimir}
    }
}
</script>