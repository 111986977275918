<template>
    <div class="chart-container">
        <canvas ref="chartCanvas"></canvas>
    </div>
</template>

<script>
import { ref, onMounted, watch } from 'vue';
import { Chart, registerables } from 'chart.js'
Chart.register(...registerables);

export default{
    props: { data: { type: Array, default: () => [] } },
    setup(props){
        const chartCanvas = ref(null);
        let chartInstance = null;

        const createChart = () => {
            if (!props.data.length || !props.data[0]?.length) {
                return;
            }

            if (chartInstance) chartInstance.destroy();
            chartInstance = new Chart(chartCanvas.value, {
                type: 'line',
                data:{
                    labels: props.data.length ? props.data[0].map(item => item.x) : [],
                    datasets:[
                        {
                            label: 'CONTRATADAS',
                            data: props.data[0].map(item => item.y),
                            borderColor: '#8080ff',
                            fill: false,
                        },
                        {
                            label: 'TRABALHADAS',
                            data: props.data[1].map(item => item.y),
                            borderColor: '#000066',
                            fill: false,
                        },
                    ],
                },
                options: {
                    responsive: true,
                    scales: {
                        x: {
                            type: 'category',
                            title: { display: false, text: 'Mês' },
                        },
                        y: {
                            title: { display: true, text: 'Horas' },
                        },
                    },
                    plugins:{
                        legend:{
                            onClick: null,
                        },
                    },
                },
            });
        }

        onMounted(createChart);

        watch(
            () => props.data,
            createChart
        )

        return{ chartCanvas };
    }
}
</script>

<style scoped>
.chart-container{
    max-width: 600px;
    width: 100%;
    height: 300px;
    margin: 0 auto;
    border: 3px solid #3c5d5d;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
}
canvas{
    width: 100% !important;
    height: 100% !important;
}
</style>