<template>
    <div v-if="isLoading" class="loading-overlay"><p>Carregando...</p></div>


    <div v-if="!modoEdicao">
        <div class="form-edit-page" style="display: flex; gap: 10px; align-items: end;">
            <div class="form-item">
                <DateRangeSelect v-model="dateRange" />
            </div>

            <div class="form-item">
                <IpOsStatusSelect v-model:statusSelecionado="statusSelecionado" />
            </div>
            
            <button type="button" @click="lerDados">Pesquisar</button>
            <button type="button" @click="closeForm">Retornar</button>
        </div>

        <MensagemAlerta class="margin-top10" v-if="errorMessage" :mensagem="errorMessage" />
        
        <table class="margin-top10">
            <thead>
                <tr>
                    <th class="col-width-integer">Código</th>
                    <th class="col-width-date">Data</th>
                    <th>Bairro</th>
                    <th>Logradouro</th>
                    <th class="col-width-medium">Situação</th>
                    <th class="col-width-acoes-2">Ações</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(obj, index) in lsDados" :key="obj.id" :class="{'even-row': index % 2 === 0, 'odd-row': index % 2 !== 0}">
                    <td class="col-width-integer right">{{ obj.cdipos }}</td>
                    <td class="col-width-date center">{{ formatDateDMY(obj.dtipos) }}</td>
                    <td>{{ obj.nmbairro }}</td>
                    <td>{{ obj.nmlogradouro }}</td>
                    <td class="col-width-medium">{{ obj.statusnm }}</td>
                    <td class="col-width-acoes-2"><div class="row-left">
                        <button v-if="obj.status==5 || obj.status==7" class="btn-icon btn-icon--edit" @click="editarCadastro(obj)"></button>
                    </div></td>
                </tr>
            </tbody>
        </table>
    </div>


    <div v-if="modoEdicao">
        <IpOsEditItensPage :ipOsView="ipOsView" @close="modoEdicao = false" @salvo="lerDados" />
    </div>
</template>


<script>
import { ref } from 'vue';
import { formatDateFromJson } from '@/funcoes/utils';
import { genericPost } from '@/funcoes/api'; 
import { globalStore } from '@/globals';
import DateRangeSelect from '@/components/DateRangeSelect.vue';
import IpOsEditItensPage from './IpOsEditItensPage.vue';
import IpOsStatusSelect from '@/selects/IpOsStatusSelect.vue';
import MensagemAlerta from '@/components/MensagemAlerta.vue';

export default{
    emits: ['close'],
    components:{DateRangeSelect, IpOsEditItensPage, IpOsStatusSelect, MensagemAlerta},

    setup(_, { emit }){
        const dateRange = ref({ startDate: "", endDate: "", selectedPeriod: "" });
        const errorMessage = ref(null);
        const ipOsView = ref({});
        const isLoading = ref(false);
        const lsDados = ref([]);
        const modoEdicao = ref(false);
        const nmPesquisa = ref(null);
        const statusSelecionado = ref(null);

        const closeForm = () => {emit('close');}

        const editarCadastro = (obj) => {
            ipOsView.value = obj;
            modoEdicao.value = true;
        };

        const lerDados = async() => {
            if ((!dateRange.value.startDate) || (!dateRange.value.endDate)){
                errorMessage.value = 'Informe corretamente um período';
                return;
            }
            try{
                errorMessage.value = null;
                isLoading.value = true;
                const req = await genericPost('/ip/ipos', {
                    idmunicipio: globalStore.idmunicipio,
                    idfornecedor: globalStore.idipprestador,
                    datainicial: dateRange.value.startDate, 
                    datafinal:dateRange.value.endDate,
                    intparam1: statusSelecionado.value});
                lsDados.value = req;
            } catch (error){errorMessage.value=error;
            } finally {isLoading.value = false;}
        }

        return { dateRange, errorMessage, ipOsView, isLoading, lsDados, modoEdicao, nmPesquisa, statusSelecionado,
            closeForm, editarCadastro, formatDateDMY: formatDateFromJson, lerDados };
    }
}
</script>
