<template>
    <div v-if="isLoading" class="loading-overlay"><p>Carregando...</p></div>

    <div class="form-edit-page">
        <form @submit.prevent="submitForm">
            <div class="row row-space">
                <div class="form-item" style="flex: 0 0 90px;">
                    <label class="label-caption">Código</label>
                    <div class="field-label field-label-end">{{ objEdicao.cddespesagrupo }}</div>
                </div>
                
                <div class="form-item flexible">
                    <label class="label-caption">Nome</label>
                    <input type="text" v-model="objEdicao.nmdespesagrupo" required maxlength="100" v-uppercase />
                </div>
            </div>


            <div class="row-end">
                <label>Ativo</label>
                <input type="checkbox" class="input-checkbox" v-model="ckAtivo" />
            </div>

            <div class="row-end">
                <label>Somar total de combustível</label>
                <input type="checkbox" class="input-checkbox" v-model="ckSomaCombustivel" />
            </div>


            <div class="row-end margin-top10">
                <button type="submit" class="btn-confirm">Salvar</button>
                <button type="cancel" class="btn-cancel" @click="cancelForm">Cancelar</button>
            </div>
        </form>
    </div>
</template>
  
<script>
import { ref } from 'vue';
import { criarDespesaGrupo } from '@/models/DespesaGrupo';
import { genericPut, getCadastroById } from '@/funcoes/api';
import { globalStore } from '@/globals';

export default {
    name: 'DespesaGrupoEditPage',
    emits: ['close', 'salvo'],
    props: {idEdicao: {type: Number, default: 0}},
    setup(props, { emit }) {
        const ckAtivo = ref(false);
        const ckSomaCombustivel = ref(false);
        const objEdicao = criarDespesaGrupo();
        const isLoading = ref(false);

        const submitForm = async () => {
            try {
                isLoading.value              = true;
                objEdicao.ativo              = ckAtivo.value ? 'S' : 'N';
                objEdicao.cksoma_combustivel = ckSomaCombustivel.value ? 'S' : 'N';
                const response               = await genericPut('/cad/despesa_grupo', objEdicao);
                if (response.bolok === 1){
                    emit('salvo');
                    emit('close');
                }else{
                    alert(response.erro);
                }
            } catch (error) {console.error('Error:', error);
            } finally{isLoading.value=false;}
        };

        const cancelForm = () => {emit('close');};

        const lerDados = async() => {
            try{
                isLoading.value=true;
                if (props.idEdicao > 0) {
                    try{
                        const req = await getCadastroById('despesa_grupo', props.idEdicao);
                        Object.assign(objEdicao, req[0]);
                        console.log(req);
                    }catch (error){console.error('Erro leitura dos dados', error);}
                }else{
                    objEdicao.idmunicipio = globalStore.idmunicipio;
                }
                objEdicao.idusuario     = globalStore.idusuario;
                ckAtivo.value           = objEdicao.ativo === 'S';
                ckSomaCombustivel.value = objEdicao.cksoma_combustivel === 'S';
            }finally{isLoading.value=false;}
        };

        lerDados();

        return {ckAtivo, ckSomaCombustivel, isLoading, objEdicao, submitForm, cancelForm };
    }
};
</script>