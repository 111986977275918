<template>
    <div class="form-item">
        <label class="label-caption">Secretaria</label>
        <select :value="secretariaSelecionada" @change="atualizarObj" class="form-control" v-bind="$attrs">
            <option v-for="tipo in tiposCrt" :key="tipo.id" :value="tipo.id">{{ tipo.descricao }}</option>
        </select>
    </div>
</template>
  
<script>
export default {
    props: {
        secretariaSelecionada: {type: Number, default: null}
    },

    data() {
        return{
            tiposCrt:[
                {id: 1, descricao: 'AGRICULTURA'},
                {id: 2, descricao: 'OBRAS'},
                {id: 9, descricao: 'TODAS'}
            ]
        };
    },

    methods: {
        atualizarObj(event) {
            this.$emit('update:secretariaSelecionada', Number(event.target.value));
        }
    }
};
</script>