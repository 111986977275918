<template>
    <div v-if="isLoading" class="loading-overlay"><p>Carregando...</p></div>

    <MensagemAlerta class="row-space" v-if="errorMessage" :mensagem="errorMessage" />

    <div v-if="showPesquisa">
        <div class="form-edit-page row-space">
            <div class="row-start">
                <div class="form-item flexible">
                    <label class="label-caption">Pesquisa Nome</label>
                    <input type="text" v-model="nmPesquisa" maxlength="60" v-uppercase />
                </div>
                <button type="button" class="buttom-icon--search" @click="lerDados" />
                <button type="button" class="buttom-icon--close" @click="closeForm" />
            </div>
        </div>
        
        <table>
            <thead>
                <tr>
                    <th>Nome</th>
                    <th class="col-width-char40">Bairro</th>
                    <th class="col-width-char20">Fone</th>
                    <th class="col-width-integer">Qtdd OS</th>
                    <th class="col-width-number">Total OS</th>
                    <th class="col-width-number">Banco</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(obj, index) in lsDados" :key="obj.id" :class="{'even-row': index % 2 === 0, 'odd-row': index % 2 !== 0, 'clickabe-row': true}" @click="setEdicao(obj)">
                    <td>{{ obj.nmpessoa }}</td>
                    <td class="col-width-char40">{{ obj.nmbairro }}</td>
                    <td class="col-width-char20">{{ obj.fone }}</td>
                    <td class="col-width-integer right">{{ formatInteger(obj.os_qtd) }}</td>
                    <td class="col-width-number right">{{ formatDecimal(obj.os_total) }}</td>
                    <td class="col-width-number right">{{ formatDecimal(obj.ttvlsaldo_banco) }}</td>
                </tr>
            </tbody>
        </table>


        <div v-if="showPagination" class="pagination">
            <button :disabled="currentPage === 1" class="buttom-pagination--first" @click="currentPage=1; updatePaginatedData();" />
            <button :disabled="currentPage === 1" class="buttom-pagination--prior" @click="currentPage--; updatePaginatedData();" />

            <div class="field-label field-label-center">{{ currentPage }} de {{ Math.ceil(lsDadosMain.length / itemsPerPage) }}</div>

            <button :disabled="currentPage === Math.ceil(lsDadosMain.length / itemsPerPage)" class="buttom-pagination--next" @click="currentPage++; updatePaginatedData();" />
            <button :disabled="currentPage === Math.ceil(lsDadosMain.length / itemsPerPage)" class="buttom-pagination--last" @click="currentPage = Math.ceil(lsDadosMain.length / itemsPerPage); updatePaginatedData();" />
        </div>
    </div>

    <div v-if="!showPesquisa && !showCobranca">
        <div class="form-edit-page row-space">
            <div class="row row-space">
                <div class="form-item" style="flex: 0 0 90px;">
                    <label class="label-caption">Produtor</label>
                    <div class="field-label field-label-end">{{ formatInteger(objEdicao.cdpessoa) }}</div>
                </div>

                <div class="form-item flexible">
                    <label class="label-caption">Nome</label>
                    <div class="field-label">{{ objEdicao.nmpessoa }}</div>
                </div>

                <div class="form-item-char20">
                    <label class="label-caption">Fone</label>
                    <div class="field-label field-label-center">{{ objEdicao.fone }}</div>
                </div>

                <div class="form-item-char20">
                    <label class="label-caption">CPF/CNPJ</label>
                    <div :class="{
                        'field-label-alert': !objEdicao.cpfcnpj,
                        'field-label': objEdicao.cpfcnpj,
                        'field-label-center': true
                    }">{{ objEdicao.cpfcnpj }}</div>
                </div>

                <div class="form-item-char10">
                    <label class="label-caption">CEP</label>
                    <div :class="{
                        'field-label-alert': !objEdicao.cep,
                        'field-label': objEdicao.cep,
                        'field-label-center': true
                    }">{{ objEdicao.cep }}</div>
                </div>
            </div>


            <div class="row row-space">
                <div class="form-item-number">
                    <label class="label-caption">Total R$</label>
                    <div class="field-label field-label-end">{{ formatDecimal(objEdicao.os_total - objEdicao.ttvlsaldo_banco) }}</div>
                </div>

                <div class="form-item-char10">
                    <label class="label-caption">Parcelas</label>
                    <select v-model="edParcelas">
                        <option v-for="parcela in opcoesParcelas" :key="parcela" :value="parcela">{{ parcela }}</option>
                    </select>
                </div>

                <div class="form-item-data">
                    <label class="label-caption">Vencimento</label>
                    <input type="date" v-model="edVencimento" />
                </div>

                <div class="form-item-number">
                    <label class="label-caption">1ª Parcela*</label>
                    <input type="text" class="input-right" v-model="edValor" v-decimal-mask/>
                </div>

                <div class="form-item-char30">
                    <label class="label-caption">Nro Título</label>
                    <input type="text" v-model="edTitulo" maxlength="30" v-uppercase />
                </div>

                <div class="form-item">
                    <label class="label-caption">Informações</label>
                    <input type="text" v-model="edInfo" maxlength="200" v-uppercase />
                </div>
            </div>


            <div class="row-end">
                <button class="btn-confirm" @click="salvarCobranca">Salvar</button>
                <button class="btn-cancel" @click="showPesquisa=true">Cancelar</button>
            </div>
        </div>


        <table>
            <thead><tr>
                <th class="col-width-integer">OS</th>
                <th class="col-width-date">Emissão</th>
                <th class="col-width-date">Execução</th>
                <th class="col-width-char30">Grupo</th>
                <th>Serviço</th>
                <th class="col-width-char05">Horas</th>
                <th class="col-width-number">Valor</th>
                <th class="col-width-number">Banco</th>
            </tr></thead>
            <tbody>
                <tr v-for="(obj, index) in lsOs" :key="obj.id" :class="{'even-row': index % 2 === 0, 'odd-row': index % 2 !== 0}">
                    <td class="col-width-integer right">{{ obj.cdos }}</td>
                    <td class="col-width-date center">{{ formatDateFromJson(obj.dtinclusao) }}</td>
                    <td class="col-width-date center">{{ formatDateFromJson(obj.dtfim) }}</td>
                    <td class="col-width-char30">{{ obj.nmgrupo }}</td>
                    <td>{{ obj.nmservico }}</td>
                    <td class="col-width-char05 center">{{ obj.hrcontratadas }}</td>
                    <td class="col-width-number right">{{ formatDecimal(obj.vlhr_total) }}</td>
                    <td class="col-width-number right">{{ formatDecimal(obj.vlbanco) }}</td>
                </tr>
            </tbody>
        </table>
    </div>

    <div v-if="showCobranca">
        <CobrancaViewPage :idView="idCobrancaView" @close="closeForm" />
    </div>
</template>


<script>
import { onMounted, ref } from 'vue';
import { decimalFromString, formatDate, formatDateFromJson, formatDecimal, formatInteger, strDataAtual } from '@/funcoes/utils';
import { genericPost, genericPut } from '@/funcoes/api'; 
import { globalStore } from '@/globals';
import CobrancaViewPage from './CobrancaViewPage.vue';
import MensagemAlerta from '@/components/MensagemAlerta.vue';

export default{
    emits: ['close'],
    components:{ CobrancaViewPage, MensagemAlerta },

    setup(_, { emit }){
        const errorMessage = ref(null);
        const idCobrancaView = ref(null);
        const isLoading = ref(false);
        const lsDados = ref([]);
        const nmPesquisa = ref(null);
        const objEdicao = ref(null);
        const showCobranca = ref(false);
        const showPesquisa = ref(true);

        const edParcelas = ref(1);
        const opcoesParcelas = [1, 2, 3, 4, 5, 6];
        const edVencimento = ref(null);
        const edValor = ref(null);
        const edTitulo = ref(null);
        const edInfo = ref(null);

        const lsOs = ref([]);

        const currentPage = ref(1);
        const itemsPerPage = ref(globalStore.itensPorPagina);
        const lsDadosMain = ref([]);
        const showPagination = ref(false);

        const closeForm = () => {emit('close');}

        const lerDados = async() => {
            try{
                errorMessage.value  = null;
                isLoading.value     = true;
                const req = await genericPost('/financeiro/os/gerar/resumo', {
                    idmunicipio: globalStore.idmunicipio,
                    nmpesquisar: nmPesquisa.value});
                lsDadosMain.value = req;
                currentPage.value=1;
                showPagination.value = (lsDadosMain.value.length > itemsPerPage.value);
                updatePaginatedData();
            } catch (error){
                // Verifica se a API retornou um erro esperado
                if (error.response && error.response.data) {
                    errorMessage.value = error.response.data; // Pega a mensagem da API
                } else {
                    errorMessage.value = 'Erro desconhecido. Tente novamente.';
                }
            } finally {isLoading.value = false;}
        }

        const salvarCobranca = async() =>{
            if (!edVencimento.value){errorMessage.value='Informe o vencimento'; return;}
            try{
                errorMessage.value          = null;
                isLoading.value             = true;
                const retCobranca = await genericPut('/cad/cobranca', {
                    idmunicipio: globalStore.idmunicipio,
                    idpessoa: objEdicao.value.idpessoa,
                    dtcobranca: strDataAtual(),
                    idlogin: globalStore.idlogin,
                    idusuario: globalStore.idusuario
                });
                if (retCobranca.bolok===1){
                    // GRAVAR ÍTENS/OS
                    for (const os of lsOs.value){
                        const retOs = await genericPut('/cad/cobranca_item', {
                            idcobranca: retCobranca.id,
                            idbancohrmov: os.idbanco,
                            idos: os.idos,
                            vlservicos: os.vlhr_total,
                            vlhoras_debito: (os.vlbanco > 0) ? 0 : (-1 * os.vlbanco),
                            vlhoras_credito: (os.vlbanco > 0) ? os.vlbanco : 0,
                            idlogin: globalStore.idlogin,
                            idusuario: globalStore.idusuario
                        });
                        if (retOs.bolok!=1){
                            errorMessage.value=retOs.erro;
                        }
                    }

                    // GRAVAR PARCELAS
                    await genericPost('/financeiro/gerar/parcelas', {
                        id: retCobranca.id,
                        datainicial: edVencimento.value,
                        intparam1: edParcelas.value,
                        double1: decimalFromString(edValor.value),
                        strparam1: edTitulo.value,
                        strparam2: edInfo.value,
                        idlogin: globalStore.idlogin,
                        idusuario: globalStore.idusuario
                    });
                        
                    idCobrancaView.value = retCobranca.id;
                    showCobranca.value   = true;
                }else{errorMessage.value=retCobranca.erro;}
            } catch (error){errorMessage.value=error;
            } finally {isLoading.value = false;}
        }

        const setEdicao = async(obj) =>{
            try{
                errorMessage.value      = null;
                isLoading.value         = true;
                objEdicao.value         = obj;
                showPesquisa.value      = false;
                edParcelas.value        = 1;

                // LISTA ORDENS SE SERVIÇO PENDENTES
                const req               = await genericPost('/financeiro/os/gerar/listagem', {
                    idmunicipio: globalStore.idmunicipio,
                    idpessoa: objEdicao.value.idpessoa});
                lsOs.value = req;
            } catch (error){errorMessage.value=error;
            } finally {isLoading.value = false;}
        }

        const updatePaginatedData = () => {
            const startIndex = (currentPage.value - 1) * itemsPerPage.value;
            const endIndex = (startIndex + itemsPerPage.value);
            lsDados.value = lsDadosMain.value.slice(startIndex, endIndex);
        }

        onMounted(()=>{
            const dataVencimento = new Date();
            dataVencimento.setDate(dataVencimento.getDate() + globalStore.cobr_prazo_inicial);
            edVencimento.value = formatDate(dataVencimento);
            edVencimento.value = dataVencimento.toISOString().slice(0, 10);
        })

        return {
            errorMessage,
            idCobrancaView, isLoading, lsDados, nmPesquisa, 
            objEdicao,
            showCobranca, 
            showPesquisa, opcoesParcelas,
            edParcelas, edVencimento, edValor, edInfo, edTitulo, lsOs,
            currentPage, itemsPerPage, lsDadosMain, showPagination,
            closeForm, formatDateFromJson, formatDecimal, formatInteger, lerDados, salvarCobranca, setEdicao, updatePaginatedData };
    }
}
</script>
