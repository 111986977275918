<template>
    <div v-if="isLoading" class="loading-overlay"><p>Carregando...</p></div>


    <div class="form-edit-page" style="display: flex; gap: 10px; align-items: end;">
        <div class="form-item flexible">
            <label class="label-caption">Pesquisar (Nome ou Número OS)</label>
            <input type="text" v-model="termoPesquisa" maxlength="60" v-uppercase />
        </div>
        <button type="button" class="buttom-icon--search" @click="lerDados" />
        <button type="button" class="buttom-icon--close" @click="closeForm" />
    </div>

    <MensagemAlerta class="margin-top10" v-if="errorMessage" :mensagem="errorMessage" />
    
    <table class="margin-top10">
        <thead>
            <tr>
                <th class="col-width-integer">OS</th>
                <th class="col-width-date">Data</th>
                <th class="col-width-char05">Horas</th>
                <th>Nome</th>
                <th class="col-width-char20">Grupo</th>
                <th class="col-width-char30">Implemento</th>
                <th class="col-width-char30">Serviço</th>
                <th class="col-width-char20">Situação</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(obj, index) in lsDados" :key="obj.id" 
                :class="{'even-row': index % 2 === 0, 'odd-row': index % 2 !== 0, 'clickabe-row': true}" @click="selecionarOS(obj)">
                <td class="col-width-integer right">{{ obj.cdos }}</td>
                <td class="col-width-date center">{{ formatDateFromJson(obj.dtinclusao) }}</td>
                <td class="col-width-char05 center">{{ formatTimeFromJson(obj.hrcontratadas) }}</td>
                <td>{{ obj.nmpessoa }}</td>
                <td class="col-width-char20">{{ obj.nmgrupo }}</td>
                <td class="col-width-char30">{{ obj.nmimplemento }}</td>
                <td class="col-width-char30">{{ obj.nmservico }}</td>
                <td class="col-width-char20">{{ obj.nmsituacao }}</td>
            </tr>
        </tbody>
    </table>

    <div v-if="showPagination" class="pagination">
        <button :disabled="currentPage === 1" class="buttom-pagination--first" @click="currentPage=1; updatePaginatedData();" />
        <button :disabled="currentPage === 1" class="buttom-pagination--prior" @click="currentPage--; updatePaginatedData();" />
        <div class="field-label field-label-center">{{ currentPage }} de {{ Math.ceil(lsDadosMain.length / itemsPerPage) }}</div>
        <button :disabled="currentPage === Math.ceil(lsDadosMain.length / itemsPerPage)" class="buttom-pagination--next" @click="currentPage++; updatePaginatedData();" />
        <button :disabled="currentPage === Math.ceil(lsDadosMain.length / itemsPerPage)" class="buttom-pagination--last" @click="currentPage = Math.ceil(lsDadosMain.length / itemsPerPage); updatePaginatedData();" />
    </div>
</template>


<script>
import { ref } from 'vue';
import { formatDateFromJson, formatTimeFromJson } from '@/funcoes/utils';
import { genericPost } from '@/funcoes/api'; 
import { globalStore } from '@/globals';
import MensagemAlerta from '@/components/MensagemAlerta.vue';

export default{
    emits: ['cancel','select'],
    components:{MensagemAlerta},
    props: {
        idSituacao:{type: Number, default: 0},
        idSituacao2:{type: Number, default: 0},
    },

    setup(props, { emit }){
        const errorMessage = ref(null);
        const isLoading = ref(false);
        const lsDados = ref([]);
        const lsDadosMain = ref([]);
        const termoPesquisa = ref(null);
        const showPagination = ref(false);

        const currentPage = ref(1);
        const itemsPerPage = ref(globalStore.itensPorPagina);

        const closeForm = () => {emit('cancel');}

        const lerDados = async() => {
            // if (!termoPesquisa.value){
            //     errorMessage.value = 'Informe nome ou número da OS';
            //     return;
            // }
            try{
                errorMessage.value      = null;
                isLoading.value         = true;
                const termo             = termoPesquisa.value ? termoPesquisa.value.trim(): '';
                const parsedValue       = parseInt(termo, 10);
                const req               = await genericPost('/os/vwos', {
                    idmunicipio: globalStore.idmunicipio,
                    nmpesquisar: Number.isInteger(parsedValue) ? '' : termo,
                    cdcadastro: Number.isInteger(parsedValue) ? parsedValue : 0,
                    idossituacao: props.idSituacao,
                    idossituacao2: props.idSituacao2});
                lsDadosMain.value       = req;
                currentPage.value       = 1;
                showPagination.value    = (lsDadosMain.value.length > itemsPerPage.value);
                updatePaginatedData();
            } catch (error){errorMessage.value=error;
            } finally {isLoading.value = false;}
        }

        const updatePaginatedData = () => {
            const startIndex = (currentPage.value - 1) * itemsPerPage.value;
            const endIndex = (startIndex + itemsPerPage.value);
            lsDados.value = lsDadosMain.value.slice(startIndex, endIndex);
        }

        const selecionarOS = (obj) => {
            emit('select', obj);
        };

        return { 
            errorMessage, isLoading, lsDados, lsDadosMain, termoPesquisa,
            currentPage, itemsPerPage, showPagination,
            closeForm, formatDateFromJson, formatTimeFromJson, lerDados, updatePaginatedData, selecionarOS };
    }
}
</script>
