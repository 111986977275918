<template>
    <div class="content-dash">
        <div class="content-dash-cards">
            <div v-if="stats.oss_qtd>0" class="card-dash">
                <div class="row">
                    <h3>Solicitações Aguardando</h3>
                    <h2>{{ formatInteger(stats.oss_qtd) }}</h2>
                </div>
                <h2>{{ formatDecimal(stats.oss_minutos / 60) }} Horas</h2>
            </div>

            <div v-if="stats.os_executar_qtd" class="card-dash">
                <div class="row">
                    <h3>OS À Executar</h3>
                    <h2>{{ formatInteger(stats.os_executar_qtd) }}</h2>
                </div>
                <h2>{{ formatDecimal(stats.os_executar_minutos / 60) }} Horas</h2>
            </div>

            <div v-if="stats.os_autorizar_qtd" class="card-dash">
                <div class="row">
                    <h3>Autorizar Emissão</h3>
                    <h2>{{ formatInteger(stats.os_autorizar_qtd) }}</h2>
                </div>
                <h2>{{ formatDecimal(stats.os_autorizar_valor) }} R$</h2>
            </div>

            <div v-if="stats.os_emitir_qtd" class="card-dash">
                <div class="row">
                    <h3>Aguardando Emissão</h3>
                    <h2>{{ formatInteger(stats.os_emitir_qtd) }}</h2>
                </div>
                <h2>{{ formatDecimal(stats.os_emitir_valor) }} R$</h2>
            </div>

            <div v-if="stats.pc_emitir_qtd" class="card-dash">
                <div class="row">
                    <h3>Boletos À Emitir</h3>
                    <h2>{{ formatInteger(stats.pc_emitir_qtd) }}</h2>
                </div>
                <h2>{{ formatDecimal(stats.pc_emitir_valor) }} R$</h2>
            </div>
        </div>

        <div class="content-dash-chart">
            <OsContratadasTrabalhadasChart :data="chartData" />
        </div>
    </div>
</template>


<script>
import { ref, onMounted } from 'vue';
import { genericPost } from '@/funcoes/api';
import { formatDecimal, formatInteger } from '@/funcoes/utils';
import { globalStore } from '@/globals';
import OsContratadasTrabalhadasChart from '@/components/charts/OsContratadasTrabalhadasChart.vue';

export default{
    components: { OsContratadasTrabalhadasChart },
    setup(){
        const stats = ref({
            oss_qtd: 0,
            oss_minutos: 0,
            os_executar_qtd: 0,
            os_executar_minutos: 0,
            os_emitir_qtd: 0,
            os_emitir_valor: 0,
            os_autorizar_qtd: 0,
            os_autorizar_valor: 0,
            pc_emitir_qtd: 0,
            pc_emitir_valor: 0,
        });
        const chartData = ref([]);

        const loadChartData = async () => {
            const now = new Date();
            let inicio, fim;
            try{
                inicio = new Date(now.getFullYear(), now.getMonth() -11, 1);
                fim = new Date(now.getFullYear(), now.getMonth() + 1, 0);

                const hrContratadas = await genericPost('/dashboard/os/horas_contratadas', {
                    idmunicipio: globalStore.idmunicipio, 
                    datainicial: inicio.toISOString().split('T')[0], 
                    datafinal: fim.toISOString().split('T')[0]
                });

                const hrTrabalhadas = await genericPost('/dashboard/os/horas_trabalhadas', {
                    idmunicipio: globalStore.idmunicipio, 
                    datainicial: inicio.toISOString().split('T')[0], 
                    datafinal: fim.toISOString().split('T')[0]
                });

                chartData.value = [
                    hrContratadas.map(item => ({
                        x: `${item.ano}-${String(item.mes).padStart(2, '0')}`,
                        y: item.horas,})),
                    hrTrabalhadas.map(item => ({
                        x: `${item.ano}-${String(item.mes).padStart(2, '0')}`,
                        y: item.horas,})),
                ];
            }catch (error){console.error('Erro ao carregar dados do gráfico:', error);}
        };

        const loadStats = async () => {
            try{
                const response = await genericPost('/dashboard/os/operacional', {idmunicipio: globalStore.idmunicipio});
                stats.value = response[0];
            }catch(error){console.error('Erro ao carregar dados de estatísticas:', error);}
        };

        onMounted(()=>{
            loadStats();
            loadChartData();
        });

        return{
            chartData, 
            stats, 
            formatDecimal, 
            formatInteger};
    }
}
</script>


<style scoped>
.card-dash{
    background-color: #ebf2f2;
    padding: 4px;
    border-radius: 8px;
    border: 2px solid #3c5d5d;
    text-align: end;
    margin-bottom: 10px;
}

.content-dash{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
}

.content-dash-cards{
    width: 350px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.content-dash-chart{
    flex-grow: 1;
    min-width: 500px;
    display: flex;
    justify-content: center; /* Centraliza o gráfico na área */
    align-items: center;
}
</style>