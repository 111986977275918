<template>
    <div class="calendar-wrapper">
        <div class="header-container">
            <button @click="prevMonth" class="btn">&#9664; Anterior</button>
            <h2 class="month-title">{{ monthYear }}</h2>
            <button @click="nextMonth" class="btn">Próximo &#9654;</button>
        </div>
        <div class="grid-container">
            <div v-for="day in weekDays" :key="day" class="week-header">{{ day }}</div>
            <div 
                v-for="(day, index) in daysInMonth" 
                :key="index" 
                class="day-box"
                :class="{
                    'disabled-day': day.isDisabled,
                    'current-month': day.inCurrentMonth
                }">
                <span>{{ day.date.getDate() }}</span>
                <div v-if="day.data" class="text-sm">
                    <div>📋 {{ day.data.ttcontratado }}</div>
                    <div v-if="day.data.ttcontratado <= day.data.ttcapacidade">✅ {{ day.data.ttcapacidade - day.data.ttcontratado }}</div>
                    <div v-else class="text-red-500">❌ {{ day.data.ttcapacidade - day.data.ttcontratado }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, computed, defineProps } from 'vue';

const props = defineProps({
    data: Array
});

const today = new Date();
const currentMonth = ref(today.getMonth());
const currentYear = ref(today.getFullYear());

const weekDays = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'];

const daysInMonth = computed(() => {
    const firstDay = new Date(currentYear.value, currentMonth.value, 1);
    const lastDay = new Date(currentYear.value, currentMonth.value + 1, 0);
    const prevMonthDays = firstDay.getDay();
    const nextMonthDays = 6 - lastDay.getDay();
    const totalDays = lastDay.getDate();

    let days = [];
    
    for (let i = prevMonthDays; i > 0; i--) {
        const date = new Date(currentYear.value, currentMonth.value, -i + 1);
        days.push({ date, isDisabled: true, inCurrentMonth: false });
    }

    for (let i = 1; i <= totalDays; i++) {
        const date = new Date(currentYear.value, currentMonth.value, i);
        const dayInfo = props.data.find(d => new Date(d.dtagendado).toDateString() === date.toDateString());
        days.push({ 
            date, 
            data: dayInfo || null,
            isDisabled: date.getDay() === 0 || date.getDay() === 6,
            inCurrentMonth: true
        });
    }

    for (let i = 1; i <= nextMonthDays; i++) {
        const date = new Date(currentYear.value, currentMonth.value + 1, i);
        days.push({ date, isDisabled: true, inCurrentMonth: false });
    }

    return days;
});

const monthYear = computed(() => {
    return new Intl.DateTimeFormat('pt-BR', { month: 'short', year: 'numeric' })
    .format(new Date(currentYear.value, currentMonth.value))
    .toUpperCase()
    .replace('.', '') // Remove o ponto
    .replace(' DE ', '/'); // Substitui "DE" por "/"
});

const prevMonth = () => {
    if (currentMonth.value === 0) {
        currentMonth.value = 11;
        currentYear.value--;
    } else {
        currentMonth.value--;
    }
};

const nextMonth = () => {
    if (currentMonth.value === 11) {
        currentMonth.value = 0;
        currentYear.value++;
    } else {
        currentMonth.value++;
    }
};
</script>

<style scoped>
.calendar-wrapper {
    max-width: 600px;
    margin: auto;
    background: #f9f9f9;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
}

.btn {
    background: #5b8b8b;
    color: white;
    padding: 5px 10px;
    border-radius: 4px;
    cursor: pointer;
}

.grid-container {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    gap: 5px;
}

.week-header {
    font-weight: bold;
    text-align: center;
}

.day-box {
    border: 1px solid #ccc;
    min-height: 52px;
    min-width: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5px;
}

.disabled-day {
    background: #e0e0e0;
    color: #a0a0a0;
}

.current-month {
    background: #ffffff;
}

.text-red-500 {
    color: red;
}
</style>
