<template>
    <div v-if="isLoading" class="loading-overlay"><p>Carregando...</p></div>

    <div class="form-edit-page">
        <form @submit.prevent="submitForm">
            <div v-if="globalStore.idipprestador" class="row row-space">
                <div class="form-item">
                    <label class="label-caption">Prestador do Serviço</label>
                    <div class="field-label">{{ globalStore.nmipprestador }}</div>
                </div>
            </div>

            <div class="row row-space">
                <div class="form-item" style="flex: 0 0 90px;">
                    <label class="label-caption">Nro OS</label>
                    <div class="field-label field-label-end">{{ objEdicao.cdipos }}</div>
                </div>

                <div class="form-item form-item-data">
                    <label class="label-caption">Data</label>
                    <input type="date" v-model="objEdicao.dtipos" required />
                </div>

                <IpServicoSelect :ckPrestador="globalStore.idipprestador>0" class="form-item" v-model:ipservicoSelecionado="objEdicao.idipservico" required />

                <BairroSelect class="form-item" v-model:bairroSelecionado="objEdicao.idbairro" required />

                <LogradouroSelect class="form-item" v-model:logradouroSelecionado="objEdicao.idlogradouro" required />

                <div class="form-item" style="flex: 0 0 150px;">
                    <label class="label-caption">Nro Endereço</label>
                    <input type="text" class="input-right" v-model="edNmEndereco" v-number maxlength="5" />
                </div>
            </div>

            <div class="row row-space">
                <div class="form-item">
                    <label class="label-caption">Referência</label>
                    <input type="text" v-model="objEdicao.referencia" v-uppercase required maxlength="100" />
                </div>

                <div v-if="!globalStore.idipprestador" class="form-item">
                    <FornecedorSelect :descCaption= "'Prestador do Serviço'" :ckIpPrestador="true" class="form-item" v-model:fornecedorSelecionado="objEdicao.idipprestador" />
                </div>
            </div>
            

            <div class="row row-space">
                <div class="form-item">
                    <label class="label-caption">Detalhamento</label>
                    <textarea v-model="objEdicao.detalhamento" maxlength="1000" rows="5" style="resize: none;"/>
                </div>
            </div>


            <div class="row-end margin-top10">
                <button type="submit" class="btn-confirm">Salvar</button>
                <button type="cancel" class="btn-cancel" @click="cancelForm">Cancelar</button>
            </div>
        </form>
    </div>
</template>
  
<script>
import { ref } from 'vue';
import { criarIpOs } from '@/models/IpOs';
import { formatInteger, integerFromString } from '@/funcoes/utils';
import { genericPut, getCadastroById } from '@/funcoes/api';
import { globalStore } from '@/globals';
import BairroSelect from '@/selects/BairroSelect.vue';
import FornecedorSelect from '@/selects/FornecedorSelect.vue';
import IpServicoSelect from '@/selects/IpServicoSelect.vue';
import LogradouroSelect from '@/selects/LogradouroSelect.vue';

export default {
    components:{BairroSelect, FornecedorSelect, IpServicoSelect, LogradouroSelect},
    emits: ['close', 'salvo'],
    props: {idEdicao: {type: Number, default: 0}},
    setup(props, { emit }) {
        const edNmEndereco = ref(null);
        const objEdicao = criarIpOs();
        const isLoading = ref(false);

        const submitForm = async () => {
            try {
                isLoading.value = true;
                if (globalStore.idipprestador){objEdicao.idipprestador=globalStore.idipprestador;}
                objEdicao.nmendereco = integerFromString(edNmEndereco.value);
                const response  = await genericPut('/cad/ipos', objEdicao);
                if (response.bolok === 1){
                    emit('salvo');
                    emit('close');
                }else{
                    alert(response.erro);
                }
            } catch (error) {console.error('Error:', error);
            } finally{isLoading.value=false;}
        };

        const cancelForm = () => {emit('close');};

        const lerDados = async() => {
            try{
                isLoading.value=true;
                if (props.idEdicao > 0) {
                    try{
                        const req = await getCadastroById('ipos', props.idEdicao);
                        Object.assign(objEdicao, req[0]);
                    }catch (error){console.error('Erro leitura dos dados', error);}
                }else{
                    objEdicao.idmunicipio = globalStore.idmunicipio;
                }
                objEdicao.idusuario = globalStore.idusuario;
                edNmEndereco.value  = formatInteger(objEdicao.nmendereco);
            }finally{isLoading.value=false;}
        };

        lerDados();

        return {edNmEndereco, globalStore, isLoading, objEdicao, submitForm, cancelForm };
    }
};
</script>