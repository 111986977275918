<template>
    <div v-if="isLoading" class="loading-overlay"><p>Carregando...</p></div>

    <div class="form-edit-page">
        <form @submit.prevent="submitForm">
            <div class="row row-space">
                <div class="form-item-char10">
                    <label class="label-caption">Código</label>
                    <div class="field-label field-label-end">{{ formatInteger(objEdicao.id) }}</div>
                </div>
                
                <div class="form-item">
                    <label class="label-caption">Nome Município</label>
                    <div class="field-label">{{ lblNome }}</div>
                </div>

                <div class="form-item-char20">
                    <label class="label-caption">Min tolerência +</label>
                    <input type="text" class="input-right" v-model="objEdicao.mntolerancia_maior" v-number maxlength="3" />
                </div>

                <div class="form-item-char20">
                    <label class="label-caption">Min tolerência -</label>
                    <input type="text" class="input-right" v-model="objEdicao.mntolerancia_menor" v-number maxlength="3" />
                </div>

                <div class="form-item-char20">
                    <label class="label-caption">Latitude</label>
                    <input type="text" class="input-center" v-model="edLatitude" maxlength="10" />
                </div>

                <div class="form-item-char20">
                    <label class="label-caption">Longitude</label>
                    <input type="text" class="input-center" v-model="edLongitude" maxlength="10" />
                </div>
            </div>


            <div class="row row-space">
                <div class="form-item">
                    <label class="label-caption">Título dos Relatórios</label>
                    <input type="text" v-model="objEdicao.rptitulo" required maxlength="100" v-uppercase />
                </div>

                <div class="form-item">
                    <label class="label-caption">Subtítulo dos Relatórios</label>
                    <input type="text" v-model="objEdicao.rpsubtitulo" maxlength="100" v-uppercase />
                </div>

                <div class="form-item-char20">
                    <label class="label-caption">Prazo Cobrança</label>
                    <input type="text" class="input-right" v-model="objEdicao.cobr_prazo_inicial" v-number maxlength="3" />
                </div>
            </div>


            <div class="row-end">
                <label for="ckAppcliAgendaPublica">Aplicativo Cidadão: Consultar Agenda Pública</label><input id="ckAppcliAgendaPublica" type="checkbox" class="input-checkbox" v-model="ckAppcliAgendaPublica" />
            </div>
            <div class="row-end">
                <label for="ckAppcliSolicitarOs">Aplicativo Cidadão: Permitir Solicitar OS</label><input id="ckAppcliSolicitarOs" type="checkbox" class="input-checkbox" v-model="ckAppcliSolicitarOs" />
            </div>

            <div class="row-end">
                <label for="ckAguardandoExecucao">Emissão de OS: Verificar OS Aguardando Execução</label><input id="ckAguardandoExecucao" type="checkbox" class="input-checkbox" v-model="ckAguardandoExecucao" />
            </div>
            <div class="row-end">
                <label for="ckPagtoAberto">Emissão de OS: Verificar Pagamentos em Aberto</label><input id="ckPagtoAberto" type="checkbox" class="input-checkbox" v-model="ckPagtoAberto" />
            </div>
            <div class="row-end">
                <label for="ckProdutor">Emissão de OS: Verificar Produtor</label>
                <input id="ckProdutor" type="checkbox" class="input-checkbox" v-model="ckProdutor" />
            </div>
            <div class="row-end">
                <label for="ckVendas">Emissão de OS: Verificar Vendas</label>
                <input id="ckVendas" type="checkbox" class="input-checkbox" v-model="ckVendas" />
            </div>

            <div class="row-end">
                <label for="ckBaixaHodometro">OS: Baixa por Hodômetro</label><input id="ckBaixaHodometro" type="checkbox" class="input-checkbox" v-model="ckBaixaHodometro" />
            </div>
            <div class="row-end">
                <label for="ckCalculoHorimetro">OS: Cálculo por Horímetro</label><input id="ckCalculoHorimetro" type="checkbox" class="input-checkbox" v-model="ckCalculoHorimetro" />
            </div>
            <div class="row-end">
                <label for="ckOsRecalcularAposEncerramento">OS: Recalcular Após Encerramento</label>
                <input id="ckOsRecalcularAposEncerramento" type="checkbox" class="input-checkbox" v-model="ckOsRecalcularAposEncerramento" />
            </div>

            <div class="row-end">
                <label for="ckLiberaExecucaoPagtoParcial">Liberar Execução com Pagamento Parcial</label><input id="ckLiberaExecucaoPagtoParcial" type="checkbox" class="input-checkbox" v-model="ckLiberaExecucaoPagtoParcial" />
            </div>
            <div class="row-end">
                <label for="ckLiberaPagamentoEncerraOs">Liberar Pagamento no Encerramento da OS</label><input id="ckLiberaPagamentoEncerraOs" type="checkbox" class="input-checkbox" v-model="ckLiberaPagamentoEncerraOs" />
            </div>
            <div class="row-end">
                <label for="ckLiberaPagamentoInclusao">Liberar Pagamento na Inclusão da OS</label><input id="ckLiberaPagamentoInclusao" type="checkbox" class="input-checkbox" v-model="ckLiberaPagamentoInclusao" />
            </div>
            <div class="row-end">
                <label for="ckOperadorOssmall">Permitir Operador Gerar OS Small</label><input id="ckOperadorOssmall" type="checkbox" class="input-checkbox" v-model="ckOperadorOssmall" />
            </div>
            <div class="row-end">
                <label for="ckPermitirParcelar">Permitir Parcelamento</label><input id="ckPermitirParcelar" type="checkbox" class="input-checkbox" v-model="ckPermitirParcelar" />
            </div>

            <div class="row-end margin-top10">
                <button class="btn-confirm" type="submit">Salvar</button>
                <button class="btn-cancel" type="cancel" @click="cancelForm">Cancelar</button>
            </div>
        </form>
    </div>
</template>
  
<script>
import { onMounted, ref } from 'vue';
import { formatInteger } from '@/funcoes/utils';
import { criarMunicipioConfig } from '@/models/MunicipioConfig';
import { genericPut, getCadastroById } from '@/funcoes/api';
import { globalStore } from '@/globals';

export default {
    emits: ['close', 'salvo'],
    setup(_, { emit }) {
        const ckAppcliAgendaPublica = ref(false);
        const ckAppcliSolicitarOs = ref(false);
        const ckAguardandoExecucao = ref(false);
        const ckBaixaHodometro = ref(false);
        const ckCalculoHorimetro = ref(false);
        const ckLiberaExecucaoPagtoParcial = ref(false);
        const ckLiberaPagamentoEncerraOs = ref(false);
        const ckLiberaPagamentoInclusao = ref(false);
        const ckOperadorOssmall = ref(false);
        const ckPagtoAberto = ref(false);
        const ckPermitirParcelar = ref(false);
        const ckProdutor = ref(false);
        const ckVendas = ref(false);
        const ckOsRecalcularAposEncerramento = ref(false);
        
        const edLatitude = ref(0);
        const edLongitude = ref(0);
        const idTpPagamento = ref(null);


        const lblNome = ref(null);
        const objEdicao = criarMunicipioConfig();
        const isLoading = ref(false);

        const submitForm = async () => {
            try {
                isLoading.value                             = true;
                objEdicao.appcli_agenda_publica             = ckAppcliAgendaPublica.value ? 1 : 0;
                objEdicao.appcli_solicitar_os               = ckAppcliSolicitarOs.value ? 1 : 0;
                objEdicao.ckaguardandoexecucao              = ckAguardandoExecucao.value ? 'S' : 'N';
                objEdicao.ckbaixa_hodometro                 = ckBaixaHodometro.value ? 'S' : 'N';
                objEdicao.ckcalculo_horimetro               = ckCalculoHorimetro.value ? 'S' : 'N';
                objEdicao.cklibera_execucao_pagto_parcial   = ckLiberaExecucaoPagtoParcial.value ? 'S' : 'N';
                objEdicao.cklibera_pagamento_encerra_os     = ckLiberaPagamentoEncerraOs.value ? 'S' : 'N';
                objEdicao.cklibera_pagamento_inclusao       = ckLiberaPagamentoInclusao.value ? 'S' : 'N';
                objEdicao.ckoperador_ossmall                = ckOperadorOssmall.value ? 'S' : 'N';
                objEdicao.ckpagtoaberto                     = ckPagtoAberto.value ? 'S' : 'N';
                objEdicao.ckpermitir_parcelar               = ckPermitirParcelar.value ? 'S' : 'N';
                objEdicao.ckprodutor                        = ckProdutor.value ? 'S' : 'N';
                objEdicao.ckvendas                          = ckVendas.value ? 'S' : 'N';
                objEdicao.osrecalcular_apos_encerramento    = ckOsRecalcularAposEncerramento.value ? 'S' : 'N';
                objEdicao.tppagamento                       = idTpPagamento.value;
                objEdicao.latitude                          = !edLatitude.value ? 0 : parseFloat(edLatitude.value.toString().replace(',', '.')) || 0;
                objEdicao.longitude                         = !edLongitude.value ? 0 : parseFloat(edLongitude.value.toString().replace(',', '.')) || 0;

                const response = await genericPut('/cad/municipio_config', objEdicao);
                if (response.bolok === 1){
                    emit('salvo');
                    emit('close');
                }else{
                    alert(response.erro);
                }
            } catch (error) {console.error('Error:', error);
            } finally{isLoading.value=false;}
        };

        const cancelForm = () => {emit('close');};

        const lerDados = async() => {
            try{
                isLoading.value=true;
                try{
                    const req = await getCadastroById('municipio_config', globalStore.idmunicipio);
                    Object.assign(objEdicao, req[0]);
                }catch (error){console.error('Erro leitura dos dados', error);}

                objEdicao.idusuario                     = globalStore.idusuario;
                ckAppcliAgendaPublica.value             = objEdicao.appcli_agenda_publica === 1;
                ckAppcliSolicitarOs.value               = objEdicao.appcli_solicitar_os === 1;
                ckAguardandoExecucao.value              = objEdicao.ckaguardandoexecucao ==='S';
                ckBaixaHodometro.value                  = objEdicao.ckbaixa_hodometro === 'S';
                ckCalculoHorimetro.value                = objEdicao.ckcalculo_horimetro === 'S';
                ckLiberaExecucaoPagtoParcial.value      = objEdicao.cklibera_execucao_pagto_parcial === 'S';
                ckLiberaPagamentoEncerraOs.value        = objEdicao.cklibera_pagamento_encerra_os === 'S';
                ckLiberaPagamentoInclusao.value         = objEdicao.cklibera_pagamento_inclusao === 'S';
                ckOperadorOssmall.value                 = objEdicao.ckoperador_ossmall === 'S';
                ckPagtoAberto.value                     = objEdicao.ckpagtoaberto === 'S';
                ckPermitirParcelar.value                = objEdicao.ckpermitir_parcelar === 'S';
                ckProdutor.value                        = objEdicao.ckprodutor === 'S';
                ckVendas.value                          = objEdicao.ckvendas === 'S';
                ckOsRecalcularAposEncerramento.value    = objEdicao.osrecalcular_apos_encerramento === 'S';
                idTpPagamento.value                     = objEdicao.tppagamento;
                edLatitude.value                        = objEdicao.latitude;
                edLongitude.value                       = objEdicao.longitude;

                lblNome.value                           = globalStore.nmmunicipio;
            }finally{isLoading.value=false;}
        };

        onMounted(lerDados);

        return {
            ckAppcliAgendaPublica,
            ckAppcliSolicitarOs, 
            ckAguardandoExecucao, 
            ckBaixaHodometro,
            ckCalculoHorimetro,
            ckLiberaExecucaoPagtoParcial,
            ckLiberaPagamentoEncerraOs,
            ckLiberaPagamentoInclusao,
            ckOperadorOssmall, 
            ckPagtoAberto,
            ckPermitirParcelar,
            ckProdutor,
            ckVendas,
            ckOsRecalcularAposEncerramento,
            edLatitude, 
            edLongitude, 
            idTpPagamento,
            isLoading, 
            lblNome,
            objEdicao, 
            formatInteger,
            submitForm, 
            cancelForm };
    }
};
</script>