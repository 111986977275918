import { reactive } from 'vue';

export const criarOs = () => {
    return reactive({
        id: 0,
        idmunicipio: 0,
        cdos: 0,
        idequipamento_grupo: 0,
        idequipamento: 0,
        idimplemento: 0,
        idservico: 0,
        idossituacao: 0,
        idpessoa: 0,
        idlocalidade: 0,
        cpfcnpj: '',
        localidade_complemento: '',
        hectares: 0,
        dtinclusao: '',
        tpdigitacao: 0,
        xtpdigitacao: '',
        dtautorizacao: '',
        dtinicioprevisto: '',
        hrinicioprevisto: '',
        hrcontratadas: '',
        mncontratados: 0,
        hrctbanco: '',
        mnctbanco: 0,
        hrctpagamento: '',
        mnctpagamento: 0,
        vlhora1: 0,
        vlhora2: 0,
        vlhora3: 0,
        mnfaixa2: 0,
        mnfaixa3: 0,
        vlhr_equipamento: 0,
        vlhr_implemento: 0,
        vlhr_taxado: 0,
        vlhr_unitario: 0,
        vlhr_segmento: 0,
        vlhr_total: 0,
        descricao: '',
        latitude: 0,
        longitude: 0,
        dhcancelamento: '',
        iducancelamento: 0,
        obscancelamento: '',
        mnbanco_cancelamento: 0,
        mnpago_cancelamento: 0,
        dtinicio: '',
        hrinicio: '',
        dtfim: '',
        hrfim: '',
        hrtrabalhadas: '',
        mntrabalhados: 0,
        mntolerancia: 0,
        mnsobrabanco: 0,
        mnsobraestorno: 0,
        idusobraestorno: 0,
        obssobraestorno: '',
        dhsobraestorno: '',
        mnsobralancado: 0,
        mnsobraaguardando: 0,
        obs: '',
        possuiequipamento: 0,
        agendado: 0,
        ordem: 0,
        ordemprioridade: 0,
        chuva: 0,
        reagendamento: 0,
        reagendamentodata: '',
        reagendamentoidusuario: 0,
        reagendamentomotivo: '',
        aguardando_execucao: 0,
        idcobrancaitem: 0,
        idlogin: 0,
        idusuario: 0
    });
};