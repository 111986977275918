<template>
    <div v-if="isLoading" class="loading-overlay"><p>Carregando...</p></div>

    <div v-if="!modoEdicao">
        <div class="form-edit-page" style="display: flex; gap: 10px; align-items: end;">
            <div class="form-item flexible">
                <label class="label-caption" for="nmPesquisa">Nome Pesquisa</label>
                <input type="text" v-model="nmPesquisa" id="nmPesquisa" maxlength="80" v-uppercase />
            </div>
            <button type="button" class="buttom-icon--search" @click="lerDados" />
            <button type="button" class="buttom-icon--add" @click="novoCadastro" />
            <button type="button" class="buttom-icon--home" @click="closePage" />
        </div>

        <div class="margin-top10">
            <table>
                <thead>
                    <tr>
                        <th class="col-width-integer">Código</th>
                        <th>Nome</th>
                        <th class="col-width-doc">CPF/CNPJ</th>
                        <th class="col-width-doc">Fone</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(obj, index) in lsDados" :key="obj.id" :class="{'even-row': index % 2 === 0, 'odd-row': index % 2 !== 0, 'clickabe-row': true}" @click="editarCadastro(obj)">
                        <td class="col-width-integer right">{{ obj.cdfornecedor }}</td>
                        <td>{{ obj.nmfornecedor }}</td>
                        <td class="col-width-doc center">{{ obj.cpfcnpj }}</td>
                        <td class="col-width-doc">{{ obj.fone }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <div v-if="modoEdicao">
        <FornecedorEditPage :idEdicao="idEdicao" @close="modoEdicao = false" @salvo="lerDados" />
    </div>
</template>


<script>
import { ref } from 'vue';
import { genericPost } from '@/funcoes/api'; 
import { globalStore } from '@/globals';
import FornecedorEditPage from '../formularios/FornecedorEditPage.vue';

export default{
    components: { FornecedorEditPage },
    emits:['close'],
    setup(_, { emit }){
        const idEdicao = ref(0);
        const isLoading = ref(false);
        const lsDados = ref([]);
        const modoEdicao = ref(false);
        const nmPesquisa = ref('');

        const closePage = () =>{emit('close');};

        const editarCadastro = (obj) => {
            idEdicao.value = obj.id;
            modoEdicao.value = true;
        };

        const lerDados = async() => {
            try{
                isLoading.value = true;
                const req = await genericPost('/listas/fornecedores', {idmunicipio: globalStore.idmunicipio, nmpesquisar: nmPesquisa.value});
                lsDados.value = req;
            } catch (error){console.error('Erro leitura dos dados', error);
            } finally {isLoading.value = false;}
        }

        const novoCadastro = () => {
            idEdicao.value = 0;
            modoEdicao.value = true;
        }

        return { idEdicao, isLoading, lsDados, modoEdicao, nmPesquisa, closePage, editarCadastro, lerDados, novoCadastro };
    }
}
</script>
