import { reactive } from 'vue';

export const criarLocalidade = () => {
    return reactive({
        id: 0,
        idmunicipio: 0,
        cdlocalidade: 0,
        idregiao: 0,
        nmlocalidade: '',
        ativo: 'S',
        latitude: 0,
        longitude: 0,
        idusuario: 0
    });
};