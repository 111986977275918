<template>
    <div v-if="isLoading" class="loading-overlay"><p>Carregando...</p></div>
    <MensagemAlerta v-if="errorMessage" :mensagem="errorMessage" />

    <div class="form-edit-page row-space">
        <div class="row row-space">
            <div class="form-item-char20">
                <label class="label-caption">Tipo</label>
                <select v-model="opcaoSelecionada">
                    <option v-for="opcao in lsOpcoes" :key="opcao.id" :value="opcao.id">{{ opcao.descricao }}</option>
                </select>
            </div>
            <EquipamentoGrupoSelect :ckAddTodos="true" v-model:grupoSelecionado="idGrupo" />
            <ServicoSelect :ckAddTodos="true" v-model:servicoSelecionado="idServico" />
            
            <button type="button" class="buttom-icon--map" @click="lerDados" />
            <button type="button" class="buttom-icon--close" @click="closeForm" />
        </div>
    </div>

    <div v-if="lsDados">
        <MapArrayLegendComponent class="map" :coordinates="lsDados"></MapArrayLegendComponent>
    </div>


    <!-- <div v-if="lsDados" class="form-edit-page">
        <div v-if="lsDados">
            <MapArrayLegendComponent class="map" :coordinates="lsDados"></MapArrayLegendComponent>
        </div>
    </div> -->
</template>

<script>
import { ref } from 'vue';
import { genericPost } from '@/funcoes/api';
import { globalStore } from '@/globals';
import EquipamentoGrupoSelect from '@/selects/EquipamentoGrupoSelect.vue';
import MapArrayLegendComponent from '@/components/MapArrayLegendComponent.vue';
import MensagemAlerta from '@/components/MensagemAlerta.vue';
import ServicoSelect from '@/selects/ServicoSelect.vue';

export default{
    components: { EquipamentoGrupoSelect, MapArrayLegendComponent, MensagemAlerta, ServicoSelect },
    emits: ['close'],
    setup(_, {emit}){
        const errorMessage = ref(null);
        const idGrupo = ref(null);
        const idServico = ref(null);
        const isLoading = ref(false);
        const lsDados = ref(null);

        const lsOpcoes = ref([
            {id: 'localidades', descricao: 'Localidade'},
            {id: 'regioes', descricao: 'Região'},
        ]);
        const opcaoSelecionada = ref('regioes');

        const closeForm = () => {emit('close');}

        const lerDados = async() => {
            try{
                errorMessage.value = null;
                isLoading.value = true;
                const r = await genericPost(`/os/mapa/${opcaoSelecionada.value}`, {
                    idmunicipio: globalStore.idmunicipio,
                    idequipamentogrupo: idGrupo.value,
                    idservico: idServico.value});
                lsDados.value = r;
            } catch (error){errorMessage.value = `ATENÇÃO: ${error.message}`;
            } finally {isLoading.value = false;}
        }

        return { 
            errorMessage, idGrupo, idServico, isLoading, lsOpcoes, opcaoSelecionada,
            lsDados,
            closeForm, lerDados };
    }
}
</script>