import { reactive } from 'vue';

export const criarEquipamento = () => {
    return reactive({
        id: 0,
        idmunicipio: 0,
        cdequipamento: 0,
        idgrupo: 0,
        idregiao: 0,
        idcombustivel: 0,
        idequipamentosetor: 0,
        nmequipamento: '',
        tipo: 0,
        xtipo: '',
        tipoagendamento: 0,
        xtipoagendamento: '',
        nropatrimonio: '',
        modelo: '',
        medida: '',
        situacao: '',
        horasdia: '',
        mnhorasdia: 0,
        alufm: 0,
        vlhora: 0,
        vlhora_taxado: 0,
        mnfaixa2: 0,
        vlhora2: 0,
        mnfaixa3: 0,
        vlhora3: 0,
        ano: 0,
        ativo: 'S',
        blterceirizado: '',
        bltroca_operador: '',
        cor: '',
        dtaquisicao: '',
        marca: '',
        nrochassi: '',
        nrorenavan: '',
        placa: '',
        ckagenda_livre: '',
        cdsecretaria: 0,
        nmsecretaria: '',
        tpmedicao: '0',
        nmtpmedicao: '',
        obs: '',
        idusuario: 0
    });
};