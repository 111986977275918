import { reactive } from 'vue';

export const criarOperador = () => {
    return reactive({
        id: 0,
        idmunicipio: 0,
        cdoperador: 0,
        idequipamento: 0,
        nmoperador: '',
        apelido_operador: '',
        fone: '',
        email: '',
        cpf: '',
        cpfnros: '',
        login: '',
        senha: '',
        dtsenha: '',
        ativo: 'S',
        blterceirizado: 'N',
        bltroca_equipamento: 'N',
        ckabreos: 'N',
        idusuario: 0
    });
};