<template>
    <div v-if="isLoading" class="loading-overlay"><p>Carregando...</p></div>

    <MensagemAlerta class="row-space" v-if="errorMessage" :mensagem="errorMessage" />

    <div class="form-edit-page row-space">
        <div class="row row-space">
            <div class="form-item-codigo">
                <label class="label-caption">Cobrança</label>
                <div class="field-label field-label-end">{{ formatInteger(objView.cdcobranca) }}</div>
            </div>

            <div class="form-item-codigo">
                <label class="label-caption">Parcela</label>
                <div class="field-label field-label-center">{{ formatInteger(objView.parcela) }}</div>
            </div>

            <div class="form-item-data">
                <label class="label-caption">Vencimento</label>
                <div class="field-label field-label-center">{{ formatDateFromJson(objView.dtvencimento) }}</div>
            </div>

            <div class="form-item-data">
                <label class="label-caption">Pagamento</label>
                <div class="field-label field-label-center">{{ formatDateFromJson(objView.dtpagamento) }}</div>
            </div>

            <div class="form-item-char20">
                <label class="label-caption">Nro Título</label>
                <div class="field-label field-label-center">{{ objView.nrotitulo }}</div>
            </div>

            <div class="form-item">
                <label class="label-caption">Informações emissão</label>
                <div class="field-label">{{ objView.infoemissao }}</div>
            </div>
        </div>


        <div class="row row-space">
            <div class="form-item ">
                <label class="label-caption">Nome</label>
                <div class="field-label">{{ objView.nmpessoa }}</div>
            </div>
        </div>


        <div class="row row-space">
            <div class="form-item-number">
                <label class="label-caption">Valor</label>
                <div class="field-label field-label-end">{{ formatDecimal(objView.vlparcela) }}</div>
            </div>

            <div class="form-item-number">
                <label class="label-caption">Recebido</label>
                <div class="field-label field-label-end">{{ formatDecimal(objView.vlrecebido) }}</div>
            </div>

            <div class="form-item-number">
                <label class="label-caption">Juros</label>
                <div class="field-label field-label-end">{{ formatDecimal(objView.vljuros) }}</div>
            </div>

            <div class="form-item-number">
                <label class="label-caption">Descontado</label>
                <div class="field-label field-label-end">{{ formatDecimal(objView.vldescontado) }}</div>
            </div>

            <div class="form-item-number">
                <label class="label-caption">À Receber</label>
                <div class="field-label field-label-end">{{ formatDecimal(objView.vlareceber) }}</div>
            </div>

            <div class="form-item">
                <label class="label-caption">Situação</label>
                <div class="field-label">{{ objView.nmsituacao }}</div>
            </div>
        </div>


        <div v-if="!showInfoEmissao && !showInfoBaixa" class="row-end">
            <button v-if="objView.idboleto && objView.idsituacao!=1" @click="emitirBoleto">Imprimir Boleto</button>
            <button v-if="objView.idsituacao==1" @click="gerarBoleto">Gerar Boleto</button>
            <button v-if="objView.idsituacao==1" @click="showInfoEmissao=true">Informar Emissão</button>
            <button v-if="objView.idsituacao==3" @click="showInfoBaixa=true">Informar Pagamento</button>
            <button type="button" @click="closeForm">Retornar</button>
        </div>
    </div>


    <div v-if="showInfoBaixa" class="form-edit-page">
        <div class="row row-space">
            <div class="form-item-data">
                <label class="label-caption">Pagamento</label>
                <input type="date" v-model="edDtBaixa" />
            </div>

            <div class="form-item-number">
                <label class="label-caption">Vl Recebido</label>
                <input type="text" class="input-right" v-model="edVlRecebido" v-decimal-mask/>
            </div>

            <div class="form-item-number">
                <label class="label-caption">Vl Juros</label>
                <input type="text" class="input-right" v-model="edVlJuros" v-decimal-mask/>
            </div>

            <div class="form-item-number">
                <label class="label-caption">Vl Desconto</label>
                <input type="text" class="input-right" v-model="edVlDescontado" v-decimal-mask/>
            </div>
            
            <div class="form-item">
                <label class="label-caption">Informações</label>
                <input type="text" v-model="edInfoBaixa" maxlength="200" v-uppercase />
            </div>
        </div>
        <div class="row-end">
            <button class="btn-confirm" @click="salvarBaixa()">Confirmar</button>
            <button class="btn-cancel" @click="showInfoBaixa=false">Cancelar</button>
        </div>
    </div>


    <div v-if="showInfoEmissao" class="form-edit-page">
        <div class="row row-space">
            <div class="form-item-char30">
                <label class="label-caption">Nro Título</label>
                <input type="text" v-model="edNroTitulo" maxlength="30" v-uppercase />
            </div>

            <div class="form-item">
                <label class="label-caption">Informações</label>
                <input type="text" v-model="edInfoEmissao" maxlength="200" v-uppercase />
            </div>
        </div>
        <div class="row-end">
            <button class="btn-confirm" @click="salvarEmissao()">Confirmar</button>
            <button class="btn-cancel" @click="showInfoEmissao=false">Cancelar</button>
        </div>
    </div>
</template>

<script>
import { onMounted, ref } from 'vue';
import { decimalFromString, formatDateFromJson, formatDecimal, formatInteger } from '@/funcoes/utils';
import { genericPost, genericPut, getBoletoPdf } from '@/funcoes/api';
import { globalStore } from '@/globals';
import MensagemAlerta from '@/components/MensagemAlerta.vue';

export default{
    components:{ MensagemAlerta },
    emits:['close','save'],
    props:{idView: {type: Number, default: 0}},
    setup(props, {emit}){
        const emitSave= ref(false);
        const errorMessage = ref(null);
        const isLoading = ref(false);
        const objView = ref({});
        const showInfoBaixa = ref(false);
        const showInfoEmissao = ref(false);

        const edDtBaixa = ref(null);
        const edVlRecebido = ref(null);
        const edVlJuros = ref(null);
        const edVlDescontado = ref(null);
        const edInfoBaixa= ref(null);

        const edNroTitulo= ref(null);
        const edInfoEmissao= ref(null);

        const closeForm = () => {
            emit('close'); 
            if (emitSave.value){emit('save')}
        }

        const emitirBoleto = async() => {
            try{
                isLoading.value=true;
                errorMessage.value=null;
                const response = await getBoletoPdf(objView.value.idboleto);
                const pdfBlob = new Blob([response], { type: 'application/pdf' });
                const pdfUrl = URL.createObjectURL(pdfBlob);
                window.open(pdfUrl, '_blank');
            }catch (error){errorMessage.value = `ATENÇÃO: ${error.message}`;
            }finally{isLoading.value=false;}
        }

        const gerarBoleto = async() =>{
            try{
                isLoading.value=true;
                errorMessage.value=null;
                const r = await genericPost('/cobranca_api/incluir', {
                    idmunicipio: globalStore.idmunicipio,
                    id: objView.value.id, 
                    idlogin: globalStore.idlogin,
                    idusuario: globalStore.idusuario});
                if (r.bolok==1){
                    await lerDados();
                    emitSave.value=true;
                    await emitirBoleto();
                }else{
                    errorMessage.value=r.erro;
                }
            }catch (error){errorMessage.value=error;
            }finally{isLoading.value=false;}

        }

        const lerDados = async() => {
            try{
                isLoading.value = true;
                const req = await genericPost('/financeiro/lista/parcelas', {idmunicipio: globalStore.idmunicipio, id: props.idView});
                Object.assign(objView.value, req[0]);
            }catch (error){alert(error);
            }finally{isLoading.value = false;}
        }

        const salvarBaixa = async()=>{
            if (!edDtBaixa.value){errorMessage.value='Informe data do pagamento'; return}
            if ((decimalFromString(edVlRecebido.value) + decimalFromString(edVlDescontado.value)) != objView.value.vlparcela){
                errorMessage.value='A soma do valor recebido com o valor do desconto deve ser igual ao valor da parcela'; return}
            try{
                isLoading.value=true;
                errorMessage.value=null;
                const r = await genericPut('/cad/cobranca_parc', {
                    id: objView.value.id, 
                    lgbaixa: globalStore.idlogin, 
                    dtpagamento: edDtBaixa.value,
                    vlrecebido: decimalFromString(edVlRecebido.value),
                    vljuros: decimalFromString(edVlJuros.value),
                    vldescontado: decimalFromString(edVlDescontado.value),
                    infobaixa: edInfoBaixa.value,
                    idusuario: globalStore.idusuario});
                if (r.bolok==1){
                    lerDados();
                    emitSave.value=true;
                    showInfoBaixa.value=false;
                }
            }finally{isLoading.value=false;}
        }

        const salvarEmissao = async()=>{
            if (!edNroTitulo.value){errorMessage.value='Informe o número do título'; return}
            try{
                isLoading.value=true;
                errorMessage.value=null;
                const r = await genericPut('/cad/cobranca_parc', {
                    id: objView.value.id, 
                    lgemissao: globalStore.idlogin, 
                    nrotitulo:  edNroTitulo.value,
                    infoemissao: edInfoEmissao.value,
                    idusuario: globalStore.idusuario});
                if (r.bolok==1){
                    lerDados();
                    emitSave.value=true;
                    showInfoEmissao.value=false;
                }
            }finally{isLoading.value=false;}
        }

        onMounted(() => {lerDados()});

        return{
            errorMessage, isLoading, objView, showInfoBaixa, showInfoEmissao,
            edDtBaixa, edVlRecebido, edVlJuros, edVlDescontado, edInfoBaixa,
            edNroTitulo, edInfoEmissao,

            closeForm, 
            emitirBoleto,
            formatDateFromJson, 
            formatDecimal,
            gerarBoleto, 
            formatInteger, 
            salvarBaixa, 
            salvarEmissao}
    }
}
</script>