import { defineStore } from "pinia";
import { criarLogin } from "@/models/Login";

export const useLoginStore = defineStore('login', {
    state: () => ({
        userData: criarLogin(),
    }),
    actions: {
        setLogin(data){
            Object.assign(this.userData, data);
        },
        clearLogin(){
            Object.keys(this.userData).forEach(key => {
                this.userData[key] = ''; // Reseta os valores para strings vazias
            });
        },
    },
    getters:{
        isAuthenticated: (state) => !!state.userData.token,
        userName: (state) => state.userData.nome,
        userMunicipio: (state) => state.userData.nmmunicipio,
    },
});