export const globalStore = {
    idlogin: null,
    idusuario: null,
    idmunicipio: null,
    idipprestador: null,
    ckadmin: 'N',
    ckipprestador: 'N',
    nmusuario: '',
    nmmunicipio: '',
    nmipprestador: '',

    ckpermitir_parcelar: 'N',
    cobr_prazo_inicial: 0,
    latitude: 0,
    longitude: 0,

    itensPorPagina: 12
};