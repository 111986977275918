import { reactive } from 'vue';

export const criarBairro = () => {
    return reactive({
        id: 0,
        idmunicipio: 0,
        cdbairro: 0,
        nmbairro: '',
        ativo: 'S',
        idusuario: 0
    });
};