import { reactive } from 'vue';

export const criarRegiao = () => {
    return reactive({
        id: 0,
        idmunicipio: 0,
        cdregiao: 0,
        nmregiao: '',
        ativo: 'S',
        latitude: 0,
        longitude: 0,
        idusuario: 0
    });
};