<template>
    <router-view />
</template>

<script>
export default {
    name: 'App'
};
</script>
  

<style>
*{
    margin: 0;
    padding: 0;
}
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    font-size: small;
    text-align: center;
    margin: 0;
    padding: 0;
}
</style>
<style src="@/assets/css/layout.css"></style>
<style src="@/assets/css/main.css"></style>
