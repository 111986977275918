<template>
    <div v-if="isLoading" class="loading-overlay"><p>Carregando...</p></div>

    <div class="form-edit-page">
        <form @submit.prevent="submitForm">
            <div class="row row-space">
                <div class="form-item" style="flex: 0 0 90px;">
                    <label class="label-caption">Código</label>
                    <div class="field-label field-label-end">{{ objEdicao.cdservico }}</div>
                </div>
                
                <div class="form-item flexible">
                    <label class="label-caption">Nome</label>
                    <input type="text" v-model="objEdicao.nmservico" required maxlength="100" v-uppercase />
                </div>

                <TipoGrupoServico class="form-item flexible" v-model:tipoSelecionado="objEdicao.idgruposervico" />

                <div class="form-item" style="flex: 0 0 150px;">
                    <label class="label-caption">Limite diário</label>
                    <input type="text" class="input-right" v-model="edAgendamentoLimiteDia" v-number maxlength="3" />
                </div>

                <div class="form-item" style="flex: 0 0 150px;">
                    <label class="label-caption">Minutos/Hectar</label>
                    <input type="text" class="input-right" v-model="edMnHectare" v-number maxlength="3" />
                </div>
            </div>


            <div class="row-end">
                <label>Ativo</label>
                <input type="checkbox" class="input-checkbox" v-model="ckAtivo" />
            </div>

            <div class="row-end">
                <label>Agendamento por data</label>
                <input type="checkbox" class="input-checkbox" v-model="ckAgendamento" />
            </div>

            <div class="row-end">
                <label>Cálculo de valor fracionado</label>
                <input type="checkbox" class="input-checkbox" v-model="ckCalculoFracionado" />
            </div>

            <div class="row-end">
                <label>Exportar para aplicativo do operador</label>
                <input type="checkbox" class="input-checkbox" v-model="ckExportaOperador" />
            </div>


            <div class="row-end margin-top10">
                <button class="btn-confirm" type="submit">Salvar</button>
                <button class="btn-cancel" type="cancel" @click="cancelForm">Cancelar</button>
            </div>
        </form>
    </div>
</template>
  
<script>
import { ref } from 'vue';
import { criarServico } from '@/models/Servico';
import { formatInteger, integerFromString } from '@/funcoes/utils';
import { genericPut, getCadastroById } from '@/funcoes/api';
import { globalStore } from '@/globals';
import TipoGrupoServico from '@/selects/TipoGrupoServico.vue';

export default {
    name: 'ServicoEditPage',
    components: { TipoGrupoServico },
    emits: ['close', 'salvo'],
    props: {idEdicao: {type: Number, default: 0}},
    setup(props, { emit }) {
        const ckAgendamento = ref(false);
        const ckAtivo = ref(false);
        const ckCalculoFracionado = ref(false);
        const ckExportaOperador = ref(false);
        const edAgendamentoLimiteDia = ref(null);
        const edMnHectare = ref(null);
        const objEdicao = criarServico();
        const isLoading = ref(false);

        const submitForm = async () => {
            try {
                objEdicao.agendamento           = ckAgendamento.value ? 'S' : 'N';
                objEdicao.ativo                 = ckAtivo.value ? 'S' : 'N';
                objEdicao.ckcalculofracionado   = ckCalculoFracionado.value ? 'S' : 'N';
                objEdicao.ckexportaoperador     = ckExportaOperador.value ? 'S' : 'N';
                objEdicao.agendamento_limitedia = integerFromString(edAgendamentoLimiteDia.value);
                objEdicao.mnhectare             = integerFromString(edMnHectare.value);

                const response = await genericPut('/cad/servico', objEdicao);
                if (response.bolok === 1){
                    emit('salvo');
                    emit('close');
                }else{
                    alert(response.erro);
                }
            } catch (error) {console.error('Error:', error);}
        };

        const cancelForm = () => {emit('close');};

        const lerDados = async() => {
            try{
                isLoading.value=true;
                if (props.idEdicao > 0) {
                    try{
                        const req = await getCadastroById('servico', props.idEdicao);
                        Object.assign(objEdicao, req[0]);
                        edAgendamentoLimiteDia.value = formatInteger(objEdicao.agendamento_limitedia);
                        edMnHectare.value            = formatInteger(objEdicao.mnhectare);
                    }catch (error){console.error('Erro leitura dos dados', error);}
                }else{
                    objEdicao.idmunicipio = globalStore.idmunicipio;
                }
                objEdicao.idusuario       = globalStore.idusuario;
                ckAgendamento.value       = objEdicao.agendamento === 'S';
                ckAtivo.value             = objEdicao.ativo === 'S';
                ckCalculoFracionado.value = objEdicao.ckcalculofracionado === 'S';
                ckExportaOperador.value   = objEdicao.ckexportaoperador === 'S';
            }finally{isLoading.value=false;}
        };

        lerDados();

        return {ckAgendamento, ckAtivo, ckCalculoFracionado, ckExportaOperador, edAgendamentoLimiteDia, edMnHectare, isLoading, objEdicao, 
            submitForm, cancelForm };
    }
};
</script>