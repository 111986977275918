<template>
    <div v-if="isLoading" class="loading-overlay"><p>Carregando...</p></div>

    <div class="form-edit-page" style="display: flex; gap: 10px; align-items: end;">
        <button type="button" class="buttom-icon--printer" @click="openReport" />
        <button type="button" class="buttom-icon--home" @click="closeForm" />
    </div>

    <MensagemAlerta class="margin-top10" v-if="errorMessage" :mensagem="errorMessage" />
</template>

<script>
import { ref } from 'vue';
import { genericReport } from '@/funcoes/api';
import { globalStore } from '@/globals';
import MensagemAlerta from '@/components/MensagemAlerta.vue';

export default{
    components:{MensagemAlerta},
    emits: ['close'],
    setup(_, {emit}){
        const errorMessage = ref(null);
        const isLoading = ref(false);

        const closeForm = () => {emit('close');}

        const openReport = async() => {
            try{
                errorMessage.value=null;
                isLoading.value = true;
                const dados = {
                    nmrelatorio: 'ipos_executar_bairro',
                    idmunicipio: globalStore.idmunicipio,
                    idusuario: globalStore.idusuario,
                    intp1: globalStore.idipprestador};
                const response = await genericReport(dados);
                const pdfBlob = new Blob([response], { type: 'application/pdf' });
                const pdfUrl = URL.createObjectURL(pdfBlob);
                window.open(pdfUrl, '_blank');
            } catch (error){errorMessage.value = `ATENÇÃO: ${error.message}`;
            } finally {isLoading.value = false;}
        }

        return { errorMessage, isLoading, closeForm, openReport };
    }
}
</script>