<template>
    <div v-if="isLoading" class="loading-overlay"><p>Carregando...</p></div>


    <div v-if="!modoEdicao">
        <div class="form-edit-page" style="display: flex; gap: 10px; align-items: end;">
            <div class="form-item flexible">
                <label class="label-caption" for="nmPesquisa">Nome Pesquisa</label>
                <input type="text" v-model="nmPesquisa" id="nmPesquisa" maxlength="80" @input="nmPesquisa = nmPesquisa.toUpperCase()" />
            </div>
            <button type="button" class="buttom-icon--search" @click="lerDados" />
            <button type="button" class="buttom-icon--add" @click="novoCadastro" />
            <button type="button" class="buttom-icon--close" @click="closeForm" />
        </div>
        
        <table class="margin-top10">
            <thead>
                <tr>
                    <th class="col-width-integer">Código</th>
                    <th>Nome</th>
                    <th class="col-width-small">Grupo</th>
                    <th class="col-width-small">Situação</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(obj, index) in lsDados" :key="obj.id" :class="{'even-row': index % 2 === 0, 'odd-row': index % 2 !== 0, 'clickabe-row': true}" @click="editarCadastro(obj)">
                    <td class="col-width-integer right">{{ obj.cdservico }}</td>
                    <td>{{ obj.nmservico }}</td>
                    <td class="col-width-small">{{ obj.xidgruposervico }}</td>
                    <td class="col-width-small">{{ obj.ativonm }}</td>
                </tr>
            </tbody>
        </table>

        <div v-if="showPagination" class="pagination">
            <button :disabled="currentPage === 1" class="buttom-pagination--first" @click="currentPage=1; updatePaginatedData();" />
            <button :disabled="currentPage === 1" class="buttom-pagination--prior" @click="currentPage--; updatePaginatedData();" />

            <div class="field-label field-label-center">{{ currentPage }} de {{ Math.ceil(lsDadosMain.length / itemsPerPage) }}</div>

            <button :disabled="currentPage === Math.ceil(lsDadosMain.length / itemsPerPage)" class="buttom-pagination--next" @click="currentPage++; updatePaginatedData();" />
            <button :disabled="currentPage === Math.ceil(lsDadosMain.length / itemsPerPage)" class="buttom-pagination--last" @click="currentPage = Math.ceil(lsDadosMain.length / itemsPerPage); updatePaginatedData();" />
        </div>
    </div>


    <div v-if="modoEdicao">
        <ServicoEditPage :idEdicao="idEdicao" @close="modoEdicao = false" @salvo="lerDados" />
    </div>
</template>


<script>
import { onMounted, ref } from 'vue';
import { genericPost } from '@/funcoes/api'; 
import { globalStore } from '@/globals';
import ServicoEditPage from '../formularios/ServicoEditPage.vue';

export default{
    name: 'ServicosTabPage',
    emits: ['close'],
    components:{ServicoEditPage},

    setup(_, { emit }){
        const idEdicao = ref(0);
        const isLoading = ref(false);
        const lsDados = ref([]);
        const lsDadosMain = ref([]);
        const modoEdicao = ref(false);
        const nmPesquisa = ref(null);
        const showPagination = ref(false);

        const currentPage = ref(1);
        const itemsPerPage = ref(globalStore.itensPorPagina);

        const closeForm = () => {emit('close');}

        const editarCadastro = (obj) => {
            idEdicao.value = obj.id;
            modoEdicao.value = true;
        };

        const lerDados = async() => {
            try{
                isLoading.value = true;
                const req = await genericPost('/listas/servicos', {idmunicipio: globalStore.idmunicipio, nmpesquisar: nmPesquisa.value });
                lsDadosMain.value = req;
                currentPage.value=1;
                showPagination.value = (lsDadosMain.value.length > itemsPerPage.value);
                updatePaginatedData();
            } catch (error){console.error('Erro leitura dos dados', error);
            } finally {isLoading.value = false;}
        }

        const novoCadastro = () => {
            idEdicao.value = 0;
            modoEdicao.value = true;
        }

        const updatePaginatedData = () => {
            const startIndex = (currentPage.value - 1) * itemsPerPage.value;
            const endIndex = (startIndex + itemsPerPage.value);
            lsDados.value = lsDadosMain.value.slice(startIndex, endIndex);
        }

        onMounted(()=>{lerDados();})

        return {
            currentPage, idEdicao, isLoading, itemsPerPage, lsDados, lsDadosMain, modoEdicao, nmPesquisa, showPagination, 
            closeForm, editarCadastro, lerDados, novoCadastro, updatePaginatedData};
    }
}
</script>
