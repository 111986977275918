<template>
    <div v-if="isLoading" class="loading-overlay"><p>Carregando...</p></div>

    <MensagemAlerta class="row-space" v-if="msgEdicao" :mensagem="msgEdicao" />
    <MensagemAlerta class="row-space" v-if="errorMessage" :mensagem="errorMessage" />

    <div v-if="!showOsView && !showPessoaSelect" class="form-edit-page row-space">
        <div class="row row-space">
            <div class="form-item-char10">
                <label class="label-caption">Produtor</label>
                <div class="field-label field-label-end">{{ formatInteger(pessoaSelecionada.cdpessoa) }}</div>
            </div>

            <div class="form-item flexible">
                <label class="label-caption">Nome</label>
                <div class="field-label">{{ pessoaSelecionada.nmpessoa }}</div>
            </div>

            <div class="form-item-char20">
                <label class="label-caption">CPF</label>
                <div class="field-label field-label-center">{{ pessoaSelecionada.cpfcnpj }}</div>
            </div>

            <div class="form-item-char20">
                <label class="label-link" @click="showModalFone=true">Fone</label>
                <div class="field-label field-label-center">{{ pessoaSelecionada.fone }}</div>
            </div>
        </div>

        <div class="row row-space">
            <div class="form-item">
                <label class="label-caption">Inscrição Estadual</label>
                <div class="field-label field-label-center">{{ pessoaStatus.produtor_inscricao }}</div>
            </div>

            <div class="form-item-char15">
                <label class="label-caption">Produtor Ativo</label>
                <div :class="{
                    'field-label-alert': pessoaStatus.produtor_sn!='S',
                    'field-label': pessoaStatus.produtor_sn=='S',
                    'field-label-center': true
                }">{{ pessoaStatus.produtor_sn }}</div>
            </div>


            <div class="form-item-char20">
                <label class="label-caption">Valor À Emitir</label>
                <div :class="{
                    'field-label-alert': pessoaStatus.financeiro_aemitir,
                    'field-label': !pessoaStatus.financeiro_aemitir,
                    'field-label-end': true
                }">{{ formatDecimal(pessoaStatus.financeiro_aemitir) }}</div>
            </div>
            
            <div class="form-item-char20">
                <label class="label-caption">Valor Vencido</label>
                <div :class="{
                    'field-label-alert': pessoaStatus.financeiro_vencido,
                    'field-label': !pessoaStatus.financeiro_vencido,
                    'field-label-end': true
                }">{{ formatDecimal(pessoaStatus.financeiro_vencido) }}</div>
            </div>

            <div class="form-item-char20">
                <label class="label-caption">Saldo Banco</label>
                <div :class="{
                    'field-label-alert': pessoaStatus.saldo_vlbanco < 0,
                    'field-label': pessoaStatus.saldo_vlbanco >= 0,
                    'field-label-end': true
                }">{{ formatDecimal(pessoaStatus.saldo_vlbanco) }}</div>
            </div>

            <div class="form-item-char20">
                <label class="label-caption">Horas Aguardando</label>
                <div class="field-label field-label-center">{{ pessoaStatus.osst2_hrstring }}</div>
            </div>

            <div class="form-item-char20">
                <label class="label-caption">Saldo de Horas</label>
                <div class="field-label field-label-center">{{ pessoaStatus.saldo_hrstring }}</div>
            </div>
        </div>
    </div>


    <div v-if="!showOsView && !showPessoaSelect" class="form-edit-page">
        <form @submit.prevent="submitForm">
            <div class="row row-space">
                <LocalidadeSelect class="form-item" v-model:localidadeSelecionada="idLocalidade" required />

                <div class="form-item">
                    <label class="label-caption">Complemento/referência</label>
                    <input type="text" v-model="edLocalidadeComplemento" maxlength="100" v-uppercase />
                </div>
            </div>
            
            
            <div class="row row-space">
                <EquipamentoGrupoSelect class="form-item" 
                    :ckStatus="true"
                    v-model:grupoSelecionado="idGrupo"
                    @update:grupoSelecionadoObj="atualizarGrupo" required />
                <EquipamentoSelect :ckPesquisar="false" :flagTipo="1" :idGrupo="idGrupo" class="form-item" 
                    v-model:equipamentoSelecionado="idEquipamento"
                    @update:equipamentoSelecionadoObj="atualizarEquipamento" />
                <EquipamentoSelect :descCaption= "'Implemento'" :flagTipo="2" class="form-item"
                    v-model:equipamentoSelecionado="idImplemento"
                    @update:equipamentoSelecionadoObj="atualizarImplemento" />
            </div>

            <div class="row row-space">
                <ServicoSelect class="form-item" v-model:servicoSelecionado="idServico" required />

                <div class="form-item">
                    <label class="label-caption">Descrição Serviço</label>
                    <input type="text" v-model="edDescricao" maxlength="100" v-uppercase />
                </div>
                
            </div>


            <div class="row row-space">
                <div class="form-item">
                    <label class="label-caption">Hectares</label>
                    <input type="text" class="input-right" v-model="edHectares" v-decimal-mask maxlength="10"/>
                </div>

                <div class="form-item">
                    <label class="label-caption">Valor Hora</label>
                    <div class="field-label field-label-end">{{ formatDecimal(vlHora) }}</div>
                </div>

                <div class="form-item">
                    <label class="label-caption">Vl Implemento</label>
                    <div class="field-label field-label-end">{{ formatDecimal(vlHoraImplemento) }}</div>
                </div>

                <div class="form-item">
                    <label class="label-caption">Hr Contratadas</label>
                    <input type="text" class="input-center" v-time-mask v-model="edHrContratada" required />
                </div>

                <div v-if="(pessoaStatus) && (pessoaStatus.saldo_minutos > 0)" class="form-item">
                    <label class="label-caption">Hr Bonificadas</label>
                    <input type="text" class="input-center" v-time-mask v-model="edHrCtBanco" />
                </div>

                <div class="form-item">
                    <label class="label-caption">Hr Pagto</label>
                    <div class="field-label field-label-center">{{ edHrCtPagamento }}</div>
                </div>

                <div class="form-item">
                    <label class="label-caption">Valor Total</label>
                    <div class="field-label field-label-end">{{ formatDecimal(vlTotal) }}</div>
                </div>

                <div class="form-item">
                    <label class="label-caption">Agendamento</label>
                    <input type="date" v-model="edDtAgendamento" required />
                </div>

                <div class="form-item-char15">
                    <label class="label-caption">Latitude</label>
                    <input type="text" v-model="edLatitude" maxlength="10" />
                </div>

                <div class="form-item-char15">
                    <label class="label-caption">Longitude</label>
                    <input type="text" v-model="edLongitude" maxlength="10" />
                </div>
            </div>


            <div v-if="showConfirmaHora" class="row-end">
                <label for="ckConfirmaHora">CONFIRMAR OS COM HORA EXCEDENTE</label>
                <input id="ckConfirmaHora" type="checkbox" class="input-checkbox" v-model="ckConfirmaHora" />
            </div>

            <div v-if="showConfirmaStatus" class="row-end">
                <label for="ckConfirmaStatus">CONFIRMAR OS COM PENDÊNCIAS DO SOLICITANTE</label>
                <input id="ckConfirmaStatus" type="checkbox" class="input-checkbox" v-model="ckConfirmaStatus" />
            </div>

            <div class="row-end">
                <label for="ckChuva">Pode ser executada com chuva</label>
                <input id="ckChuva" type="checkbox" class="input-checkbox" v-model="ckChuva" />
            </div>

            <div class="row-end">
                <label for="ckPossuiEquipamento">Possui equipamento</label>
                <input id="ckPossuiEquipamento" type="checkbox" class="input-checkbox" v-model="ckPossuiEquipamento" />
            </div>

            <div class="row-end margin-top10">
                <button class="btn-confirm" type="submit">Salvar</button>
                <button class="btn-cancel" type="cancel" @click="cancelForm">Cancelar</button>
            </div>
        </form>
    </div>

    <div v-if="showPessoaSelect">
        <PessoaPesquisaPage
            @close="cancelForm"
            @pessoaSelecionada="selecionarPessoa" />
    </div>

    <div>
        <ModalFone
            :visible="showModalFone"
            @update:visible="(value) => (showModalFone = value)"
            @submit="redefinirFone" />
    </div>

    <div v-if="showOsView">
        <OSViewPage :idView="idOsView" @close="closeForm" />
    </div>
</template>

<script>
import { onMounted, ref, watch } from 'vue';
import { criarOs } from '@/models/Os';
import { decimalFromString, decimalFromTimeString, formatDecimal, formatInteger, formatTimeFromDecimal, strDataAtual } from '@/funcoes/utils';
import { genericPost, genericPut, getCadastroById } from '@/funcoes/api';
import { globalStore } from '@/globals';
import EquipamentoGrupoSelect from '@/selects/EquipamentoGrupoSelect.vue';
import EquipamentoSelect from '@/selects/EquipamentoSelect.vue';
import LocalidadeSelect from '@/selects/LocalidadeSelect.vue';
import MensagemAlerta from '@/components/MensagemAlerta.vue';
import ModalFone from '@/components/modals/ModalFone.vue';
import OSViewPage from './OSViewPage.vue';
import PessoaPesquisaPage from '../cadastros/pesquisas/PessoaPesquisaPage.vue';
import ServicoSelect from '@/selects/ServicoSelect.vue';

export default{
    components: {EquipamentoGrupoSelect, EquipamentoSelect, LocalidadeSelect, MensagemAlerta, ModalFone, OSViewPage, PessoaPesquisaPage, ServicoSelect},
    emits: ['close', 'save'],
    props: {idEdicao: {type: Number, default: 0}},
    setup(props, { emit }){
        const ckChuva = ref(false);
        const ckPossuiEquipamento = ref(false);
        const edDescricao = ref(null);
        const edDtAgendamento = ref(null);
        const edHectares = ref(null);
        const edHrContratada = ref("");
        const edHrCtBanco = ref("");
        const edHrCtPagamento = ref("");
        const edLatitude = ref(null);
        const edLongitude = ref(null);
        const edLocalidadeComplemento = ref(null);

        const ckConfirmaHora = ref(false);
        const ckConfirmaStatus = ref(false);
        const errorMessage = ref(null);
        const isLoading = ref(false);
        const idEquipamento = ref(null);
        const idGrupo = ref(null);
        const idImplemento = ref(null);
        const idLocalidade = ref(null);
        const idOperador = ref(0);
        const idOsView = ref(0);
        const idServico = ref(null);
        const msgEdicao = ref(null);
        const objEdicao = criarOs();
        const showConfirmaHora = ref(false);
        const showConfirmaStatus = ref(false);
        const showModalFone = ref(false);
        const showOsView = ref(false);
        const showPessoaSelect = ref(false);
        const vlHora = ref(0);
        const vlHoraImplemento = ref(0);
        const vlTotal = ref(0);
        const vlTotalFixado = ref(0);

        // OBJETOS
        const equipamentoSelecionado = ref(null);
        const grupoSelecionado = ref(null);
        const implementoSelecionado = ref(null);
        const pessoaSelecionada = ref({});
        const pessoaStatus = ref({});

        const atualizarEquipamento = (novoEquipamento) => {
            equipamentoSelecionado.value = novoEquipamento;
            if (equipamentoSelecionado.value.idoperador){idOperador.value = equipamentoSelecionado.value.idoperador;}
        };

        const atualizarGrupo = (novoGrupo) => {
            grupoSelecionado.value = novoGrupo;
        };

        const atualizarImplemento = (novoImplemento) => {
            implementoSelecionado.value = novoImplemento;
        };

        const atualizarVlTotal = () => {
            const horas                 = decimalFromTimeString(edHrContratada.value); // Converte string para número
            const horasCtBanco          = decimalFromTimeString(edHrCtBanco.value);
            const horasPagto            = (horas - horasCtBanco);
            showConfirmaHora.value      = (horas>10);
            edHrCtPagamento.value       = formatTimeFromDecimal(horasPagto);
            vlTotal.value               = (vlHora.value * horasPagto) + (vlHoraImplemento.value * horasPagto); // Calcula o total
        };

        const cancelForm = () => {emit('close');};

        const closeForm = () => {
            showOsView.value=false;
            emit('close');
            emit('save');
        };

        const lerDados = async() =>{
            try{
                errorMessage.value=null;
                isLoading.value=true;
                if (props.idEdicao > 0) {
                    try{
                        const req = await getCadastroById('os', props.idEdicao);
                        Object.assign(objEdicao, req[0]);
                        if (!objEdicao.idcobrancaitem){
                            msgEdicao.value='AVISO: EDIÇÃO DE ORDEM DE SERVIÇO';
                        }else{
                            vlTotalFixado.value=objEdicao.vlhr_total;
                            msgEdicao.value=`AVISO: VALOR TOTAL FIXADO R$ ${formatDecimal(vlTotalFixado.value)}`;
                        }

                        // LER DADOS DA PESSOA
                        const rPessoa = await genericPost('/listas/pessoas', {idmunicipio: globalStore.idmunicipio, id: objEdicao.idpessoa});
                        pessoaSelecionada.value = rPessoa[0];
                        lerPessoaStatus();

                        // ATRIBUIÇÃO DAS VARIÁVEIS
                        idGrupo.value                   = objEdicao.idequipamento_grupo;
                        idEquipamento.value             = objEdicao.idequipamento;
                        idImplemento.value              = objEdicao.idimplemento;
                        idServico.value                 = objEdicao.idservico;
                        idLocalidade.value              = objEdicao.idlocalidade;
                        edLocalidadeComplemento.value   = objEdicao.localidade_complemento;
                        edHectares.value                = formatDecimal(objEdicao.hectares);
                        edDtAgendamento.value           = objEdicao.dtinicioprevisto;
                        edHrContratada.value            = objEdicao.hrcontratadas;
                        edHrCtBanco.value               = objEdicao.hrctbanco;
                        edDescricao.value               = objEdicao.descricao;
                        ckPossuiEquipamento.value       = objEdicao.possuiequipamento === 1;
                        ckChuva.value                   = objEdicao.chuva === 1;
                        edLatitude.value                = objEdicao.latitude;
                        edLongitude.value               = objEdicao.longitude;

                        vlHora.value                    = objEdicao.vlhora1;
                        vlHoraImplemento.value          = objEdicao.vlhr_implemento;
                        atualizarVlTotal();                        

                    }catch (error){errorMessage.value='Erro leitura dos dados'}
                }else{
                    showPessoaSelect.value=true;
                    objEdicao.idmunicipio         = globalStore.idmunicipio;
                    objEdicao.idossituacao        = 2; //todo
                    objEdicao.dtinclusao          = strDataAtual();
                    objEdicao.tpdigitacao         = 1;
                }
            } catch (error) {errorMessage.value=error;
            } finally {isLoading.value=false;}
            objEdicao.idlogin             = globalStore.idlogin;
            objEdicao.idusuario           = globalStore.idusuario;
        }

        const lerPessoaStatus = async() => {
            if (!pessoaSelecionada.value){
                pessoaStatus.value=null;
                return;
            }

            try{
                showConfirmaStatus.value        = false;
                errorMessage.value              = null;
                const retOs = await genericPost('/dados/status_pessoa', {id: pessoaSelecionada.value.id});
                pessoaStatus.value=retOs[0];
                showConfirmaStatus.value        = (
                    (pessoaStatus.value.financeiro_aemitir) || 
                    (pessoaStatus.value.financeiro_vencido) || 
                    (pessoaStatus.value.saldo_vlbanco < 0) ||
                    (pessoaStatus.value.produtor_sn != 'S'));
            }catch (error){ errorMessage.value  = error;
            }finally{isLoading.value=false;}
        }

        const redefinirFone = async({fone}) =>{
            try{
                errorMessage.value  = null;
                isLoading.value     = true;
                const r = await genericPut('/cad/pessoa', {
                    id: pessoaSelecionada.value.id,
                    fone: fone, 
                    idusuario: globalStore.idusuario});
                if (r.bolok == 1){pessoaSelecionada.value.fone = fone}else{errorMessage.value = r.error}
            }finally {isLoading.value = false;}
        }

        const selecionarPessoa = (novaPessoa) => {
            pessoaSelecionada.value = novaPessoa; // Atualiza o valor da ref
            idLocalidade.value = pessoaSelecionada.value.idlocalidade;
            showPessoaSelect.value = false; // Fecha a tela de seleção
            lerPessoaStatus();
        };

        const submitForm = async () => {
            try{
                errorMessage.value                      = null;
                const mnContratados                     = Math.trunc(decimalFromTimeString(edHrContratada.value)*60);
                const mnCtBanco                         = Math.trunc(decimalFromTimeString(edHrCtBanco.value)*60);
                if ((edHrContratada.value.length != 5) || (mnContratados < 30)){
                    errorMessage.value                  = 'Informe horas contratadas corretamente. Mínimo permitido são 30 minutos';
                    return;
                }
                if ((mnContratados>600) && (!ckConfirmaHora.value)){
                    errorMessage.value                  = 'Necessário confirmar horas excedentes ao padrão';
                    return;
                }
                if (mnCtBanco > pessoaStatus.value.saldo_minutos){
                    errorMessage.value                  = 'Saldo informado de bonificação maior que saldo do produtor';
                    return;
                }
                if ((showConfirmaStatus.value) && (!ckConfirmaStatus.value)){
                    errorMessage.value                  = 'Necessário confirmar emissão com pendências do solicitante';
                    return;
                }
                if ((vlTotalFixado.value > 0) && (vlTotalFixado.value != vlTotal.value)){
                    errorMessage.value                  = 'Não é permitido alteração do valor total da OS';
                    return;
                }
                
                isLoading.value                   = true;
                objEdicao.idequipamento_grupo     = idGrupo.value;
                objEdicao.idequipamento           = idEquipamento.value??1;
                objEdicao.idimplemento            = idImplemento.value;
                objEdicao.idservico               = idServico.value;
                objEdicao.idpessoa                = pessoaSelecionada.value.id;
                objEdicao.idlocalidade            = idLocalidade.value;
                objEdicao.localidade_complemento  = edLocalidadeComplemento.value;
                objEdicao.hectares                = decimalFromString(edHectares.value);
                objEdicao.dtinicioprevisto        = edDtAgendamento.value;
                objEdicao.hrinicioprevisto        = '08:00';
                objEdicao.mncontratados           = mnContratados;
                objEdicao.mnctbanco               = mnCtBanco;
                objEdicao.descricao               = edDescricao.value;
                //objEdicao.value.obs                     = 'todo-obs';
                objEdicao.possuiequipamento       = ckPossuiEquipamento.value ? 1 : 0;
                objEdicao.agendado                = 0; //todo
                objEdicao.chuva                   = ckChuva.value ? 1 : 0;
                objEdicao.latitude                = !edLatitude.value ? 0 : parseFloat(edLatitude.value.toString().replace(',', '.')) || 0;
                objEdicao.longitude               = !edLongitude.value ? 0 : parseFloat(edLongitude.value.toString().replace(',', '.')) || 0;

                const retOs = await genericPut('/cad/os', objEdicao);
                if (retOs.bolok==1){
                    // emit('save', 'close');

                    idOsView.value=retOs.id;
                    showOsView.value=true;
                    // closeForm();
                }else{
                    console.log('ERRO OS', retOs.erro);
                }
            }catch (error){ errorMessage.value = error;
            }finally{isLoading.value=false;}
        }

        watch(edHrContratada, () => {
            atualizarVlTotal();
        });

        watch(edHrCtBanco, () => {
            atualizarVlTotal();
        });

        watch(equipamentoSelecionado, (novoObjeto) => {
            if (novoObjeto  && (decimalFromString(novoObjeto.vlhora)>0)) {
                vlHora.value = novoObjeto.vlhora || 0;
                atualizarVlTotal();
            }
        });

        watch(grupoSelecionado, (novoObjeto) => {
            if (novoObjeto) {
                vlHora.value = novoObjeto.vlhora || 0;
                edDtAgendamento.value = novoObjeto.dtprevisao;
                atualizarVlTotal();
            }
        });

        watch(implementoSelecionado, (novoObjeto) => {
            if (novoObjeto) {
                vlHoraImplemento.value = novoObjeto.vlhora || 0;
                atualizarVlTotal();
            }
        });

        onMounted(()=>{lerDados();});

        return{
            ckChuva, ckPossuiEquipamento, edDescricao, edDtAgendamento, edHectares, 
            edHrContratada, edHrCtBanco, edHrCtPagamento,
            edLatitude, edLongitude, edLocalidadeComplemento,
            errorMessage, idEquipamento, idGrupo, idImplemento, idLocalidade, idOperador, idOsView, idServico, isLoading, 
            msgEdicao,
            ckConfirmaHora, ckConfirmaStatus, showConfirmaHora, showConfirmaStatus,
            objEdicao, pessoaSelecionada, pessoaStatus, vlHora, vlHoraImplemento, vlTotal,
            showModalFone,
            showOsView,
            showPessoaSelect,
            atualizarEquipamento, atualizarGrupo, atualizarImplemento, 
            cancelForm,
            closeForm, formatDecimal, formatInteger,
            redefinirFone, selecionarPessoa, submitForm};
    }
}
</script>