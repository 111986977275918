<template>
    <div v-if="isLoading" class="loading-overlay"><p>Carregando...</p></div>

    <div class="form-edit-page">
        <form @submit.prevent="submitForm">
            <div class="row row-space">
                <div class="form-item" style="flex: 0 0 90px;">
                    <label class="label-caption">Código</label>
                    <div class="field-label field-label-end">{{ objEdicao.cdproduto }}</div>
                </div>
                
                <div class="form-item flexible">
                    <label class="label-caption">Nome</label>
                    <input type="text" v-model="objEdicao.nmproduto" required maxlength="100" v-uppercase />
                </div>

                <div class="form-item" style="flex: 0 0 160px;">
                    <label class="label-caption">Unidade</label>
                    <input type="text" v-model="objEdicao.unidade" required maxlength="10" v-uppercase />
                </div>
            </div>


            <div class="row row-space">
                <DespesaGrupoSelect class="form-item flexible" v-model:grupoSelecionado="objEdicao.iddespesagrupo" />

                <div class="form-item" style="flex: 0 0 160px;">
                    <label class="label-caption">Valor Custo</label>
                    <input v-model="edVlCusto" v-decimal-mask type="text" class="input-right" />
                </div>

                <div class="form-item" style="flex: 0 0 160px;">
                    <label class="label-caption">Estoque Mínimo</label>
                    <input type="text" class="input-right" v-model="edAlmoxMinimo" v-number maxlength="5" />
                </div>
            </div>


            <div class="row-end">
                <label for="ckativo">Ativo</label>
                <input id="ckativo" type="checkbox" class="input-checkbox" v-model="ckAtivo" />
            </div>
            <div class="row-end">
                <label for="ckcombustivel">Combustível</label>
                <input id="ckcombustivel" type="checkbox" class="input-checkbox" v-model="ckCombustivel" />
            </div>
            <div class="row-end">
                <label for="ckip">Material Iluminação Pública</label>
                <input id="ckip" type="checkbox" class="input-checkbox" v-model="ckIp" />
            </div>


            <div class="row-end margin-top10">
                <button class="btn-confirm" type="submit">Salvar</button>
                <button class="btn-cancel" type="cancel" @click="cancelForm">Cancelar</button>
            </div>
        </form>
    </div>
</template>
  
<script>
import { ref } from 'vue';
import { criarProduto } from '@/models/Produto';
import { decimalFromString, formatDecimal, formatInteger, integerFromString } from '@/funcoes/utils';
import { genericPut, getCadastroById } from '@/funcoes/api';
import { globalStore } from '@/globals';
import DespesaGrupoSelect from '@/selects/DespesaGrupoSelect.vue';

export default {
    name: 'ProdutoEditPage',
    components: { DespesaGrupoSelect },
    emits: ['close', 'salvo'],
    props: {idEdicao: {type: Number, default: 0}},
    setup(props, { emit }) {
        const ckAtivo = ref(false);
        const ckCombustivel = ref(false);
        const ckIp = ref(false);
        const edAlmoxMinimo = ref(null);
        const edVlCusto = ref(null);
        const objEdicao = criarProduto();
        const isLoading = ref(false);

        const submitForm = async () => {
            try {
                objEdicao.ativo         = ckAtivo.value ? 'S' : 'N';
                objEdicao.ckcombustivel = ckCombustivel.value ? 'S' : 'N';
                objEdicao.ckip          = ckIp.value ? 'S' : 'N';
                objEdicao.almox_minimo  = integerFromString(edAlmoxMinimo.value);
                objEdicao.vlcusto       = decimalFromString(edVlCusto.value);
                const response          = await genericPut('/cad/produto', objEdicao);
                if (response.bolok === 1){
                    emit('salvo');
                    emit('close');
                }else{
                    alert(response.erro);
                }
            } catch (error) {console.error('Error:', error);}
        };

        const cancelForm = () => {emit('close');};

        const lerDados = async() => {
            try{
                isLoading.value=true;
                if (props.idEdicao > 0) {
                    try{
                        const req = await getCadastroById('produto', props.idEdicao);
                        Object.assign(objEdicao, req[0]);
                    }catch (error){console.error('Erro leitura dos dados', error);}
                }else{
                    objEdicao.idmunicipio = globalStore.idmunicipio;
                }
                objEdicao.idusuario       = globalStore.idusuario;
                ckAtivo.value             = objEdicao.ativo === 'S';
                ckCombustivel.value       = objEdicao.ckcombustivel === 'S';
                ckIp.value                = objEdicao.ckip === 'S';
                edAlmoxMinimo.value       = formatInteger(objEdicao.almox_minimo);
                edVlCusto.value           = formatDecimal(objEdicao.vlcusto);
            }finally{isLoading.value=false;}
        };

        lerDados();

        return { ckAtivo, ckCombustivel, ckIp, edAlmoxMinimo, edVlCusto, isLoading, objEdicao, submitForm, cancelForm };
    }
};
</script>