import { createApp } from 'vue'
import App from './App.vue'
import pinia from './store'
import router from './router';
import 'leaflet/dist/leaflet.css';

import cepMask from './diretives/cepMask';
import cnpjMask from './diretives/cnpjMask';
import cpfMask from './diretives/cpfMask';
import decimalMask from './diretives/decimalMask';
import numberMask from './diretives/numberMask';
import phoneMask from './diretives/phoneMask';
import timeMask from './diretives/timeMask';
import uppercase from './diretives/uppercase';

createApp(App)
    .directive('cep', cepMask)
    .directive('cnpj', cnpjMask)
    .directive('cpf', cpfMask)
    .directive('decimal-mask', decimalMask)
    .directive('number', numberMask)
    .directive('phone', phoneMask)
    .directive('time-mask', timeMask)
    .directive('uppercase', uppercase)
    .use(pinia)
    .use(router)
    .mount('#app');