<template>
    <div v-if="isLoading" class="loading-overlay"><p>Carregando...</p></div>

    <div class="form-edit-page">
        <form @submit.prevent="submitForm">
            <div class="row row-space">
                <div class="form-item" style="flex: 0 0 90px;">
                    <label class="label-caption">Código</label>
                    <div class="field-label field-label-end">{{ objEdicao.cdequipamento }}</div>
                </div>
                
                <div class="form-item flexible">
                    <label class="label-caption">Nome</label>
                    <input type="text" v-model="objEdicao.nmequipamento" required maxlength="100" v-uppercase />
                </div>

                <div class="form-item" style="flex: 0 0 220px;">
                    <EquipamentoGrupoSelect v-model:grupoSelecionado="objEdicao.idgrupo" required />
                </div>

                <div class="form-item" style="flex: 0 0 180px;">
                    <TipoEquipamentoSelect v-model:tipoSelecionado="objEdicao.tipo" required />
                </div>

                <div class="form-item" style="flex: 0 0 160px;">
                    <TipoAgendamentoSelect v-model:tipoSelecionado="objEdicao.tipoagendamento" />
                </div>
            </div>


            <div class="row row-space">
                <div class="form-item" style="flex: 0 0 180px;">
                    <EquipamentoSecretariaSelect v-model:secretariaSelecionada="objEdicao.cdsecretaria" />
                </div>
                <div class="form-item flexible">
                    <RegiaoSelect v-model:regiaoSelecionada="objEdicao.idregiao" />
                </div>
                <div class="form-item" style="flex: 0 0 220px;">
                    <EquipamentoSetorSelect v-model:setorSelecionada="objEdicao.idequipamentosetor" />
                </div>
                <div class="form-item flexible">
                    <ProdutoSelect :ckCombustivel="true" v-model:produtoSelecionado="objEdicao.idcombustivel" />
                </div>
                <div class="form-item" style="flex: 0 0 140px;">
                    <TipoMedicaoSelect v-model:tipoSelecionado="objEdicao.tpmedicao" />
                </div>
            </div>


            <div class="row row-space">
                <div class="form-item" style="flex: 0 0 140px;">
                    <label class="label-caption">Nro Patrimônio</label>
                    <input type="text" class="input-center" v-model="objEdicao.nropatrimonio" maxlength="20" v-uppercase />
                </div>
                <div class="form-item flexible">
                    <label class="label-caption">Marca</label>
                    <input type="text" v-model="objEdicao.marca" maxlength="100" v-uppercase />
                </div>
                <div class="form-item flexible">
                    <label class="label-caption">Modelo</label>
                    <input type="text" v-model="objEdicao.modelo" maxlength="100" v-uppercase />
                </div>
            </div>


            <div class="row row-space">
                <div class="form-item" style="flex: 0 0 140px;">
                    <label class="label-caption">Placa</label>
                    <input type="text" class="input-center" v-model="objEdicao.placa" maxlength="20" v-uppercase />
                </div>
                <div class="form-item" style="flex: 0 0 140px;">
                    <label class="label-caption">Ano</label>
                    <input type="text" class="input-right" v-model="edAno" v-number maxlength="5" />
                </div>
                <div class="form-item" style="flex: 0 0 140px;">
                    <label class="label-caption">Aquisição</label>
                    <input type="date" v-model="objEdicao.dtaquisicao" />
                </div>
                <div class="form-item flexible">
                    <label class="label-caption">Cor</label>
                    <input type="text" v-model="objEdicao.cor" maxlength="100" v-uppercase />
                </div>
                <div class="form-item flexible">
                    <label class="label-caption">Chassi</label>
                    <input type="text" v-model="objEdicao.nrochassi" maxlength="100" v-uppercase />
                </div>
                <div class="form-item flexible">
                    <label class="label-caption">Renavan</label>
                    <input type="text" v-model="objEdicao.nrorenavan" maxlength="100" v-uppercase />
                </div>
            </div>


            <div class="row row-space">
                <div class="form-item" style="flex: 0 0 120px;">
                    <label class="label-caption">Horas/Dia</label>
                    <input type="text" class="input-center" v-time-mask v-model="objEdicao.horasdia" maxlength="5" />
                </div>

                <div class="form-item">
                    <label class="label-caption">Valor Hora</label>
                    <input v-model="edVlHora" v-decimal-mask type="text" class="input-right" />
                </div>

                <div class="form-item" style="flex: 0 0 140px;">
                    <label class="label-caption">Valor Taxado</label>
                    <input v-model="edVlHoraTaxado" v-decimal-mask type="text" class="input-right" />
                </div>

                <div class="form-item" style="flex: 0 0 150px;">
                    <label class="label-caption">Min Faixa 2</label>
                    <input type="text" class="input-right" v-model="edMnFaixa2" v-number maxlength="5" />
                </div>

                <div class="form-item">
                    <label class="label-caption">Valor 2</label>
                    <input v-model="edVlHora2" v-decimal-mask type="text" class="input-right" />
                </div>

                <div class="form-item" style="flex: 0 0 150px;">
                    <label class="label-caption">Min Faixa 3</label>
                    <input type="text" class="input-right" v-model="edMnFaixa3" v-number maxlength="5" />
                </div>

                <div class="form-item">
                    <label class="label-caption">Valor 3</label>
                    <input v-model="edVlHora3" v-decimal-mask type="text" class="input-right" />
                </div>

                <div class="form-item" style="flex: 0 0 150px;">
                    <label class="label-caption">Alíq. UFM</label>
                    <input type="text" class="input-right" v-model="edAlUfm" v-number maxlength="5" />
                </div>
            </div>


            <div class="row row-space">
                <div class="form-item">
                    <label class="label-caption">Observações</label>
                    <textarea v-model="objEdicao.obs" maxlength="1000" rows="5" style="resize: none;"/>
                </div>
            </div>


            <div class="row-end">
                <label for="ckAtivo">Ativo</label>
                <input id="ckAtivo" type="checkbox" class="input-checkbox" v-model="ckAtivo" />
            </div>
            <div class="row-end">
                <label for="ckAgendaLivre">Agenda Livre</label>
                <input id="ckAgendaLivre" type="checkbox" class="input-checkbox" v-model="ckAgendaLivre" />
            </div>
            <div class="row-end">
                <label for="ckTerceirizado">Equipamento terceirizado</label>
                <input id="ckTerceirizado" type="checkbox" class="input-checkbox" v-model="ckTerceirizado" />
            </div>
            <div class="row-end">
                <label for="ckTrocaOperador">Permite troca pelo aplicativo do operador</label>
                <input id="ckTrocaOperador" type="checkbox" class="input-checkbox" v-model="ckTrocaOperador" />
            </div>


            <div class="row-end margin-top10">
                <button class="btn-confirm" type="submit">Salvar</button>
                <button class="btn-cancel" type="cancel" @click="cancelForm">Cancelar</button>
            </div>
        </form>
    </div>
</template>
  
<script>
import { ref } from 'vue';
import { criarEquipamento } from '@/models/Equipamento';
import { decimalFromString, formatDecimal, formatInteger, integerFromString } from '@/funcoes/utils';
import { useLoginStore } from '@/store/login';
import { genericPut, getCadastroById } from '@/funcoes/api';
import EquipamentoGrupoSelect from '@/selects/EquipamentoGrupoSelect.vue';
import EquipamentoSecretariaSelect from '@/selects/EquipamentoSecretariaSelect.vue';
import EquipamentoSetorSelect from '@/selects/EquipamentoSetorSelect.vue';
import ProdutoSelect from '@/selects/ProdutoSelect.vue';
import RegiaoSelect from '@/selects/RegiaoSelect.vue';
import TipoAgendamentoSelect from '@/selects/TipoAgendamentoSelect.vue';
import TipoEquipamentoSelect from '@/selects/TipoEquipamentoSelect.vue';
import TipoMedicaoSelect from '@/selects/TipoMedicaoSelect.vue';

export default {
    name: 'EquipamentoEditPage',
    components: { EquipamentoGrupoSelect, EquipamentoSecretariaSelect, EquipamentoSetorSelect, ProdutoSelect, 
        RegiaoSelect, TipoAgendamentoSelect, TipoEquipamentoSelect, TipoMedicaoSelect },
    emits: ['close', 'salvo'],
    props: {idEdicao: {type: Number, default: 0}},
    setup(props, { emit }) {
        const ckAgendaLivre = ref(false);
        const ckAtivo = ref(false);
        const ckTerceirizado = ref(false);
        const ckTrocaOperador = ref(false);
        const edAlUfm = ref(null);
        const edAno = ref(null);
        const edMnFaixa2 = ref(null);
        const edMnFaixa3 = ref(null);
        const edVlHora = ref(null);
        const edVlHora2 = ref(null);
        const edVlHora3 = ref(null);
        const edVlHoraTaxado = ref(null);
        const objEdicao = criarEquipamento();
        const isLoading = ref(false);
        const login = useLoginStore();

        const submitForm = async () => {
            try {
                objEdicao.ckagenda_livre   = ckAgendaLivre.value ? 'S' : 'N';
                objEdicao.ativo            = ckAtivo.value ? 'S' : 'N';
                objEdicao.blterceirizado   = ckTerceirizado.value ? 'S' : 'N';
                objEdicao.bltroca_operador = ckTrocaOperador.value ? 'S' : 'N';
                objEdicao.alufm            = decimalFromString(edAlUfm.value);
                objEdicao.ano              = integerFromString(edAno.value);
                objEdicao.mnfaixa2         = integerFromString(edMnFaixa2.value);
                objEdicao.mnfaixa3         = integerFromString(edMnFaixa3.value);
                objEdicao.vlhora           = decimalFromString(edVlHora.value);
                objEdicao.vlhora2          = decimalFromString(edVlHora2.value);
                objEdicao.vlhora3          = decimalFromString(edVlHora3.value);
                objEdicao.vlhora_taxado    = decimalFromString(edVlHoraTaxado.value);
                const response = await genericPut('/cad/equipamento', objEdicao);
                if (response.bolok === 1){
                    emit('salvo');
                    emit('close');
                }else{
                    alert(response.erro);
                }
            } catch (error) {console.error('Error:', error);}
        };

        const cancelForm = () => {emit('close');};

        const lerDados = async() => {
            try{
                isLoading.value=true;
                if (props.idEdicao > 0) {
                    try{
                        const req = await getCadastroById('equipamento', props.idEdicao);
                        Object.assign(objEdicao, req[0]);
                        edAlUfm.value           = formatDecimal(objEdicao.alufm);
                        edAno.value             = formatInteger(objEdicao.ano);
                        edMnFaixa2.value        = formatInteger(objEdicao.mnfaixa2);
                        edMnFaixa3.value        = formatInteger(objEdicao.mnfaixa3);
                        edVlHora.value          = formatDecimal(objEdicao.vlhora);
                        edVlHora2.value         = formatDecimal(objEdicao.vlhora2);
                        edVlHora3.value         = formatDecimal(objEdicao.vlhora3);
                        edVlHoraTaxado.value    = formatDecimal(objEdicao.vlhora_taxado);
                    }catch (error){console.error('Erro leitura dos dados', error);}
                }else{
                    objEdicao.idmunicipio = login.userData.idmunicipio;
                }
                objEdicao.idusuario   = login.userData.id;
                ckAgendaLivre.value   = objEdicao.ckagenda_livre === 'S';
                ckAtivo.value         = objEdicao.ativo === 'S';
                ckTerceirizado.value  = objEdicao.blterceirizado === 'S';
                ckTrocaOperador.value = objEdicao.bltroca_operador === 'S';
            }finally{isLoading.value=false;}
        };

        lerDados();

        return {
            edAlUfm, edAno, edMnFaixa2, edMnFaixa3, edVlHora, edVlHora2, edVlHora3, edVlHoraTaxado,
            ckAgendaLivre, ckAtivo, ckTerceirizado, ckTrocaOperador, isLoading, objEdicao, 
            submitForm, cancelForm };
    }
};
</script>