import { createRouter, createWebHistory } from 'vue-router';
import ForgotPasswordPage from '@/views/main/ForgotPasswordPage.vue';
import LoginPage from '@/views/main/LoginPage.vue';
import MainIpPage from '@/views/main/MainIpPage.vue';
import MainIpPrestadorPage from '@/views/main/MainIpPrestadorPage.vue';
import MainObrasPage from '@/views/main/MainObrasPage.vue';
import MainOsPage from '@/views/main/MainOsPage.vue';
import ResetPasswordPage from '@/views/main/ResetPasswordPage.vue';
// import WelcomePage from '@/views/main/WelcomePage.vue';

const routes = [
    // { path: '/', name: 'WelcomePage', component: WelcomePage },
    { path: '/', name: 'LoginPage', component: LoginPage },
    { path: '/forgot-password', name: 'ForgotPasswordPage', component: ForgotPasswordPage },
    { path: '/login', name: 'LoginPageLogout', component: LoginPage },
    { path: '/main-ip', name: 'MainIpPage', component: MainIpPage },
    { path: '/main-ipprestador', name: 'MainIpPrestadorPage', component: MainIpPrestadorPage },
    { path: '/main-obras', name: 'MainObrasPage', component: MainObrasPage },
    { path: '/main-os', name: 'MainOsPage', component: MainOsPage },
    { path: '/reset-password', name: 'ResetPasswordPage', component: ResetPasswordPage },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;
