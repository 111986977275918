import { reactive } from 'vue';

export const criarPessoa = () => {
    return reactive({
        id: 0,
        idmunicipio: 0,
        cdpessoa: 0,
        idlocalidade: 0,
        nmpessoa: '',
        apelido: '',
        cpfcnpj: '',
        cpfcnpjnros: '',
        tipo: '',
        endereco: '',
        nmendereco: 0,
        complemento: '',
        nmbairro: '',
        cep: '',
        uf: '',
        fone: '',
        email: '',
        inscricaoestadual: '',
        login: '',
        senha: '',
        dtsenha: '',
        dtnascimento: '',
        ativo: 'S',
        ckexportaoperador: 'N',
        ckprodutor_ativo: 'N',
        idusuario: 0
    });
};