<template>
    <div v-if="isLoading" class="loading-overlay"><p>Carregando...</p></div>

    <div class="content-row">
        <div class="content-data">
            <div class="form-edit-page">
                <div class="row row-space">
                    <div class="form-item" style="flex: 0 0 90px;">
                        <label class="label-caption">Nro OS</label>
                        <div class="field-label field-label-end">{{ formatInteger(objView.cdipos) }}</div>
                    </div>

                    <div class="form-item form-item-data">
                        <label class="label-caption">Data</label>
                        <div class="field-label field-label-center">{{ formatDateFromJson(objView.dtipos) }}</div>
                    </div>

                    <div class="form-item">
                        <label class="label-caption">Bairro</label>
                        <div class="field-label">{{ objView.nmbairro }}</div>
                    </div>

                    <div class="form-item ">
                        <label class="label-caption">Logradouro</label>
                        <div class="field-label">{{ objView.nmlogradouro }}</div>
                    </div>

                    <div class="form-item" style="flex: 0 0 90px;">
                        <label class="label-caption">Nro</label>
                        <div class="field-label field-label-end">{{ formatInteger(objView.nmendereco) }}</div>
                    </div>
                </div>


                <div class="row row-space">
                    <div class="form-item">
                        <label class="label-caption">Referência</label>
                        <div class="field-label">{{ objView.referencia }}</div>
                    </div>

                    <div class="form-item" style="flex: 0 0 220px;">
                        <label class="label-caption">Latitude</label>
                        <div class="field-label field-label-center">{{ objView.latitude }}</div>
                    </div>

                    <div class="form-item" style="flex: 0 0 220px;">
                        <label class="label-caption">Longitude</label>
                        <div class="field-label field-label-center">{{ objView.longitude }}</div>
                    </div>
                </div>


                <div class="row row-space">
                    <div class="form-item" style="flex: 0 0 90px;">
                        <label class="label-caption">Cód. Prest.</label>
                        <div class="field-label field-label-end">{{ formatInteger(objView.idipprestador) }}</div>
                    </div>

                    <div class="form-item">
                        <label class="label-caption">Prestador do Serviço</label>
                        <div class="field-label">{{ objView.nmfornecedor }}</div>
                    </div>

                    <div class="form-item" style="flex: 0 0 220px;">
                        <label class="label-caption">Situação</label>
                        <div class="field-label field-label-center">{{ objView.statusnm }}</div>
                    </div>
                </div>


                <div class="row row-space">
                    <div class="form-item">
                        <label class="label-caption">Data/Hora Autorização</label>
                        <div class="field-label">{{ formatDateTimeFromJson(objView.dhautorizacao) }}</div>
                    </div>

                    <div class="form-item form-item-data">
                        <label class="label-caption">Data Início</label>
                        <div class="field-label field-label-center">{{ formatDateFromJson(objView.dtinicio) }}</div>
                    </div>

                    <div class="form-item form-item-data">
                        <label class="label-caption">Hora</label>
                        <div class="field-label field-label-center">{{ objView.hrinicio }}</div>
                    </div>

                    <div class="form-item form-item-data">
                        <label class="label-caption">Data Fim</label>
                        <div class="field-label field-label-center">{{ formatDateFromJson(objView.dtfim) }}</div>
                    </div>

                    <div class="form-item form-item-data">
                        <label class="label-caption">Hora</label>
                        <div class="field-label field-label-center">{{ objView.hrfim }}</div>
                    </div>

                    <div class="form-item form-item-data">
                        <label class="label-caption">Quantidade</label>
                        <div class="field-label field-label-end">{{ formatDecimal(objView.qtd_itens) }}</div>
                    </div>

                    <div class="form-item form-item-data">
                        <label class="label-caption">Total</label>
                        <div class="field-label field-label-end">{{ formatDecimal(objView.vltotal) }}</div>
                    </div>
                </div>


                <div class="row row-space">
                    <div class="form-item">
                        <label class="label-caption">Detalhamento</label>
                        <div class="field-label">{{ objView.detalhamento }}</div>
                    </div>            
                </div>


                <div class="row-end">
                    <button type="button" @click="closeForm">Retornar</button>
                </div>
            </div>
        </div>


        <div v-if="imageSrc" class="content-foto">
            <div class="form-edit-page">
                <div v-if="imageSrc">
                    <img :src="imageSrc" alt="Imagem carregada" @click="showModalImage = true" />
                </div>
            </div>
        </div>
    </div>


    <div>
        <ImageModal v-if="showModalImage" :imageSrc="imageSrc" :visible="showModalImage" @close="showModalImage=false" />
    </div>


    <div v-if="showMap" class="form-edit-page margin-top10">
        <MapComponent :latitude="objView.latitude" :longitude="objView.longitude" />
    </div>
</template>

<script>
import { ref } from 'vue';
import { formatDateFromJson, formatDateTimeFromJson, formatDecimal, formatInteger } from '@/funcoes/utils';
import { genericGet, genericPost } from '@/funcoes/api';
import { globalStore } from '@/globals';
import ImageModal from '@/components/modals/ImageModal.vue';
import MapComponent from '@/components/MapComponent.vue';

export default{
    components: {ImageModal, MapComponent},
    emits:['close'],
    props:{idView: {type: Number, default: 0}},
    setup(props, {emit}){
        const imageSrc = ref(null);
        const isLoading = ref(false);
        const objView = ref({});
        const showMap = ref(false);
        const showModalImage = ref(false);

        const closeForm = () => {emit('close');}

        const lerDados = async() => {
            try{
                isLoading.value = true;
                const req = await genericPost('/ip/ipos/full', {idmunicipio: globalStore.idmunicipio, id: props.idView});
                Object.assign(objView.value, req[0]);
                showMap.value = ((objView.value) && (objView.value.latitude) && (objView.value.longitude));


                // BUSCA DADOS DA SOLICITAÇÃO PARA BAIXAR IMAGEM ANEXADA
                const reqSol = await genericPost('/ip/ipossolicitacoes', {idmunicipio: globalStore.idmunicipio, intparam2: objView.value.id});
                if (reqSol.length>0){
                    // LISTA DE ANEXOS  --  MOSTRANDO APENAS O PRIMEIRO
                    const lsImagens = await genericPost('/ip/ipossolicitacoes/anexos', {idmunicipio: globalStore.idmunicipio, id: reqSol[0].id});
                    if (lsImagens.length > 0){
                        const reqFoto = await genericGet('/main/anexo_view_base64', {id: lsImagens[0].idanexo});
                        if (reqFoto){
                            imageSrc.value = `data:image/jpeg;base64,${reqFoto.anexo}`;
                        }
                    }
                }


            }catch (error){alert(error);
            }finally{isLoading.value = false;}
        }

        lerDados();

        return{
            imageSrc, isLoading, objView, showMap, showModalImage,
            closeForm, formatDateFromJson, formatDateTimeFromJson, formatDecimal, formatInteger}
    }
}
</script>

<style scoped>
.content-row{
    display: flex;
    gap: 10px;
}
.content-data{
    flex: 1;
}
.content-foto{
    width: 300px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
.content-foto img{
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
    border-radius: 8px;
    cursor: pointer;
}
</style>