<template>
    <div class="layout">
        <aside class="sidebar" :class="{ collapsed: isCollapsed }">
            <ul>
                <li @click="toggleMenu">
                    <img :src="menuIcon" alt="Toggle Menu" />
                    <span>{{ isCollapsed ? 'Expandir' : 'Recolher' }}</span>
                </li>


                <li v-if="ckLoginAdmin" @mouseover="showSettingsMenu = true" @mouseleave="showSettingsMenu = false">
                    <img src="@/assets/icons/menuSettings.svg" />
                    <span>Configurações</span>
                    <div v-if="showSettingsMenu" class="submenu" :class="{ 'relative': isCollapsed }">
                        <ul>
                            <li @click="selectOption('MunicipioConfigEditPage', 'CONIGURAÇÕES DO SISTEMA')">
                                <span>Configurações do Sistema</span>
                            </li>
                            <li @click="selectOption('CobrConveniosTabPage', 'CONVÊNIOS DE COBRANÇA')">
                                <span>Convênios de Cobrança</span>
                            </li>
                            <li @click="selectOption('BancoHorasTipoTabPage', 'GRUPOS DE BANCO DE HORAS')">
                                <span>Grupos de Banco de Horas</span>
                            </li>
                            <li @click="selectOption('DespesasGruposTabPage', 'GRUPOS DE DESPESAS')">
                                <span>Grupos de Despesas</span>
                            </li>
                            <li @click="selectOption('ProdutosTabPage', 'PRODUTOS')">
                                <span>Produtos</span>
                            </li>
                            <li @click="selectOption('EquipamentosSetoresTabPage', 'SETORES DE EQUIPAMENTOS')">
                                <span>Setores de Equipamentos</span>
                            </li>
                            <li @click="selectOption('UsuariosTabPage', 'USUÁRIOS')">
                                <span>Usuários</span>
                            </li>
                        </ul>
                    </div>
                </li>


                <li @mouseover="showCadastrosMenu = true" @mouseleave="showCadastrosMenu = false">
                    <img src="@/assets/icons/menuOpcoes.svg" />
                    <span>Cadastros</span>
                    <div v-if="showCadastrosMenu" class="submenu" :class="{ 'relative': isCollapsed }">
                        <ul>
                            <li @click="selectOption('EquipamentosTabPage', 'EQUIPAMENTOS')">
                                <span>Equipamentos</span>
                            </li>
                            <li @click="selectOption('EquipamentosGruposTabPage', 'GRUPOS DE EQUIPAMENTO')">
                                <span>Grupos de Equipamento</span>
                            </li>
                            <li @click="selectOption('LocalidadesTabPage', 'LOCALIDADES')">
                                <span>Localidades</span>
                            </li>
                            <li @click="selectOption('OperadoresTabPage', 'OPERADORES')">
                                <span>Operadores</span>
                            </li>
                            <li @click="selectOption('PessoasTabPage', 'PESSOAS')">
                                <span>Pessoas e Produtores</span>
                            </li>
                            <li @click="selectOption('RegioesTabPage', 'REGIÕES')">
                                <span>Regiões</span>
                            </li>
                            <li @click="selectOption('ServicosTabPage', 'TIPOS DE SERVIÇO')">
                                <span>Serviços</span>
                            </li>
                        </ul>
                    </div>
                </li>

                <li @click="setComponenteAtivo('OsSolicitacoesTabPage', 'SOLICITAÇÕES DE SERVIÇO')">
                    <img src="@/assets/icons/menuCall.svg" />
                    <span>Solicitações</span>
                </li>

                <li @click="setComponenteAtivo('OsEditPage', 'EMISSÃO DE ORDEM DE SERVIÇO')">
                    <img src="@/assets/icons/menuEmissao.svg" />
                    <span>Emissão de OS</span>
                </li>

                <li @click="setComponenteAtivo('OsCancelamentoPage', 'CANCELAMENTO DE ORDEM DE SERVIÇO')">
                    <img src="@/assets/icons/menuCancel.svg" />
                    <span>Cancelamento de OS</span>
                </li>

                <li @click="setComponenteAtivo('OsAlteracaoPage', 'ALTERAÇÃO DE ORDEM DE SERVIÇO EMITIDA')">
                    <img src="@/assets/icons/menuEdit.svg" />
                    <span>Alterar OS Emitida</span>
                </li>

                <li @click="setComponenteAtivo('OsBaixaHorimetroPage', 'OS - BAIXA POR HORÍMETRO')">
                    <img src="@/assets/icons/menuOdometer.svg" />
                    <span>Baixa por horímetro</span>
                </li>

                <li @click="setComponenteAtivo('OsSobrasAguardandoPage', 'OS - ANÁLISE DE SALDOS DE HORAS')">
                    <img src="@/assets/icons/menuCheckDuplo.svg" />
                    <span>Analisar  Saldo de Horas</span>
                </li>

                <li @click="setComponenteAtivo('OsTabPage', 'ORDENS DE SERVIÇO')">
                    <img src="@/assets/icons/menuDados.svg" />
                    <span>Tabela de OS</span>
                </li>

                <li @mouseover="showGaragemMenu = true" @mouseleave="showGaragemMenu = false">
                    <img src="@/assets/icons/menuGaragem.svg" />
                    <span>Garagem</span>
                    <div v-if="showGaragemMenu" class="submenu" :class="{ 'relative': isCollapsed }">
                        <ul>
                            <li @click="selectOption('AgendaEquipamentoPage', 'GARAGEM - AGENDA POR EQUIPAMENTO')">
                                <span>Agenda por Equipamento</span>
                            </li>
                            <li @click="selectOption('AgendaOperadorPage', 'OS - GARAGEM - AGENDA POR OPERADOR')">
                                <span>Agenda por Operador</span>
                            </li>
                            <li @click="selectOption('GaragemDemandaAgendamentoPage', 'GARAGEM - AGENDAMENTO E DEMANDA POR GRUPO')">
                                <span>Agendamento e Demanda por Grupo</span>
                            </li>
                            <li @click="selectOption('CalendarioAgendamentoGrupoPage', 'GARAGEM - CALENDÁRIO AGENDAMENTO POR GRUPO')">
                                <span>Calendário Agendamentos por Grupo</span>
                            </li>
                            <li @click="selectOption('OsExecutadasTabPage', 'GARAGEM - SERVIÇOS EXECUTADOS')">
                                <span>Serviços Executados</span>
                            </li>
                            <li @click="selectOption('OsMapasPage', 'MAPA DE SERVIÇOS')">
                                <span>Visualização de Mapas</span>
                            </li>
                        </ul>
                    </div>
                </li>

                <li @mouseover="showFinanceiroMenu = true" @mouseleave="showFinanceiroMenu = false">
                    <img src="@/assets/icons/menuMoney.svg" />
                    <span>Financeiro</span>
                    <div v-if="showFinanceiroMenu" class="submenu" :class="{ 'relative': isCollapsed }">
                        <ul>
                            <li @click="selectOption('OsLiberarCobrancaPage', 'OS - AUTORIZAR EMISSÃO DE COBRANÇA')">
                                <span>Autorizar Emissão de Cobrança</span>
                            </li>
                            <li @click="selectOption('BoletosTabPage', 'BOLETOS EMITIDOS')">
                                <span>Consultar Boletos Emitidos</span>
                            </li>
                            <li @click="selectOption('CobrancasTabPage', 'COBRANÇAS')">
                                <span>Consultar Cobrancas</span>
                            </li>
                            <li @click="selectOption('CobrancaParcelasTabPage', 'COBRANÇA / PARCELAS')">
                                <span>Consultar Parcelas</span>
                            </li>
                            <li @click="selectOption('OsGerarCobrancaPage', 'GERAR COBRANÇA')">
                                <span>Emitir Cobrança</span>
                            </li>
                            <li @click="selectOption('CobrancaReportPage', 'RELATÓRIO DE COBRANÇA')">
                                <span>Relatório - Cobrança</span>
                            </li>
                            <li @click="selectOption('CobrancaRecebimentosReportPage', 'RELATÓRIO DE RECEBIMENTOS')">
                                <span>Relatório - Recebimentos</span>
                            </li>
                        </ul>
                    </div>
                </li>

                <li @mouseover="showRelatoriosMenu = true" @mouseleave="showRelatoriosMenu = false">
                    <img src="@/assets/icons/menuPrinter.svg" />
                    <span>Relatórios</span>
                    <div v-if="showRelatoriosMenu" class="submenu" :class="{ 'relative': isCollapsed }">
                        <ul>
                            <li @click="selectOption('EquipamentosExtratoReportPage', 'EQUIPAMENTOS - HISTÓRICO DE HORAS')">
                                <span>Equipamentos - Histórico de Horas</span>
                            </li>
                            <li @click="selectOption('OSAguardandoExecucaoReportPage', 'OS - AGUARDANDO EXECUÇÃO')">
                                <span>OS - Aguardando Execução</span>
                            </li>
                            <li @click="selectOption('OSExecutadasReportPage', 'OS - EXECUTADAS')">
                                <span>OS - Executadas</span>
                            </li>
                            <li @click="selectOption('OSInformativoReportPage', 'OS - INFORMATIVO DE EXECUÇÕES')">
                                <span>OS - Informativo de Execuções</span>
                            </li>
                        </ul>
                    </div>
                </li>

                <li @click="setComponenteAtivo('UsuarioViewPage', 'DADOS DO USUÁRIO')">
                    <img src="@/assets/icons/menuUser.svg" />
                    <span>Meus Dados</span>
                </li>
                
                <li @click="confirmLogout">
                    <img src="@/assets/icons/menuLogout.svg" />
                    <span>Desconectar</span>
                </li>

                <li @click="goHomeIP">
                    <img src="@/assets/icons/menuTransfer.svg" />
                    <span>Módulo I.P.</span>
                </li>
            </ul>
        </aside>

        <main class="content">
            <header class="header">
                <div class="header-title">{{ nmTitulo ? nmTitulo : 'GESTÃO AGRICULTURA' }}</div>
                <div style="align-items: center; display: flex;">
                    <div class="user-info">
                        <div class="user-name">{{ loginStore.userData.nome }}</div>
                        <div class="establishment-name">{{ loginStore.userData.nmmunicipio }}</div>
                    </div>
                    <div class="token-info">{{ timeRemaining }}</div>
                </div>
            </header>
            <div class="main-content">
                <slot>
                    <div v-if="nmComponenteAtivo=='BancoHorasTipoTabPage'"><BancoHorasTipoTabPage @close="setComponenteAtivo(null, null)"/></div>
                    <div v-else-if="nmComponenteAtivo=='CobrConveniosTabPage'"><CobrConveniosTabPage @close="setComponenteAtivo(null, null)"/></div>
                    <div v-else-if="nmComponenteAtivo=='DespesasGruposTabPage'"><DespesasGruposTabPage @close="setComponenteAtivo(null, null)"/></div>
                    <div v-else-if="nmComponenteAtivo=='EquipamentosGruposTabPage'"><EquipamentosGruposTabPage @close="setComponenteAtivo(null, null)"/></div>
                    <div v-else-if="nmComponenteAtivo=='EquipamentosSetoresTabPage'"><EquipamentosSetoresTabPage @close="setComponenteAtivo(null, null)"/></div>
                    <div v-else-if="nmComponenteAtivo=='EquipamentosTabPage'"><EquipamentosTabPage @close="setComponenteAtivo(null, null)"/></div>
                    <div v-else-if="nmComponenteAtivo=='LocalidadesTabPage'"><LocalidadesTabPage @close="setComponenteAtivo(null, null)"/></div>
                    <div v-else-if="nmComponenteAtivo=='MunicipioConfigEditPage'"><MunicipioConfigEditPage @close="setComponenteAtivo(null, null)"/></div>
                    <div v-else-if="nmComponenteAtivo=='OperadoresTabPage'"><OperadoresTabPage @close="setComponenteAtivo(null, null)"/></div>
                    <div v-else-if="nmComponenteAtivo=='OsBaixaHorimetroPage'"><OsBaixaHorimetroPage @close="setComponenteAtivo(null, null)"/></div>
                    <div v-else-if="nmComponenteAtivo=='OsAlteracaoPage'"><OsAlteracaoPage @close="setComponenteAtivo(null, null)"/></div>
                    <div v-else-if="nmComponenteAtivo=='OsCancelamentoPage'"><OsCancelamentoPage @close="setComponenteAtivo(null, null)"/></div>
                    <div v-else-if="nmComponenteAtivo=='OsEditPage'"><OsEditPage @close="setComponenteAtivo(null, null)"/></div>
                    <div v-else-if="nmComponenteAtivo=='OsExecutadasTabPage'"><OsExecutadasTabPage @close="setComponenteAtivo(null, null)"/></div>
                    <div v-else-if="nmComponenteAtivo=='OsMapasPage'"><OsMapasPage @close="setComponenteAtivo(null, null)"/></div>
                    <div v-else-if="nmComponenteAtivo=='OsSobrasAguardandoPage'"><OsSobrasAguardandoPage @close="setComponenteAtivo(null, null)"/></div>
                    <div v-else-if="nmComponenteAtivo=='OsSolicitacoesTabPage'"><OsSolicitacoesTabPage @close="setComponenteAtivo(null, null)"/></div>
                    <div v-else-if="nmComponenteAtivo=='OsTabPage'"><OsTabPage @close="setComponenteAtivo(null, null)"/></div>
                    <div v-else-if="nmComponenteAtivo=='PessoasTabPage'"><PessoasTabPage @close="setComponenteAtivo(null, null)"/></div>
                    <div v-else-if="nmComponenteAtivo=='ProdutosTabPage'"><ProdutosTabPage @close="setComponenteAtivo(null, null)"/></div>
                    <div v-else-if="nmComponenteAtivo=='RegioesTabPage'"><RegioesTabPage @close="setComponenteAtivo(null, null)"/></div>
                    <div v-else-if="nmComponenteAtivo=='ServicosTabPage'"><ServicosTabPage @close="setComponenteAtivo(null, null)"/></div>
                    <div v-else-if="nmComponenteAtivo=='UsuarioViewPage'"><UsuarioViewPage  :idView=loginStore.userData.idusuario @close="setComponenteAtivo(null, null)"/></div>

                    <div v-else-if="nmComponenteAtivo=='AgendaEquipamentoPage'"><AgendaEquipamentoPage @close="setComponenteAtivo(null, null)"/></div>
                    <div v-else-if="nmComponenteAtivo=='AgendaOperadorPage'"><AgendaOperadorPage @close="setComponenteAtivo(null, null)"/></div>
                    <div v-else-if="nmComponenteAtivo=='CalendarioAgendamentoGrupoPage'"><CalendarioAgendamentoGrupoPage @close="setComponenteAtivo(null, null)"/></div>
                    <div v-else-if="nmComponenteAtivo=='GaragemDemandaAgendamentoPage'"><GaragemDemandaAgendamentoPage @close="setComponenteAtivo(null, null)"/></div>

                    <div v-else-if="nmComponenteAtivo=='BoletosTabPage'"><BoletosTabPage @close="setComponenteAtivo(null, null)"/></div>
                    <div v-else-if="nmComponenteAtivo=='CobrancaParcelasTabPage'"><CobrancaParcelasTabPage @close="setComponenteAtivo(null, null)"/></div>
                    <div v-else-if="nmComponenteAtivo=='CobrancasTabPage'"><CobrancasTabPage @close="setComponenteAtivo(null, null)"/></div>
                    <div v-else-if="nmComponenteAtivo=='OsGerarCobrancaPage'"><OsGerarCobrancaPage @close="setComponenteAtivo(null, null)"/></div>
                    <div v-else-if="nmComponenteAtivo=='OsLiberarCobrancaPage'"><OsLiberarCobrancaPage @close="setComponenteAtivo(null, null)"/></div>

                    <div v-else-if="nmComponenteAtivo=='CobrancaRecebimentosReportPage'"><CobrancaRecebimentosReportPage @close="setComponenteAtivo(null, null)"/></div>
                    <div v-else-if="nmComponenteAtivo=='CobrancaReportPage'"><CobrancaReportPage @close="setComponenteAtivo(null, null)"/></div>
                    <div v-else-if="nmComponenteAtivo=='EquipamentosExtratoReportPage'"><EquipamentosExtratoReportPage @close="setComponenteAtivo(null, null)"/></div>
                    <div v-else-if="nmComponenteAtivo=='OSAguardandoExecucaoReportPage'"><OSAguardandoExecucaoReportPage @close="setComponenteAtivo(null, null)"/></div>
                    <div v-else-if="nmComponenteAtivo=='OSExecutadasReportPage'"><OSExecutadasReportPage @close="setComponenteAtivo(null, null)"/></div>
                    <div v-else-if="nmComponenteAtivo=='OSInformativoReportPage'"><OSInformativoReportPage @close="setComponenteAtivo(null, null)"/></div>

                    <div v-else-if="nmComponenteAtivo=='UsuariosTabPage'"><UsuariosTabPage @close="setComponenteAtivo(null, null)"/></div>
                    <div v-else><OSAgriculturaDash /></div>
                </slot>
            </div>
        </main>
    </div>
</template>

<script>
import { ref, onMounted, onBeforeUnmount } from 'vue';
import { jwtDecode } from 'jwt-decode';
import { useLoginStore } from '@/store/login';
import { useRouter } from 'vue-router';

import BancoHorasTipoTabPage from '../cadastros/tabelas/BancoHorasTipoTabPage.vue';
import CobrConveniosTabPage from '../cadastros/tabelas/CobrConveniosTabPage.vue';
import DespesasGruposTabPage from '../cadastros/tabelas/DespesasGruposTabPage.vue';
import EquipamentosGruposTabPage from '../cadastros/tabelas/EquipamentosGruposTabPage.vue';
import EquipamentosSetoresTabPage from '../cadastros/tabelas/EquipamentosSetoresTabPage.vue';
import EquipamentosTabPage from '../cadastros/tabelas/EquipamentosTabPage.vue';
import LocalidadesTabPage from '../cadastros/tabelas/LocalidadesTabPage.vue';
import MunicipioConfigEditPage from '../cadastros/formularios/MunicipioConfigEditPage.vue';
import OperadoresTabPage from '../cadastros/tabelas/OperadoresTabPage.vue';
import OsAlteracaoPage from '../os/OsAlteracaoPage.vue';
import OSAgriculturaDash from '../dashboard/OSAgriculturaDash.vue';
import OsBaixaHorimetroPage from '../os/OsBaixaHorimetroPage.vue';
import OsCancelamentoPage from '../os/OsCancelamentoPage.vue';
import OsEditPage from '../os/OsEditPage.vue';
import OsExecutadasTabPage from '../os/OsExecutadasTabPage.vue';
import OsMapasPage from '../os/OsMapasPage.vue';
import OsSobrasAguardandoPage from '../os/OsSobrasAguardandoPage.vue';
import OsSolicitacoesTabPage from '../os/OsSolicitacoesTabPage.vue';
import OsTabPage from '../os/OsTabPage.vue';
import PessoasTabPage from '../cadastros/tabelas/PessoasTabPage.vue';
import ProdutosTabPage from '../cadastros/tabelas/ProdutosTabPage.vue';
import RegioesTabPage from '../cadastros/tabelas/RegioesTabPage.vue';
import ServicosTabPage from '../cadastros/tabelas/ServicosTabPage.vue';
import UsuarioViewPage from '../cadastros/visualizacoes/UsuarioViewPage.vue';

import AgendaEquipamentoPage from '../os/AgendaEquipamentoPage.vue';
import AgendaOperadorPage from '../os/AgendaOperadorPage.vue';
import CalendarioAgendamentoGrupoPage from '../os/CalendarioAgendamentoGrupoPage.vue';
import GaragemDemandaAgendamentoPage from '../os/GaragemDemandaAgendamentoPage.vue';

// SUBMENU FINANCEIRO
import BoletosTabPage from '../financeiro/BoletosTabPage.vue';
import CobrancaParcelasTabPage from '../financeiro/CobrancaParcelasTabPage.vue';
import CobrancasTabPage from '../financeiro/CobrancasTabPage.vue';
import OsGerarCobrancaPage from '../financeiro/OsGerarCobrancaPage.vue';
import OsLiberarCobrancaPage from '../financeiro/OsLiberarCobrancaPage.vue';

import CobrancaRecebimentosReportPage from '../reports/CobrancaRecebimentosReportPage.vue';
import CobrancaReportPage from '../reports/CobrancaReportPage.vue';
import EquipamentosExtratoReportPage from '../reports/EquipamentosExtratoReportPage.vue';
import OSAguardandoExecucaoReportPage from '../reports/OSAguardandoExecucaoReportPage.vue';
import OSExecutadasReportPage from '../reports/OSExecutadasReportPage.vue';
import OSInformativoReportPage from '../reports/OSInformativoReportPage.vue';

import UsuariosTabPage from '../cadastros/tabelas/UsuariosTabPage.vue';
import { globalStore } from '@/globals';

export default {
    name: 'MainOs',
    components: {
        BancoHorasTipoTabPage,
        CobrConveniosTabPage,
        DespesasGruposTabPage,
        EquipamentosGruposTabPage,
        EquipamentosSetoresTabPage,
        EquipamentosTabPage,
        LocalidadesTabPage,
        MunicipioConfigEditPage,
        OperadoresTabPage,
        OsAlteracaoPage,
        OSAgriculturaDash,
        OsBaixaHorimetroPage,
        OsCancelamentoPage,
        OsEditPage,
        OsExecutadasTabPage,
        OsMapasPage,
        OsSobrasAguardandoPage,
        OsSolicitacoesTabPage,
        OsTabPage,
        PessoasTabPage,
        ProdutosTabPage,
        RegioesTabPage,
        ServicosTabPage,
        UsuarioViewPage,

        AgendaEquipamentoPage,
        AgendaOperadorPage,
        CalendarioAgendamentoGrupoPage,
        GaragemDemandaAgendamentoPage,

        BoletosTabPage,
        CobrancaParcelasTabPage,
        CobrancasTabPage,
        OsGerarCobrancaPage,
        OsLiberarCobrancaPage,

        CobrancaRecebimentosReportPage,
        CobrancaReportPage,
        EquipamentosExtratoReportPage,
        OSAguardandoExecucaoReportPage,
        OSExecutadasReportPage,
        OSInformativoReportPage,

        UsuariosTabPage
    },
    computed: {
        menuIcon() {
            return this.isCollapsed
                ? require('@/assets/icons/menuRight.svg')
                : require('@/assets/icons/menuLeft.svg');
        }
    },
    setup() {
        const ckLoginAdmin= ref(false);
        const isCollapsed = ref(true);
        const nmComponenteAtivo = ref(null);
        const nmTitulo = ref(null);
        const router = useRouter();
        const showCadastrosMenu = ref(false);
        const showFinanceiroMenu = ref(false);
        const showGaragemMenu = ref(false);
        const showRelatoriosMenu = ref(false);
        const showSettingsMenu = ref(false);
        const timeRemaining = ref(null);
        const loginStore = useLoginStore();
        let timer = null;

        const confirmLogout = () => {resetLogin();}

        const goHomeIP = () =>{
            router.push('/main-ip');
        }

        const resetLogin = () => {
            loginStore.clearLogin();
            router.push('/login');
        }

        const toggleMenu = () => { isCollapsed.value = !isCollapsed.value; }

        const selectOption = (page, titulo) => {
            nmComponenteAtivo.value   = page;
            nmTitulo.value            = titulo;
            showCadastrosMenu.value   = false; // Fecha o submenu após a seleção
            showFinanceiroMenu.value  = false;
            showGaragemMenu.value     = false;
            showRelatoriosMenu.value  = false;
        }

        const setComponenteAtivo = (nome, titulo) => {
          nmComponenteAtivo.value = nome;
          nmTitulo.value = titulo;
        }

        const updateTimeRemaining = () => {
            const token = localStorage.getItem('jwtToken');
            if (token){
                try{
                    const decodedToken = jwtDecode(token);
                    const expiration = decodedToken.exp * 1000;
                    const now = Date.now();
                    const difference = expiration - now;

                    if (difference > 0){
                        const hours         = Math.floor((difference / (1000 * 60 * 60)) % 24);
                        const minutes       = Math.floor((difference / (1000 * 60)) % 60);
                        const seconds       = Math.floor((difference / 1000) % 60);
                        const formattedTime = [
                            hours.toString().padStart(2, '0'),
                            minutes.toString().padStart(2, '0'),
                            seconds.toString().padStart(2, '0')
                        ].join(':');
                        timeRemaining.value = formattedTime;
                    }else{
                        timeRemaining.value = "Token expirado";
                        clearInterval(timer);
                        // TODO: REDIRECIONAR PARA TELA DE LOGIN OU EMITIR ALERTA E REATIVAR A SESSÃO
                    }
                }catch(error){
                    console.error('Erro ao decodificar o token:', error);
                    timeRemaining.value = "Erro ao verificar o token";
                    clearInterval(timer);
                }
            } else {
                timeRemaining.value = "Token não encontrado";
                clearInterval(timer);
                // TODO: REDIRECIONAR PARA TELA DE LOGIN OU EMITIR ALERTA E REATIVAR A SESSÃO
            }
        };

        onMounted(() => {
            updateTimeRemaining();
            timer = setInterval(updateTimeRemaining, 1000);
            ckLoginAdmin.value = globalStore.ckadmin==='S';
        });

        onBeforeUnmount(() => {
            clearInterval(timer);
        });

        return {
            ckLoginAdmin,
            isCollapsed,
            loginStore,
            nmComponenteAtivo,
            nmTitulo,
            showCadastrosMenu,
            showGaragemMenu,
            showRelatoriosMenu,
            showSettingsMenu,
            showFinanceiroMenu,
            timeRemaining,
            confirmLogout,
            goHomeIP,
            toggleMenu,
            selectOption,
            setComponenteAtivo
        };
    }
};
</script>

<style scoped src="@/assets/css/main.css"></style>
