<template>
    <div>
      <div id="map" style="height: 550px; width: 100%;"></div>
    </div>
  </template>
  
  <script>
  import L from 'leaflet';
  
  export default {
    props: {
      coordinates: {
        type: Array,
        required: true,
        validator(value) {
          return value.every(coord => 'latitude' in coord && 'longitude' in coord);
        }
      }
    },
    data() {
      return {
        map: null,
        markersGroup: null
      };
    },
    mounted() {
      if (this.coordinates.length === 0) {
        console.warn("Nenhuma coordenada fornecida.");
        return;
      }
      
      // Inicializa o mapa com a primeira coordenada
      const firstCoordinate = this.coordinates[0];
      this.map = L.map('map').setView([firstCoordinate.latitude, firstCoordinate.longitude], 12);
      
      L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
        maxZoom: 20,
      }).addTo(this.map);
      
      // Cria um layerGroup para os marcadores
      this.markersGroup = L.layerGroup().addTo(this.map);
      
      // Adiciona os marcadores iniciais
      this.addMarkers();
      
      // Recalcula o tamanho do mapa
      this.$nextTick(() => {
        setTimeout(() => {
          this.map.invalidateSize();
        }, 100);
      });
    },
    methods: {
      addMarkers() {
        // Remove os marcadores existentes
        if (this.markersGroup) {
          this.markersGroup.clearLayers();
        }
        
        const customIcon = L.icon({
          iconUrl: require('@/assets/icons/mapMarker.svg'),
          iconSize: [26, 26],
          iconAnchor: [10, 20],
          popupAnchor: [0, -38]
        });
        
        // Adiciona os marcadores com base no array atualizado
        this.coordinates.forEach(coord => {
          const marker = L.marker([coord.latitude, coord.longitude], { icon: customIcon });
          marker.bindPopup(`Latitude: ${coord.latitude}, Longitude: ${coord.longitude}`);
          marker.bindTooltip(`${coord.legenda}`, {
            permanent: true,
            direction: 'right',
            offset: [10, 0],
            className: 'custom-tooltip'
          });
          marker.addTo(this.markersGroup);
        });
      }
    },
    watch: {
      // Observa alterações no array de coordenadas (deep: true para detectar alterações em objetos internos)
      coordinates: {
        handler() {
          this.addMarkers();
        },
        deep: true
      }
    }
  };
  </script>
  
  <style scoped>
  .custom-tooltip {
    background-color: rgba(0, 0, 0, 0.7);
    color: #fff;
    border-radius: 4px;
    padding: 2px 6px;
    font-size: 12px;
    border: none;
  }
  
  .leaflet-tooltip {
    pointer-events: none;
  }
  </style>
  