<template>
    <div v-if="isLoading" class="loading-overlay"><p>Carregando...</p></div>


    <div v-show="true">
        <div class="form-edit-page row-space">
            <div class="row">
                <KeepAlive>
                    <div class="form-item">
                        <EquipamentoSelect class="form-item" :ckAddTodos="false" :flagTipo="1" v-model:equipamentoSelecionado="idEquipamento" />
                    </div>
                </KeepAlive>
                
                <button type="button" class="buttom-icon--search" @click="lerDados" />
                <button type="button" class="buttom-icon--close" @click="closeForm" />
            </div>
        </div>


        <MensagemAlerta class="row-space" v-if="errorMessage" :mensagem="errorMessage" />

        
        <table>
            <thead>
                <tr>
                    <th class="col-width-integer">OS</th>
                    <th class="col-width-date">Solicitado</th>
                    <th class="col-width-date">Agendado</th>
                    <th>Nome</th>
                    <th>Serviço</th>
                    <th class="col-width-date">Horas</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(obj, index) in lsDados" :key="obj.id" :class="{'even-row': index % 2 === 0, 'odd-row': index % 2 !== 0}">
                    <td class="col-width-integer right">{{ formatInteger(obj.cdos) }}</td>
                    <td class="col-width-date center">{{ formatDateFromJson(obj.dtinclusao) }}</td>
                    <td class="col-width-date center">{{ formatDateFromJson(obj.dtinicioprevisto) }}</td>
                    <td>{{ obj.nmpessoa }}</td>
                    <td>{{ obj.nmservico }}</td>
                    <td class="col-width-date center">{{ obj.hrcontratadas }}</td>
                </tr>
            </tbody>
        </table>
        

        <div v-if="showPagination" class="pagination">
            <button :disabled="currentPage === 1" class="buttom-pagination--first" @click="currentPage=1; updatePaginatedData();" />
            <button :disabled="currentPage === 1" class="buttom-pagination--prior" @click="currentPage--; updatePaginatedData();" />

            <div class="field-label field-label-center">{{ currentPage }} de {{ Math.ceil(lsDadosMain.length / itemsPerPage) }}</div>

            <button :disabled="currentPage === Math.ceil(lsDadosMain.length / itemsPerPage)" class="buttom-pagination--next" @click="currentPage++; updatePaginatedData();" />
            <button :disabled="currentPage === Math.ceil(lsDadosMain.length / itemsPerPage)" class="buttom-pagination--last" @click="currentPage = Math.ceil(lsDadosMain.length / itemsPerPage); updatePaginatedData();" />
        </div>
    </div>
</template>


<script>
import { ref } from 'vue';
import { formatDateFromJson, formatDecimal, formatInteger } from '@/funcoes/utils';
import { genericGet } from '@/funcoes/api'; 
import { globalStore } from '@/globals';
import EquipamentoSelect from '@/selects/EquipamentoSelect.vue';
import MensagemAlerta from '@/components/MensagemAlerta.vue';

export default{
    emits: ['close'],
    components:{ EquipamentoSelect, MensagemAlerta },

    setup(_, { emit }){
        const errorMessage = ref(null);
        const idEquipamento = ref(null);
        const isLoading = ref(false);
        const lsDados = ref([]);
        const lsDadosMain = ref([]);
        const showPagination = ref(false);

        const currentPage = ref(1);
        const itemsPerPage = ref(globalStore.itensPorPagina);

        const closeForm = () => {emit('close');}

        const lerDados = async() => {
            try{
                errorMessage.value = null;
                isLoading.value = true;
                const req = await genericGet('/os/agenda_equipamento', {idequipamento: idEquipamento.value});
                lsDadosMain.value = req;
                currentPage.value=1;
                showPagination.value = (lsDadosMain.value.length > itemsPerPage.value);
                updatePaginatedData();
            } catch (error){errorMessage.value=error;
            } finally {isLoading.value = false;}
        }

        const updatePaginatedData = () => {
            const startIndex = (currentPage.value - 1) * itemsPerPage.value;
            const endIndex = (startIndex + itemsPerPage.value);
            lsDados.value = lsDadosMain.value.slice(startIndex, endIndex);
        }
        
        return { 
            errorMessage, idEquipamento, isLoading, lsDados, lsDadosMain,
            currentPage, itemsPerPage, showPagination,
            closeForm, formatDateFromJson, formatDecimal, formatInteger, lerDados, updatePaginatedData };
    }
}
</script>
