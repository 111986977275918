<template>
    <div class="not-robot">
        <label>
            <input
                type="checkbox"
                :checked="modelValue"
                @change="updateValue"
            />
            <span class="checkmark"></span>
            <span class="label-text">Não sou robô</span>
        </label>
    </div>
</template>
  
<script>
export default {
    name: 'NotRobotCheck',
    props: {
        modelValue: {type: Boolean, required: true,},
    },
    emits: ['update:modelValue'],
    methods: {
        updateValue(event) {
            this.$emit('update:modelValue', event.target.checked);
        },
    },
};
</script>

<style scoped>
.not-robot {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-family: Arial, sans-serif;
}

label {
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
}

input[type='checkbox'] {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.checkmark {
    height: 20px;
    width: 20px;
    background-color: #e6e6e6;
    border: 1px solid #ccc;
    border-radius: 4px;
    display: inline-block;
    margin-right: 10px;
    position: relative;
}

input[type='checkbox']:checked ~ .checkmark {
    background-color: #007bff;
    border-color: #007bff;
}

input[type='checkbox']:checked ~ .checkmark::after {
    content: '';
    position: absolute;
    left: 6px;
    top: 3px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.label-text {
    font-size: 14px;
    color: #333;
}
</style>
