<template>
    <div class="form-group-scoped">
        <div class="form-item form-item-data">
            <label class="label-caption" for="startDate">Data Inicial</label>
            <input
                type="date"
                id="startDate"
                v-model="localStartDate"
                @change="onDateChange" />
        </div>
  
        <div class="form-item form-item-data">
            <label class="label-caption" for="endDate">Data Final</label>
            <input
                type="date"
                id="endDate"
                v-model="localEndDate"
                @change="onDateChange"/>
        </div>
  
        <div class="form-item" style="flex: 0 0 100px;">
            <select id="preset" v-model="localSelectedPeriod" @change="setDateRange">
                <option value="">Selecionar</option>
                <option
                    v-for="option in periodOptions"
                    :key="option.id"
                    :value="option.id"
                >{{ option.description }}</option>
            </select>
        </div>
    </div>
</template>
  
<script>
import { ref, watch } from "vue";

export default {
    name: "DateRangeSelect",
    props: {
        modelValue: {
            type: Object,
            required: true,
            default: () => ({ startDate: "", endDate: "", selectedPeriod: "" }),
        },
    },
    emits: ["update:modelValue"],
    setup(props, { emit }) {
        const periodOptions = ref([
            { id: "currentYear", description: "Ano Atual" },
            { id: "lastYear", description: "Ano Anterior" },
            { id: "currentMonth", description: "Mês Atual" },
            { id: "lastMonth", description: "Mês Anterior" },
        ]);
  
        const localStartDate = ref(props.modelValue.startDate);
        const localEndDate = ref(props.modelValue.endDate);
        const localSelectedPeriod = ref(props.modelValue.selectedPeriod);
  
        const setDateRange = () => {
            const today = new Date();
            const currentYear = today.getFullYear();
            const currentMonth = today.getMonth();
            let startDate, endDate;
      
            switch (localSelectedPeriod.value) {
                case "currentYear":{
                    startDate = `${currentYear}-01-01`;
                    endDate   = `${currentYear}-12-31`;
                    break;}
        
                case "lastYear":{
                    startDate = `${currentYear - 1}-01-01`;
                    endDate   = `${currentYear - 1}-12-31`;
                    break;}
        
                case "currentMonth":{
                    startDate = `${currentYear}-${String(currentMonth + 1).padStart(2, "0")}-01`;
                    endDate   = new Date(currentYear, currentMonth + 1, 0).toISOString().split("T")[0];
                    break;}
        
                case "lastMonth":{
                    const adjustedYear  = currentMonth === 0 ? currentYear - 1 : currentYear;
                    const adjustedMonth = currentMonth === 0 ? 12 : currentMonth;
                    startDate = `${adjustedYear}-${String(adjustedMonth).padStart(2, "0")}-01`;
                    endDate   = new Date(adjustedYear, adjustedMonth, 0).toISOString().split("T")[0];
                    break;}
        
                default:{
                    startDate = "";
                    endDate   = "";}
            }
      
            localStartDate.value = startDate;
            localEndDate.value   = endDate;
            updateParent();
        };

        const definirPeriodo = (periodo) => {
            localSelectedPeriod.value = periodo;
            setDateRange();
        };
  
        const onDateChange = () => {
            localSelectedPeriod.value = "";
            updateParent();
        };
  
        const updateParent = () => {
            emit("update:modelValue", {
                startDate: localStartDate.value,
                endDate: localEndDate.value,
                selectedPeriod: localSelectedPeriod.value,
            });
        };
  
        watch(
            () => props.modelValue,
            (newValue) => {
                localStartDate.value = newValue.startDate;
                localEndDate.value = newValue.endDate;
                localSelectedPeriod.value = newValue.selectedPeriod;
            }
        );
    
        return {
            localStartDate,
            localEndDate,
            localSelectedPeriod,
            periodOptions,
            setDateRange,
            onDateChange,
            definirPeriodo,
        };
    },
};
</script>  
  
<style scoped>
.form-group-scoped {
    align-items: end;
    display: flex;
    justify-content: space-between;
    gap: 10px;
    flex: 1;
}
</style>  