<template>
    <div class="form-item">
        <label class="label-caption">Grupo Equipamento</label>
        <select :value="grupoSelecionado" @change="atualizarObj" class="form-control" v-bind="$attrs"> 
            <option v-if="isLoading" disabled>Carregando...</option>
            <option v-if="!lsDados || lsDados.length === 0" disabled>Nenhuma opção disponível</option>
            <option v-for="obj in lsDados" :key="obj.id" :value="obj.id">{{ obj.nmgrupo }}</option>
        </select>
    </div>
</template>

<script>
import { ref, watch, onMounted } from "vue";
import { genericPost } from "@/funcoes/api";
import { globalStore } from "@/globals";

export default {
    props: {
        ckAddTodos:{type: Boolean, default: false},
        ckPesquisar: {type: Boolean, default: true},
        ckStatus: {type: Boolean, default: false},
        grupoSelecionado: {type: Number, default: null,},
    },
    setup(props, { emit }) {
        const lsDados = ref(null);
        const isLoading = ref(false);

        const fetchData = async () => {
            try {
                isLoading.value=true;
                const endPoint = props.ckStatus ? '/listas/equipamentosgrupo/status' : '/listas/equipamentosgrupo';
                const response = await genericPost(endPoint, {idmunicipio: globalStore.idmunicipio, ativo: 'S'});
                lsDados.value = response;
                if (props.ckAddTodos){lsDados.value.unshift({ id: 0, nmgrupo: 'TODOS' });}
            } catch (error) {console.error("Erro de conexão com a API:", error);
            }finally{isLoading.value=false;}
        };

        const atualizarObj = (event) => {
            const selectedId = Number(event.target.value);
            emit("update:grupoSelecionado", selectedId);

            const selectedObj = lsDados.value.find((obj) => obj.id === selectedId);
            if (selectedObj) {emit("update:grupoSelecionadoObj", selectedObj);}
        };

        onMounted(() => {
            if (props.ckPesquisar){
                fetchData();
            }
        });

        watch(
            () => props.ckPesquisar,
            (newVal, oldVal) => {
                if (newVal !== oldVal || newVal) {
                    fetchData();
                }
            }
        );

        return {isLoading, lsDados, atualizarObj};
    },
};
</script>
  