import { reactive } from 'vue';

export const criarIpServico = () => {
    return reactive({
        id: 0,
        idmunicipio: 0,
        cdipservico: 0,
        nmipservico: '',
        detalhamento: '',
        ativo: 'S',
        ckcidadao: 'N',
        ckprestador: 'N',
        idusuario: 0
    });
};