import { reactive } from 'vue';

export const criarBancoHrTpMovimento = () => {
    return reactive({
        id: 0,
        idmunicipio: 0,
        cdtpmovimento: 0,
        nmtpmovimento: '',
        obs: '',
        ativo: 'S',
        osvinculada: 'N',
        idusuario: 0
    });
};