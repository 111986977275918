import { reactive } from 'vue';

export const criarFornecedor = () => {
    return reactive({
        id: 0,
        idmunicipio: 0,
        cdfornecedor: 0,
        nmfornecedor: '',
        cpfcnpj: '',
        cpfcnpjnros: '',
        ie: 0,
        endereco: '',
        nmendereco: 0,
        complemento: '',
        bairro: '',
        nmmunicipio: '',
        uf: '',
        cep: '',
        fone: '',
        email: '',
        nmcontato: '',
        ativo: 'S',
        ckipprestador: 'N',
        idusuario: 0
    });
};