<template>
    <div class="form-item">
        <label class="label-caption">Grupo de Despesa</label>
        <select :value="grupoSelecionado" @change="atualizarObj" class="form-control" v-bind="$attrs"> 
            <option v-if="!lsDados || lsDados.length === 0" disabled>Carregando...</option>
            <option v-for="obj in lsDados" :key="obj.id" :value="obj.id">{{ obj.nmdespesagrupo }}</option>
        </select>
    </div>
</template>

<script>
import { ref, watch, onMounted } from "vue";
import { genericPost } from "@/funcoes/api";
import { globalStore } from "@/globals";

export default {
    props: {
        lsDadosProp: {type: Array, default: () => [],},
        grupoSelecionado: {type: Number, default: null,},
    },
    setup(props, { emit }) {
        const lsDados = ref(props.lsDadosProp);

        const fetchData = async () => {
            try {
                const response = await genericPost('/listas/despesas_grupo', {idmunicipio: globalStore.idmunicipio, ativo: 'S'});
                lsDados.value = response;
            } catch (error) {console.error("Erro de conexão com a API:", error);}
        };

        const atualizarObj = (event) => {
            emit("update:grupoSelecionado", Number(event.target.value));
        };

        onMounted(() => {
            if (!props.lsDadosProp || props.lsDadosProp.length === 0) {
                fetchData();
            }
        });

        watch(
            () => props.lsDadosProp,
            (newVal) => {lsDados.value = newVal;}
        );

        return {lsDados, atualizarObj};
    },
};
</script>
  