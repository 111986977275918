<template>
    <div v-if="isLoading" class="loading-overlay"><p>Carregando...</p></div>

    <div class="form-edit-page">
        <div v-if="lsDados">
            <MapArrayComponent class="map" :coordinates="lsDados"></MapArrayComponent>
        </div>
    </div>
    <div class="row-space"/>    
</template>


<script>
import { onMounted, ref } from 'vue';
import { genericPost } from '@/funcoes/api'; 
import { globalStore } from '@/globals';
import MapArrayComponent from '@/components/MapArrayComponent.vue';

export default{
    components:{MapArrayComponent},

    setup(){
        const isLoading = ref(false);
        const lsDados = ref(null);

        const lerDados = async() => {
            try{
                isLoading.value = true;
                const req = await genericPost('/ip/ipos/coordenadas', {
                    idmunicipio: globalStore.idmunicipio,
                    intparam1: 0});
                lsDados.value = req;
            } finally {isLoading.value = false;}
        }
        
        onMounted(()=>{
            lerDados();
        })

        return { isLoading, lsDados };
    }
}
</script>