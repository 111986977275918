<template>
    <div v-if="isLoading" class="loading-overlay"><p>Carregando...</p></div>


    <div v-show="!modoVisualizacao && !modoEdicao">
        <div class="form-edit-page" style="display: flex; gap: 10px; align-items: end;">
            <div class="form-item-rangedata" ><DateRangeSelect v-model="dateRange" ref="dateRangeSelect" /></div>
            
            <EquipamentoGrupoSelect :ckAddTodos="true" v-model:grupoSelecionado="idGrupo" required />
            <OsSolicitacaoStatusSelect v-model:statusSelecionado="statusSelecionado" />
            
            <button type="button" class="buttom-icon--search" @click="lerDados" />
            <button type="button" class="buttom-icon--add" @click="novoCadastro" />
            <button type="button" class="buttom-icon--printer" @click="imprimir" />
            <button type="button" class="buttom-icon--home" @click="closeForm" />
        </div>

        <MensagemAlerta class="margin-top10" v-if="errorMessage" :mensagem="errorMessage" />
        
        <table class="margin-top10">
            <thead>
                <tr>
                    <th class="col-width-integer">Código</th>
                    <th class="col-width-date">Data</th>
                    <th class="col-width-date">Horas</th>
                    <th>Nome</th>
                    <th>Localidade</th>
                    <th>Serviço</th>
                    <th class="col-width-medium">Situação</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(obj, index) in lsDados" :key="obj.id" :class="{'even-row': index % 2 === 0, 'odd-row': index % 2 !== 0, 'clickabe-row': true}" @click="visualizarIpOS(obj)">
                    <td class="col-width-integer right">{{ obj.cdsolicitacao }}</td>
                    <td class="col-width-date center">{{ formatDateDMY(obj.dtsolicitacao) }}</td>
                    <td class="col-width-date center">{{ formatTimeFromJson(obj.hrsolicitadas) }}</td>
                    <td>{{ obj.nmpessoa }}</td>
                    <td>{{ obj.nmlocalidade }}</td>
                    <td>{{ obj.nmservico }}</td>
                    <td class="col-width-medium">{{ obj.xsituacao }}</td>
                </tr>
            </tbody>
        </table>
    </div>


    <div v-if="modoEdicao">
        <OsSolicitacaoEditPage :idEdicao="idEdicao" @close="modoEdicao = false" @save="lerDados" />
    </div>


    <div v-if="modoVisualizacao">
        <OSSolicitacaoViewPage :idView="idView" @close="modoVisualizacao = false" @save="lerDados" />
    </div>
</template>


<script>
import { onMounted, ref } from 'vue';
import { formatDateFromJson, formatTimeFromJson } from '@/funcoes/utils';
import { genericPost, genericReport } from '@/funcoes/api'; 
import { globalStore } from '@/globals';
import DateRangeSelect from '@/components/DateRangeSelect.vue';
import EquipamentoGrupoSelect from '@/selects/EquipamentoGrupoSelect.vue';
import MensagemAlerta from '@/components/MensagemAlerta.vue';
import OsSolicitacaoEditPage from './OsSolicitacaoEditPage.vue';
import OsSolicitacaoStatusSelect from '@/selects/estaticos/OsSolicitacaoStatusSelect.vue';
import OSSolicitacaoViewPage from './OSSolicitacaoViewPage.vue';

export default{
    emits: ['close'],
    components:{DateRangeSelect, EquipamentoGrupoSelect, MensagemAlerta, OsSolicitacaoEditPage, OsSolicitacaoStatusSelect, OSSolicitacaoViewPage},

    setup(_, { emit }){
        const dateRange = ref({ startDate: "", endDate: "", selectedPeriod: "" });
        const dateRangeSelect = ref(null);
        const errorMessage = ref(null);
        const idEdicao = ref(0);
        const idGrupo = ref(0);
        const idView = ref(0);
        const isLoading = ref(false);
        const lsDados = ref([]);
        const modoEdicao = ref(false);
        const modoVisualizacao = ref(false);
        const nmPesquisa = ref(null);
        const statusSelecionado = ref(null);

        const closeForm = () => {emit('close');}

        const imprimir = async() => {
            try{
                if (!dateRange.value.startDate || !dateRange.value.endDate){
                    throw new Error('Necessário informar ambas as datas.');
                }
                const dataInicio = new Date(dateRange.value.startDate);
                const dataFim = new Date(dateRange.value.endDate);

                if (isNaN(dataInicio.getTime()) || isNaN(dataFim.getTime())){
                    throw new Error('Datas inválidas. Verifique o formato das datas.');
                }

                if (dataFim < dataInicio){
                    throw new Error('A data final deve ser maior ou igual à data inicial.');
                }

                errorMessage.value = null;
                isLoading.value = true;
                const dados = {
                    nmrelatorio: 'ipossolicitacoes',
                    idmunicipio: globalStore.idmunicipio,
                    idusuario: globalStore.idusuario,
                    datainicial: dateRange.value.startDate,
                    datafinal: dateRange.value.endDate,
                    intp1: statusSelecionado.value};
                const response = await genericReport(dados);
                const pdfBlob = new Blob([response], { type: 'application/pdf' });
                const pdfUrl = URL.createObjectURL(pdfBlob);
                window.open(pdfUrl, '_blank');
            } catch (error){errorMessage.value = `ATENÇÃO: ${error.message}`;
            } finally {isLoading.value = false;}
        }

        const lerDados = async() => {
            if ((!dateRange.value.startDate) || (!dateRange.value.endDate)){
                errorMessage.value = 'Informe corretamente um período';
                return;
            }
            try{
                errorMessage.value = null;
                isLoading.value = true;
                const req = await genericPost('/listas/ossolicitacoes', {
                    idmunicipio: globalStore.idmunicipio,
                    idequipamentogrupo: idGrupo.value,
                    datainicial: dateRange.value.startDate, 
                    datafinal:dateRange.value.endDate,
                    intparam1: statusSelecionado.value});
                lsDados.value = req;
            } catch (error){errorMessage.value=error;
            } finally {isLoading.value = false;}
        }

        const novoCadastro = () => {
            idEdicao.value = 0;
            modoEdicao.value = true;
        }

        const visualizarIpOS = (obj) =>{
            idView.value = obj.id;
            modoVisualizacao.value = true;
        }

        onMounted(() => {
            dateRangeSelect.value.definirPeriodo("currentMonth");
            lerDados();
        });

        return { dateRange, dateRangeSelect, errorMessage, idEdicao,idGrupo, isLoading, idView, lsDados, modoEdicao, modoVisualizacao, nmPesquisa, statusSelecionado,
            closeForm, formatDateDMY: formatDateFromJson, formatTimeFromJson, imprimir, lerDados, novoCadastro, visualizarIpOS };
    }
}
</script>
