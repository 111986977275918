<template>
    <div v-if="showEdicao">
        <OsEditPage :idEdicao="idEdicao" @close="showEdicao=false; showOSSelect=true" />
    </div>

    <div v-if="showOSSelect">
        <OsPesquisaPage
            :idSituacao="1"
            :idSituacao2="2"
            @cancel="cancelForm"
            @select="selecionarOS" />
    </div>
</template>

<script>
import { ref } from 'vue';
import OsEditPage from './OsEditPage.vue';
import OsPesquisaPage from './OsPesquisaPage.vue';

export default{
    components: {OsEditPage, OsPesquisaPage},
    emits: ['close', 'salvo'],
    setup(_, { emit }){
        const idEdicao = ref(null);
        const showEdicao = ref(false);
        const showOSSelect = ref(true);

        const cancelForm = () => {emit('close');};

        const selecionarOS = (os) => {
            showOSSelect.value      = false;
            idEdicao.value          = os.id;
            showEdicao.value=true;
        };

        return{
            idEdicao,
            showEdicao,
            showOSSelect,
            cancelForm,
            selecionarOS };
    }
}
</script>