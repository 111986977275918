<template>
    <div class="form-item">
        <label class="label-caption">Tipo Medição</label>
        <select :value="tipoSelecionado" @change="atualizarTipo" class="form-control" v-bind="$attrs">
            <option v-for="tipo in lsTipos" :key="tipo.id" :value="tipo.id">{{ tipo.descricao }}</option>
        </select>
    </div>
</template>
  
<script>
export default {
    props: {
        tipoSelecionado: {type: String, default: null}
    },

    data() {
        return{
            lsTipos:[
                {id: '0', descricao: 'NENHUM'},
                {id: '1', descricao: 'KM'},
                {id: '2', descricao: 'HORAS'}
            ]
        };
    },

    methods: {
        atualizarTipo(event) {
            this.$emit('update:tipoSelecionado', String(event.target.value));
        }
    }
};
</script>