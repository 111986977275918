<template>
    <div class="form-item">
        <label class="label-caption">{{ descCaption }}</label>
        <select :value="fornecedorSelecionado" @change="atualizarObj" class="form-control" v-bind="$attrs"> 
            <option v-if="!lsDados || lsDados.length === 0" disabled>Carregando...</option>
            <option v-for="obj in lsDados" :key="obj.id" :value="obj.id">{{ obj.nmfornecedor }}</option>
        </select>
    </div>
</template>

<script>
import { ref, watch, onMounted } from "vue";
import { genericPost } from "@/funcoes/api";
import { globalStore } from "@/globals";

export default {
    props: {
        ckAddTodos: {type: Boolean, default: false},
        ckIpPrestador: {type: Boolean, default: false},
        descCaption: {type: String, default: 'Fornecedor'},
        flagTipo: {type: Number, default: 0},
        lsDadosProp: {type: Array, default: () => [],},
        fornecedorSelecionado: {type: Number, default: null,},
    },
    setup(props, { emit }) {
        const lsDados = ref(props.lsDadosProp);

        const fetchData = async () => {
            try {
                const response = await genericPost('/listas/fornecedores', {
                    idmunicipio: globalStore.idmunicipio, 
                    ativo: 'S',
                    intparam1: props.ckIpPrestador ? 1 : 0});
                lsDados.value = response;
                if (props.ckAddTodos){lsDados.value.unshift({ id: 0, nmfornecedor: 'TODOS' });}
            } catch (error) {console.error("Erro de conexão com a API:", error);}
        };

        const atualizarObj = (event) => {
            const selectedId = Number(event.target.value);
            emit("update:fornecedorSelecionado", selectedId);

            const selectedObj = lsDados.value.find((obj) => obj.id === selectedId);
            if (selectedObj) {emit("update:fornecedorSelecionadoObj", selectedObj);}
        };

        onMounted(() => {
            if ((!props.lsDadosProp || props.lsDadosProp.length === 0)) {
                fetchData();
            }
        });

        watch(
            () => props.lsDadosProp,
            (newVal) => {lsDados.value = newVal;}
        );

        return {lsDados, atualizarObj};
    },
};
</script>
  