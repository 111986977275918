<template>
    <div v-if="isLoading" class="loading-overlay"><p>Carregando...</p></div>

    <div class="form-edit-page">
        <form @submit.prevent="submitForm">
            <div class="row row-space">
                <div class="form-item" style="flex: 0 0 90px;">
                    <label class="label-caption">Código</label>
                    <div class="field-label field-label-end">{{ objEdicao.cdoperador }}</div>
                </div>                
                <div class="form-item flexible">
                    <label class="label-caption" for="nmoperador">Nome</label>
                    <input type="text" v-model="objEdicao.nmoperador" id="nmoperador" required maxlength="80" v-uppercase />
                </div>
                <div class="form-item flexible">
                    <label class="label-caption" for="apelido">Apelido</label>
                    <input type="text" v-model="objEdicao.apelido_operador" id="apelido" maxlength="30" v-uppercase />
                </div>
            </div>


            <div class="row row-space">
                <div class="form-item" style="flex: 0 0 160px;">
                    <label class="label-caption" for="cpf">CPF</label>
                    <input type="text" class="input-center" v-model="objEdicao.cpf" id="cpf" maxlength="14" />
                </div>

                <div class="form-item" style="flex: 0 0 160px;">
                    <label class="label-caption" for="fone">Telefone</label>
                    <input type="text" v-model="objEdicao.fone" id="fone" v-phone maxlength="15" />
                </div>

                <div class="form-item-char20">
                    <label class="label-caption" for="fone">Login</label>
                    <input type="text" v-model="objEdicao.login" id="fone" maxlength="20" />
                </div>

                <div class="form-item-char20">
                    <label class="label-caption" for="fone">Senha</label>
                    <input type="password" v-model="objEdicao.senha" id="fone" maxlength="20" />
                </div>

                <div class="form-item flexible">
                    <label class="label-caption" for="email">Email</label>
                    <input type="email" v-model="objEdicao.email" id="email" />
                </div>
            </div>


            <div class="row row-space">
                <EquipamentoSelect class="form-item flexible" flagTipo="1" v-model:equipamentoSelecionado="objEdicao.idequipamento" />
            </div>

            <div class="row-end">
                <label for="ckAtivo">Ativo</label>
                <input type="checkbox" class="input-checkbox" id="ckAtivo" v-model="ckAtivo" />
            </div>

            <div class="row-end">
                <label for="ckAbreOs">Permite abertura de OS</label>
                <input type="checkbox" class="input-checkbox" id="ckAbreOs" v-model="ckAbreOs" />
            </div>

            <div class="row-end">
                <label for="ckTrocaEquipamento">Permite troca de equipamento</label>
                <input type="checkbox" class="input-checkbox" id="ckTrocaEquipamento" v-model="ckTrocaEquipamento" />
            </div>

            <div class="row-end">
                <label for="ckTerceirizado">Terceirizado</label>
                <input type="checkbox" class="input-checkbox" id="ckTerceirizado" v-model="ckTerceirizado" />
            </div>


            <div class="row-end margin-top10">
                <button class="btn-confirm" type="submit">Salvar</button>
                <button class="btn-cancel" type="cancel" @click="cancelForm">Cancelar</button>
            </div>
        </form>
    </div>
</template>
  
<script>
import { ref } from 'vue';
import { criarOperador } from '@/models/Operador';
import { genericPut, getCadastroById } from '@/funcoes/api';
import { globalStore } from '@/globals';
import EquipamentoSelect from '@/selects/EquipamentoSelect.vue';

export default {
    components:{EquipamentoSelect},
    emits: ['close', 'salvo'],
    props: {idEdicao: {type: Number, default: 0}},
    setup(props, { emit }) {
        const ckAbreOs = ref(false);
        const ckAtivo = ref(false);
        const ckTerceirizado = ref(false);
        const ckTrocaEquipamento = ref(false);
        const objEdicao = criarOperador();
        const isLoading = ref(false);

        const submitForm = async () => {
            try {
                isLoading.value=true;
                objEdicao.ativo = ckAtivo.value ? 'S' : 'N';
                objEdicao.blterceirizado = ckTerceirizado.value ? 'S' : 'N';
                objEdicao.bltroca_equipamento = ckTrocaEquipamento.value ? 'S' : 'N';
                objEdicao.ckabreos = ckAbreOs.value ? 'S' : 'N';
                const response = await genericPut('/cad/operador', objEdicao);
                console.log('Success', response);
                emit('salvo');
                emit('close');
            } catch (error) {console.error('Error:', error);
            } finally {isLoading.value=false;}
        };

        const cancelForm = () => {emit('close');};

        const lerDados = async() => {
            try{
                isLoading.value=true;

                if (props.idEdicao > 0) {
                    try{
                        const req = await getCadastroById('operador', props.idEdicao);
                        Object.assign(objEdicao, req[0]);
                    }catch (error){console.error('Erro leitura dos dados', error);}
                }else{
                    objEdicao.idmunicipio = globalStore.idmunicipio;
                }
                objEdicao.idusuario       = globalStore.idusuario;
                ckAbreOs.value            = objEdicao.ckabreos==='S';
                ckAtivo.value             = objEdicao.ativo==='S';
                ckTerceirizado.value      = objEdicao.blterceirizado==='S';
                ckTrocaEquipamento.value  = objEdicao.bltroca_equipamento==='S';
            }finally{isLoading.value=false;}
        };

        lerDados();

        return { ckAbreOs, ckAtivo, ckTerceirizado, ckTrocaEquipamento, isLoading, objEdicao, submitForm, cancelForm };
    }
};
</script>