<template>
    <div class="form-container">
        <div class="painel">
            <img src="@/assets/imolgaro.png" width="250px">
            <div></div>
            <h2 style="margin-top: 30px;">SISTEMA DE GESTÃO PARA ORDENS DE SERVIÇO E ADMINISTRAÇÃO MUNICIPAL</h2>
        
            <div style="margin-top: 30px;">
                <form>
                    <div class="input-group">
                        <img src="@/assets/icons/mail.svg" alt="E-mail" class="icon" />
                        <input type="text" placeholder="Usuário/Email" v-model="credenciais.email" />
                    </div>

                    <div class="input-group">
                        <img src="@/assets/icons/pass.svg" alt="Senha" class="icon" />
                        <input type="password" placeholder="Senha" v-model="credenciais.passWord" />
                    </div>

                    <div><NotRobotCheck v-model="notRobot" /></div>
                </form>

                <div class="row-start">
                    <button @click="loginNew" class="login-button" :disabled="isLoading" style="margin-top: 20px;">
                        {{ isLoading ? 'Autenticando...' : 'Acessar' }}
                    </button>
                </div><br>

                <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>
            </div>
        </div>
    </div>
</template>


<script>
import { reactive, ref } from 'vue';
import { genericPost, getCadastroById, loginAPI } from '@/funcoes/api'; 
import { globalStore } from '@/globals';
import { useLoginStore } from '@/store/login';
import { useRouter } from 'vue-router';
import NotRobotCheck from '@/components/NotRobotCheck.vue';

export default {
    name: 'LoginPage',
    components:{ NotRobotCheck },

    setup() {
        const loginStore = useLoginStore();
        const credenciais = reactive({email: '', passWord: ''});
        const isLoading = ref(false);
        const errorMessage = ref('');
        const notRobot = ref(false);
        const router = useRouter();

        const loginNew = async() => {
            if ((!credenciais.email) || (!credenciais.passWord)){
                alert('Informe o email e senha');
                return;
            }
            if (!notRobot.value) {
                alert('Por favor, confirme que você não é um robô.');
                return;
            }
            try{
                isLoading.value = true;
                errorMessage.value = "";
                const dados = { appname: 'WEB', appver: '1.25.0214', login: credenciais.email, senha: credenciais.passWord };
                const loginSuccess = await loginAPI(credenciais.email, credenciais.passWord, dados);
                if (loginSuccess){
                    const req = await genericPost('/main/registrarlogin', dados);
                    if (req){
                        const usuario = await getCadastroById('usuario', req.idusuario);
                        if (usuario){
                            globalStore.ckadmin       = usuario[0].ckadmin;
                            globalStore.ckipprestador = usuario[0].ckipprestador;
                        }
                        globalStore.idlogin       = req.id;
                        globalStore.idipprestador = req.idfornecedor;
                        globalStore.idmunicipio   = req.idmunicipio;
                        globalStore.idusuario     = req.idusuario;
                        globalStore.nmusuario     = req.nmusuario;
                        globalStore.nmmunicipio   = req.nmmunicipio;
                        globalStore.nmipprestador = req.nmfornecedor;

                        // SEMPRE ALTERAR DEFINIÇÕES NA OPÇÃO DE TROCAR DE MUNICÍPIO NA VIEW DO USUÁRIO
                        const config = await getCadastroById('municipio_config', req.idmunicipio);
                        if (config){
                            globalStore.ckpermitir_parcelar = config[0].ckpermitir_parcelar;
                            globalStore.cobr_prazo_inicial  = config[0].cobr_prazo_inicial;
                            globalStore.latitude            = config[0].latitude;
                            globalStore.longitude           = config[0].longitude;
                        }

                        loginStore.setLogin(req);
                        if (req.idfornecedor){router.push('/main-ipprestador');}else{router.push('/main-os');}
                    }
                }else{errorMessage.value = "Login não autorizado"}        
            } catch (error){console.error('Erro leitura dos dados', error);
            } finally {isLoading.value = false;}
        }

        return { credenciais, isLoading, errorMessage, notRobot, loginNew };
    }
}
</script>

<style scoped>
h2{
    color: navy;
}

.form-container{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #d8d5d8;
}

.icon {
    width: 32px;
    height: 32px;
    margin-right: 10px;
}

.input-group {
  position: relative; /* Necessário para posicionar o ícone dentro do input */
  margin-bottom: 15px;
}

.input-group input {
    width: 100%;
    padding: 10px 40px; /* Espaçamento interno para acomodar o ícone */
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    background: #f9f9f9;
}

.input-group input:focus {
    outline: none;
    border-color: #007bff;
    background: #fff;
}

.input-group .icon {
    position: absolute;
    left: 10px; /* Posiciona o ícone no lado esquerdo do input */
    top: 50%;
    transform: translateY(-50%); /* Centraliza verticalmente */
    width: 20px;
    height: 20px;
    pointer-events: none; /* Permite clicar no input sem interferência do ícone */
}

.login-button {
    padding: 5px 30px;
    font-size: 16px;
    color: white;
    background-color: navy;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}
  
.login-button:hover {
    background-color: #0056b3;
}

.painel{
    border-color: #7c8a6e;
    border-style: solid;
    border-radius: 5%;
    border-width: 4px;
    max-width: 500px;
    width: 100%;
    padding: 20px 60px;
    background: #f2eef2;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.error-message {
    color: red;
    font-weight: bold;
    margin-top: 10px;
}
</style>