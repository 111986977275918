<template>
    <div v-if="isLoading" class="loading-overlay"><p>Carregando...</p></div>

    <div class="form-edit-page">
        <form @submit.prevent="submitForm">
            <div class="row row-space">
                <div class="form-item" style="flex: 0 0 90px;">
                    <label class="label-caption">Código</label>
                    <div class="field-label field-label-end">{{ objEdicao.cdequipamentogrupo }}</div>
                </div>
                
                <div class="form-item flexible">
                    <label class="label-caption" for="nmgrupo">Nome</label>
                    <input type="text" v-model="objEdicao.nmgrupo" id="nmgrupo" v-uppercase required maxlength="100" />
                </div>

                <TipoGrupoEquipamento class="form-item flexible"
                    v-model:tipoSelecionado="objEdicao.tipo" />
            </div>


            <div class="row row-space">
                <div class="form-item" style="flex: 0 0 150px;">
                    <label class="label-caption">Limite Horas</label>
                    <input type="text" class="input-right" v-model="edLimiteHoras" v-number maxlength="5" />
                </div>

                <div class="form-item">
                    <label class="label-caption">Valor Hora</label>
                    <input v-model="edVlHora" v-decimal-mask type="text" class="input-right" />
                </div>

                <div class="form-item" style="flex: 0 0 140px;">
                    <label class="label-caption">Valor Taxado</label>
                    <input v-model="edVlHoraTaxado" v-decimal-mask type="text" class="input-right" />
                </div>

                <div class="form-item" style="flex: 0 0 150px;">
                    <label class="label-caption">Min Faixa 2</label>
                    <input type="text" class="input-right" v-model="edMnFaixa2" v-number maxlength="5" />
                </div>

                <div class="form-item">
                    <label class="label-caption">Valor 2</label>
                    <input v-model="edVlHora2" v-decimal-mask type="text" class="input-right" />
                </div>

                <div class="form-item" style="flex: 0 0 150px;">
                    <label class="label-caption">Min Faixa 3</label>
                    <input type="text" class="input-right" v-model="edMnFaixa3" v-number maxlength="5" />
                </div>

                <div class="form-item">
                    <label class="label-caption">Valor 3</label>
                    <input v-model="edVlHora3" v-decimal-mask type="text" class="input-right" />
                </div>

                <div class="form-item" style="flex: 0 0 150px;">
                    <label class="label-caption">Alíq. UFM</label>
                    <input type="text" class="input-right" v-model="edAlUfm" v-number maxlength="5" />
                </div>
            </div>

            <div class="row-end">
                <label>Ativo</label>
                <input type="checkbox" class="input-checkbox" v-model="ckAtivo" />
            </div>


            <div class="row-end">
                <label>Agenda Pública</label>
                <input type="checkbox" class="input-checkbox" v-model="ckAgendaPublica" />
            </div>


            <div class="row-end margin-top10">
                <button type="submit">Salvar</button>
                <button type="cancel" @click="cancelForm">Cancelar</button>
            </div>
        </form>
    </div>
</template>
  
<script>
import { computed, ref } from 'vue';
import { criarEquipamentoGrupo } from '@/models/EquipamentoGrupo';
import { decimalFromString, formatDecimal, formatInteger, integerFromString } from '@/funcoes/utils';
import { useLoginStore } from '@/store/login';
import { genericPut, getCadastroById } from '@/funcoes/api';
import TipoGrupoEquipamento from '@/selects/TipoGrupoEquipamento.vue';

export default {
    name: 'EquipamentoGrupoEditPage',
    components: { TipoGrupoEquipamento },
    emits: ['close', 'salvo'],
    props: {idEdicao: {type: Number, default: 0}},
    setup(props, { emit }) {
        const edAlUfm = ref(null);
        const edLimiteHoras = ref(null);
        const edMnFaixa2 = ref(null);
        const edMnFaixa3 = ref(null);
        const edVlHora = ref(null);
        const edVlHora2 = ref(null);
        const edVlHora3 = ref(null);
        const edVlHoraTaxado = ref(null);
        const objEdicao = criarEquipamentoGrupo();
        const isLoading = ref(false);
        const login = useLoginStore();

        const ckAgendaPublica = computed({
            get() {return objEdicao.agenda_publica === "S";},
            set(value) {objEdicao.agenda_publica = value ? "S" : "N";}
        });

        const ckAtivo = computed({
            get() {return objEdicao.ativo === "S";},
            set(value) {objEdicao.ativo = value ? "S" : "N";}
        });

        const submitForm = async () => {
            try {
                objEdicao.alufm         = decimalFromString(edAlUfm.value);
                objEdicao.limite_horas  = integerFromString(edLimiteHoras.value);
                objEdicao.mnfaixa2      = integerFromString(edMnFaixa2.value);
                objEdicao.mnfaixa3      = integerFromString(edMnFaixa3.value);
                objEdicao.vlhora        = decimalFromString(edVlHora.value);
                objEdicao.vlhora2       = decimalFromString(edVlHora2.value);
                objEdicao.vlhora3       = decimalFromString(edVlHora3.value);
                objEdicao.vlhora_taxado = decimalFromString(edVlHoraTaxado.value);
                const response = await genericPut('/cad/equipamentogrupo', objEdicao);
                if (response.bolok === 1){
                    emit('salvo');
                    emit('close');
                }else{
                    alert(response.erro);
                }
            } catch (error) {console.error('Error:', error);}
        };

        const cancelForm = () => {emit('close');};

        const lerDados = async() => {
            try{
                isLoading.value=true;
                if (props.idEdicao > 0) {
                    try{
                        const req = await getCadastroById('equipamentogrupo', props.idEdicao);
                        Object.assign(objEdicao, req[0]);
                        edMnFaixa2.value        = formatInteger(objEdicao.mnfaixa2);
                        edMnFaixa3.value        = formatInteger(objEdicao.mnfaixa3);
                        edVlHora.value          = formatDecimal(objEdicao.vlhora);
                        edVlHoraTaxado.value    = formatDecimal(objEdicao.vlhora_taxado);
                    }catch (error){console.error('Erro leitura dos dados', error);}
                }else{
                    objEdicao.idmunicipio = login.userData.idmunicipio;
                }
                objEdicao.idusuario = login.userData.id;
            }finally{isLoading.value=false;}
        };

        lerDados();

        return {
            edAlUfm, edLimiteHoras, edMnFaixa2, edMnFaixa3, edVlHora, edVlHora2, edVlHora3, edVlHoraTaxado,
            ckAgendaPublica, ckAtivo, isLoading, objEdicao, 
            submitForm, cancelForm };
    }
};
</script>