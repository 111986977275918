import { reactive } from 'vue';

export const criarEquipamentoGrupo = () => {
    return reactive({
        id: 0,
        idmunicipio: 0,
        cdequipamentogrupo: 0,
        nmgrupo: '',
        alufm: 0,
        vlhora: 0,
        vlhora_taxado: 0,
        mnfaixa2: 0,
        vlhora2: 0,
        mnfaixa3: 0,
        vlhora3: 0,
        limite_horas: 0,
        tipo: 0,
        tiponm: '',
        agenda_publica: 'N',
        ativo: 'S',
        idusuario: 0
    });
};