import { reactive } from 'vue';

export const criarDespesaGrupo = () => {
    return reactive({
        id: 0,
        idmunicipio: 0,
        cddespesagrupo: 0,
        nmdespesagrupo: '',
        ativo: 'S',
        cksoma_combustivel: 'N',
        idusuario: 0
    });
};