import { reactive } from 'vue';

export const criarCobrConvenio = () => {
    return reactive({
        id: 0,
        idmunicipio: 0,
        cdcobr_convenio: 0,
        identificacao: '',
        codigo_cedente: 0,
        convenio: 0,
        nmempresa: '',
        cpfcnpj: '',
        endereco: '',
        cep: '',
        banco: 0,
        banco_dv: '',
        nmbanco: '',
        carteira: 0,
        variacao: 0,
        agencia: 0,
        agencia_dv: '',
        conta: 0,
        conta_dv: '',
        boleto_inicial: 0,
        localpagamento: '',
        msg: '',
        ativo: '',
        almulta: 0,
        aljuros: 0,
        ckaceite_vencido: '',
        dias_protesto: 0,
        dias_baixa: 0,
        idusuario: 0
    });
};