<template>
    <div v-if="isLoading" class="loading-overlay"><p>Carregando dados...</p></div>


    <div class="form-edit-page">
        <form @submit.prevent="submitForm">
            <div class="row row-space">
                <div class="form-item" style="flex: 0 0 90px;">
                    <label class="label-caption">Código</label>
                    <div class="field-label field-label-end">{{ objEdicao.cdusuario }}</div>
                </div>

                <div class="form-item">
                    <label class="label-caption">Nome</label>
                    <input type="text" v-model="objEdicao.nmusuario" required maxlength="100" v-uppercase />
                </div>

                <div class="form-item" style="flex: 0 0 150px;">
                    <label class="label-caption">CPF</label>
                    <input type="text" class="input-center" v-model="objEdicao.cpf" v-cpf maxlength="14" />
                </div>
            </div>


            <div class="row row-space">
                <div class="form-item-telefone">
                    <label class="label-caption">Celular</label>
                    <input type="text" class="input-center" v-model="objEdicao.celular" v-phone maxlength="15" />
                </div>

                <div class="form-item-telefone">
                    <label class="label-caption">Fone</label>
                    <input type="text" class="input-center" v-model="objEdicao.fone" v-phone maxlength="15" />
                </div>

                <div class="form-item">
                    <label class="label-caption">Email</label>
                    <input type="email" v-model="objEdicao.email"/>
                </div>
            </div>     
            
            <div class="row row-space">
                <div class="form-item">
                    <label class="label-caption">Cargo/Função</label>
                    <input type="text" v-model="objEdicao.cargo" required maxlength="100" v-uppercase />
                </div>

                <div v-if="ckLoginAdmin" class="form-item">
                    <FornecedorSelect :descCaption= "'Prestador Serviço IP'" :ckIpPrestador="true" class="form-item"
                        v-model:fornecedorSelecionado="idIpPrestador" />
                </div>

                <div v-if="idEdicao==0" class="form-item-char20">
                    <label class="label-caption">Login Inicial</label>
                    <input type="text" v-model="objEdicao.login" />
                </div>
                <div v-if="idEdicao==0" class="form-item-char20">
                    <label class="label-caption">Senha Inicial</label>
                    <input type="text" v-model="objEdicao.senha" />
                </div>
            </div>


            <div v-if="ckLoginAdmin" class="row-space">
                <div class="row-end">
                    <label for="ckativo">Ativo</label>
                    <input id="ckativo" type="checkbox" class="input-checkbox" v-model="ckAtivo" />
                </div>

                <div class="row-end">
                    <label for="ckadmin">Administrador do Sistema</label>
                    <input id="ckadmin" type="checkbox" class="input-checkbox" v-model="ckAdmin" />
                </div>

                <div class="row-end">
                    <label for="ckipprestador">Prestador de Serviço Iluminação Pública</label>
                    <input id="ckipprestador" type="checkbox" class="input-checkbox" v-model="ckIpPrestador" />
                </div>
            </div>

            <div class="row-end">
                <button v-if="objEdicao.id>0" type="button" @click="showModalSenha=true">Alterar Login</button>
                <button type="submit" class="btn-confirm">Salvar</button>
                <button type="button" class="btn-cancel" @click="cancelForm">Cancelar</button>
            </div>            
        </form>
    </div>

    <div>
        <ModalRedefinirSenha
            :visible="showModalSenha"
            @update:visible="(value) => (showModalSenha = value)"
            @submit="redefinirLoginSenha" />
    </div>
</template>
  
<script>
import { ref } from 'vue';
import { criarUsuario } from '@/models/Usuario';
import { useLoginStore } from '@/store/login';
import { genericPut, getCadastroById } from '@/funcoes/api';
import { globalStore } from '@/globals';
import FornecedorSelect from '@/selects/FornecedorSelect.vue';
import ModalRedefinirSenha from '@/components/modals/ModalRedefinirSenha.vue';

export default {
    components:{ FornecedorSelect, ModalRedefinirSenha },
    emits: ['close', 'salvo'],
    props:{
        idEdicao: {type: Number, default: 0}
    },

    setup(props, { emit }){
        const ckAdmin = ref(false);
        const ckAtivo = ref(false);
        const ckIpPrestador = ref(false);
        const ckLoginAdmin= ref(globalStore.ckadmin==='S');
        const objEdicao = criarUsuario();
        const idIpPrestador = ref(null);
        const isLoading = ref(false);
        const login = useLoginStore();
        const showModalSenha = ref(false);

        const cancelForm = () => {emit('close');}

        const lerDados = async() => {
            if (props.idEdicao > 0) {
                try{
                    isLoading.value = true;
                    try{
                        const req = await getCadastroById('usuario', props.idEdicao);
                        Object.assign(objEdicao, req[0]);
                    }catch (error){console.error('Erro leitura dos dados', error);}
                }finally{isLoading.value = false;}
            }else{
                objEdicao.idmunicipio = login.userData.idmunicipio;
            }
            ckAdmin.value         = objEdicao.ckadmin === 'S';
            ckAtivo.value         = objEdicao.ativo === 'S';
            ckIpPrestador.value   = objEdicao.ckipprestador === 'S';
            idIpPrestador.value   = objEdicao.idipprestador;
            objEdicao.idusuario   = login.userData.id;
        };

        const redefinirLoginSenha = async({username, senha}) => {
            try{
                isLoading.value = true;
                const r = await genericPut('/cad/usuario', {id: objEdicao.id, login: username, senha: senha});
                if (r.bolok == 1){
                    alert("Nova senha alterada com sucesso");
                    objEdicao.login = username;  // POR GARANTIA ATÉ QUE DESVINCULE LOGIN E SENHA DO OBJETO
                    objEdicao.senha = senha;
                }else{alert(r.error);}
            }finally {isLoading.value = false;}
        }

        const submitForm = async() => {
            try{
                objEdicao.ativo         = ckAtivo.value ? 'S' : 'N';
                objEdicao.ckadmin       = ckAdmin.value ? 'S' : 'N';
                objEdicao.ckipprestador = ckIpPrestador.value ? 'S' : 'N';
                objEdicao.idipprestador = idIpPrestador.value;
                const response        = await genericPut('/cad/usuario', objEdicao);
                if (response.bolok == 1){
                    emit('salvo');
                    emit('close');
                }else{alert(response.erro);}
            }catch (error){console.error('Error:', error);}
        }

        lerDados();

        return {
            ckAdmin, ckAtivo, ckIpPrestador, ckLoginAdmin, objEdicao, idIpPrestador, isLoading, showModalSenha,
            cancelForm, redefinirLoginSenha, submitForm };
    }
};
</script>