<template>
    <div class="form-item">
        <label class="label-caption">Situação Solicitação</label>
        <select :value="statusSelecionado" @change="atualizarTipo" class="form-control" v-bind="$attrs">
            <option v-for="tipo in lsTipos" :key="tipo.id" :value="tipo.id">{{ tipo.descricao }}</option>
        </select>
    </div>
</template>
  
<script>
export default {
    props: {
        statusSelecionado: {type: Number, default: null}
    },

    data() {
        return{
            lsTipos:[
                {id: 0, descricao: 'TODAS'},
                {id: 1, descricao: 'AGUARDANDO APROVAÇÃO'},
                {id: 2, descricao: 'APROVADA'},
                {id: 3, descricao: 'OS GERADA'},
                {id: 4, descricao: 'REJEITADA POR CRITÉRIOS'},
                {id: 9, descricao: 'NÃO APROVADA'}
            ]
        };
    },

    methods: {
        atualizarTipo(event) {
            this.$emit('update:statusSelecionado', Number(event.target.value));
        }
    }
};
</script>