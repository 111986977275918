<template>
    <div class="modal-overlay">
        <div class="modal">
            <h3>Informe o CPF ou CNPJ</h3>
            <form @submit.prevent="submitCpfCnpj">
                <div>
                    <label for="cpfCnpjInput">CPF ou CNPJ:</label>
                    <input
                        type="text"
                        id="cpfCnpjInput"
                        v-model="cpfCnpj"
                        placeholder="Digite o CPF ou CNPJ"
                    />
                </div>
                <div class="actions">
                        <button class="btn-confirm" type="submit">Confirmar</button>
                        <button class="btn-cancel" type="button" @click="closeModal">Cancelar</button>
                </div>
            </form>
        </div>
    </div>
</template>
  
<script>
export default {
    data() {
        return {
            cpfCnpj: ""
        };
    },
    methods: {
        submitCpfCnpj() {
            if (this.cpfCnpj.trim() === "") {
                alert("Informe um CPF ou CNPJ.");
                return;
            }
            this.$emit("cpfCnpjSubmitted", this.cpfCnpj);
        },

        closeModal() {
            this.$emit("close");
        }
    }
};
</script>
  
<style scoped>
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
}
  
.modal {
    background: #fff;
    padding: 20px;
    border-radius: 5px;
    width: 300px;
}
  
.actions {
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
}
</style>
  