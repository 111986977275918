<template>
    <div v-if="isLoading" class="loading-overlay"><p>Carregando...</p></div>
    <MensagemAlerta class="row-space" v-if="msgAlerta" :mensagem="msgAlerta" />

    <div v-if="!showOSSelect" class="form-edit-page">
        <form @submit.prevent="submitForm">
            <div v-if="osSelecionada" class="row row-space">
                <div class="form-item-codigo">
                    <label class="label-caption">OS</label>
                    <div class="field-label field-label-end">{{ osSelecionada.cdos }}</div>
                </div>

                <div class="form-item-char10">
                    <label class="label-caption">Inclusão</label>
                    <div class="field-label field-label-center">{{ formatDateFromJson(osSelecionada.dtinclusao) }}</div>
                </div>

                <div class="form-item">
                    <label class="label-caption">Nome</label>
                    <div class="field-label">{{ osSelecionada.nmpessoa }}</div>
                </div>

                <div class="form-item">
                    <label class="label-caption">Serviço</label>
                    <div class="field-label">{{ osSelecionada.nmservico }}</div>
                </div>

                <div class="form-item-char10">
                    <label class="label-caption">Hr Contratadas</label>
                    <div class="field-label field-label-center">{{ osSelecionada.hrcontratadas }}</div>
                </div>
            </div>

            <div class="row row-space">
                <div class="form-item">
                    <label class="label-caption">Grupo de Equipamento</label>
                    <div class="field-label">{{ osSelecionada.nmlocalidade }}</div>
                </div>

                <div class="form-item">
                    <label class="label-caption">Grupo de Equipamento</label>
                    <div class="field-label">{{ osSelecionada.nmgrupo }}</div>
                </div>

                <div class="form-item">
                    <label class="label-caption">Siruação OS</label>
                    <div class="field-label">{{ osSelecionada.nmsituacao }}</div>
                </div>

                <div class="form-item">
                    <label class="label-caption">Siruação Pagamento</label>
                    <div class="field-label">{{ osSelecionada.nmpagamento }}</div>
                </div>
            </div>


            <div class="row row-space">
                <div class="form-item">
                    <label class="label-caption">Justificativa</label>
                    <input type="text" v-model="edJustificativa" v-uppercase />
                </div>
            </div>


            <div class="row-end">
                <button class="btn-confirm" type="submit">Confirmar</button>
                <button class="btn-cancel" type="cancel" @click="openOSSelect">Cancelar</button>
            </div>
        </form>
    </div>

    <div v-if="showOSSelect">
        <OsPesquisaPage
            :idSituacao="2"
            @cancel="cancelForm"
            @select="selecionarOS" />
    </div>
</template>

<script>
import { ref } from 'vue';
import { formatDateFromJson, formatDecimal } from '@/funcoes/utils';
import { genericPost } from '@/funcoes/api';
import { globalStore } from '@/globals';
import MensagemAlerta from '@/components/MensagemAlerta.vue';
import OsPesquisaPage from './OsPesquisaPage.vue';

export default{
    components: { MensagemAlerta, OsPesquisaPage },
    emits: ['close', 'salvo'],
    setup(_, { emit }){
        const isLoading = ref(false);
        const msgAlerta = ref(false);
        const showOSSelect = ref(true);

        const edJustificativa = ref(null);

        // OBJETOS
        const osSelecionada = ref(null);

        const cancelForm = () => {emit('close');};

        const openOSSelect = () => {
            showOSSelect.value=true;
        }

        const selecionarOS = (os) => {
            showOSSelect.value      = false;
            osSelecionada.value     = os;
        };

        const submitForm = async () => {
            try{
                msgAlerta.value=null;
                if (!edJustificativa.value){
                    msgAlerta.value='Necessário informar uma justificativa';
                    return;
                }
                isLoading.value=true;
                const ret = await genericPost('/os/cancelamento', {
                    idmunicipio: globalStore.idmunicipio,
                    idos: osSelecionada.value.id,
                    strparam1: edJustificativa.value,
                    idlogin: globalStore.idlogin,
                    idusuario: globalStore.idusuario});
                if (ret.bolok === 1){
                    emit('save');
                    showOSSelect.value      = true;
                    edJustificativa.value   = null;
                }else{
                    msgAlerta.value=ret.erro;
                }
            }finally{isLoading.value=false;}
        }

        return{
            edJustificativa, isLoading, msgAlerta, showOSSelect,
            osSelecionada,
            cancelForm, formatDateFromJson, formatDecimal, openOSSelect, selecionarOS, submitForm};
    }
}
</script>