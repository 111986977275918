import { reactive } from 'vue';

export const criarProduto = () => {
    return reactive({
        id: 0,
        idmunicipio: 0,
        iddespesagrupo: 0,
        cdproduto: 0,
        nmproduto: '',
        unidade: '',
        almox_minimo: 0,
        ativo: 'S',
        ckcombustivel: 'N',
        ckip: 'N',
        vlcusto: 0,
        idusuario: 0
    });
};