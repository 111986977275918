import { reactive } from 'vue';

export const criarUsuario = () => {
    return reactive({
        id: 0,
        idmunicipio: 0,
        idipprestador: 0,
        cdusuario: 0,
        nmusuario: '',
        cpf: '',
        cpfnros: '',
        cargo: '',
        celular: '',
        fone: '',
        email: '',
        login: '',
        senha: '',
        dtsenha: '',
        nivel: 0,
        situacao: '',
        primeiroacesso: 0,
        logo: '',
        paineladm: 0,
        cdusuarioadm: 0,
        cdusuarioedit: 0,
        usuarioteste: 0,
        dtlogin: '',
        menuequipamentos: 0,
        menuservicos: 0,
        ativo: 'S',
        ckadmin: 'N',
        ckipprestador: 'N',
        idusuario: 0
    });
};