import { reactive } from 'vue';

export const criarIpOs = () => {
    return reactive({
        id: 0,
        idmunicipio: 0,
        idbairro: 0,
        idlogradouro: 0,
        idipservico: 0,
        idipprestador: 0,
        cdipos: 0,
        dtipos: '',
        detalhamento: '',
        nmendereco: 0,
        referencia: '',
        latitude: 0,
        longitude: 0,
        dhautorizacao: '',
        lgautorizacao: 0,
        dtinicio: '',
        hrinicio: '',
        dtfim: '',
        hrfim: '',
        status: 0,
        statusnm: '',
        idusuario: 0
    });
};