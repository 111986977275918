<template>
    <div>
        <div id="map" style="height: 330px; width: 100%;"></div>
    </div>
</template>

<script>
import L from 'leaflet';

export default {
    props: {
        latitude: {type: Number, required: true},
        longitude: {type: Number, required: true}
    },
    mounted() {
        this.map = L.map('map').setView([this.latitude, this.longitude], 15);

        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            maxZoom: 19,
        }).addTo(this.map);

        const customIcon = L.icon({
            iconUrl: require('@/assets/icons/mapMarker.svg'), // Caminho para sua imagem
            iconSize: [38, 38], // Tamanho do ícone (largura, altura)
            iconAnchor: [19, 38], // Ponto de ancoragem (base do ícone)
            popupAnchor: [0, -38] // Ponto onde o popup aparecerá
        });

        L.marker([this.latitude, this.longitude], {icon: customIcon}).addTo(this.map).openPopup();

        // Força o Leaflet a recalcular o tamanho do mapa
        this.$nextTick(() => {
            setTimeout(() => {
                this.map.invalidateSize();
            }, 100); // Pequeno atraso para garantir que o DOM foi atualizado
        });
    },
    data() {
        return {
            map: null
        };
    }
};
</script>