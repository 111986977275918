export default {
  beforeMount(el) {
    el.style.textAlign = 'right';

    // Formata o valor ao sair do campo
    el.addEventListener('blur', function (e) {
      let value = e.target.value.trim();

      if (value) {
        // Substitui vírgulas por ponto, converte para número, e aplica formatação
        value = parseFloat(value.replace(',', '.')).toLocaleString('pt-BR', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });
      }

      e.target.value = value || ''; // Define valor formatado ou vazio se inválido
      const event = new Event('input', { bubbles: true });
      el.dispatchEvent(event);
    });

    // Remove formatação ao focar no campo
    el.addEventListener('focus', function (e) {
      let value = e.target.value.trim();

      if (value) {
        // Remove formatação e retorna valor bruto
        value = value.replace(/\./g, '').replace(',', '.');
        e.target.value = value;
      }
    });
  },

  // Formata o valor inicial ao montar o componente
  mounted(el) {
    if (el.value) {
      el.value = parseFloat(el.value).toLocaleString('pt-BR', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    }
  }
};
