<template>
    <div v-if="isLoading" class="loading-overlay"><p>Carregando...</p></div>

    <div v-if="!showPessoaSelect" class="form-edit-page">
        <form @submit.prevent="submitForm">
            <div v-if="pessoaSelecionada" class="row row-space">
                <div class="form-item" style="flex: 0 0 90px;">
                    <label class="label-caption">Produtor</label>
                    <div class="field-label field-label-end">{{ pessoaSelecionada.cdpessoa }}</div>
                </div>

                <div class="form-item flexible">
                    <label class="label-caption">Nome</label>
                    <div class="field-label">{{ pessoaSelecionada.nmpessoa }}</div>
                </div>

                <div class="form-item" style="flex: 0 0 160px;">
                    <label class="label-caption">CPF</label>
                    <div class="field-label field-label-center">{{ pessoaSelecionada.cpfcnpj }}</div>
                </div>
            </div>


            <div class="row row-space">
                <LocalidadeSelect class="form-item" v-model:localidadeSelecionada="idLocalidade" />

                <div class="form-item flexible">
                    <label class="label-caption">Complemento/referência</label>
                    <input type="text" v-model="edLocalidadeComplemento" maxlength="100" v-uppercase />
                </div>
            </div>
            
            
            <div class="row row-space">
                <EquipamentoGrupoSelect class="form-item" v-model:grupoSelecionado="idGrupo" required />
                <EquipamentoSelect :descCaption= "'Implemento'" :flagTipo="2" class="form-item" v-model:equipamentoSelecionado="idImplemento" />
            </div>
            

            <div class="row row-space">
                <ServicoSelect class="form-item" v-model:servicoSelecionado="idServico" required />

                <div class="form-item" style="flex: 0 0 140px;">
                    <label class="label-caption">Data Solicitação</label>
                    <input type="date" v-model="edDtSolicitacao" required />
                </div>

                <div class="form-item" style="flex: 0 0 120px;">
                    <label class="label-caption">Horas Solicitadas</label>
                    <input type="text" class="input-center" v-time-mask v-model="edHrSolicitadas" required />
                </div>
            </div>

            <div class="row row-space">
                <div class="form-item">
                    <label class="label-caption">Detalhamento</label>
                    <textarea v-model="edDescricao" maxlength="1000" rows="5" style="resize: none;"/>
                </div>
            </div>

            <div class="row-end">
                <label for="ckChuva">Pode ser executada com chuva</label>
                <input id="ckChuva" type="checkbox" class="input-checkbox" v-model="ckChuva" />
            </div>

            <div class="row-end margin-top10">
                <button class="btn-confirm" type="submit">Salvar</button>
                <button class="btn-cancel" type="cancel" @click="cancelForm">Cancelar</button>
            </div>
        </form>
    </div>

    <div v-if="showPessoaSelect">
        <PessoaPesquisaPage
            @close="cancelForm"
            @pessoaSelecionada="selecionarPessoa" />
    </div>

</template>

<script>
import { ref } from 'vue';
import { decimalFromTimeString } from '@/funcoes/utils';
import { genericPut } from '@/funcoes/api';
import { globalStore } from '@/globals';
import EquipamentoGrupoSelect from '@/selects/EquipamentoGrupoSelect.vue';
import EquipamentoSelect from '@/selects/EquipamentoSelect.vue';
import LocalidadeSelect from '@/selects/LocalidadeSelect.vue';
import PessoaPesquisaPage from '../cadastros/pesquisas/PessoaPesquisaPage.vue';
import ServicoSelect from '@/selects/ServicoSelect.vue';

export default{
    components: {EquipamentoGrupoSelect, EquipamentoSelect, LocalidadeSelect, PessoaPesquisaPage, ServicoSelect},
    emits: ['close', 'save'],
    props: {idEdicao: {type: Number, default: 0}},
    setup(props, { emit }){
        const ckChuva = ref(false);
        const edDescricao = ref(null);
        const edDtSolicitacao = ref(new Date().toISOString().split('T')[0]);
        const edHectares = ref(null);
        const edHrSolicitadas = ref(null);
        const edLocalidadeComplemento = ref(null);

        const isLoading = ref(false);
        const idGrupo = ref(null);
        const idImplemento = ref(null);
        const idLocalidade = ref(null);
        const idServico = ref(null);
        const showPessoaSelect = ref(true);

        // OBJETOS
        const pessoaSelecionada = ref(null);

        const cancelForm = () => {emit('close');};

        const iniciarDados = async() => {
            if (props.idEdicao > 0) {
                console.log(props.idEdicao);
            }
        }

        const selecionarPessoa = (novaPessoa) => {
            pessoaSelecionada.value = novaPessoa; // Atualiza o valor da ref
            idLocalidade.value = pessoaSelecionada.value.idlocalidade;
            showPessoaSelect.value = false; // Fecha a tela de seleção
        };

        const submitForm = async () => {
            try{
                isLoading.value=true;
                const dadosSolicitacao = {
                    id: 0,
                    idmunicipio: globalStore.idmunicipio,
                    idequipamento_grupo: idGrupo.value,
                    idimplemento: idImplemento.value,
                    idservico: idServico.value,
                    idpessoa: pessoaSelecionada.value.id,
                    idlocalidade: idLocalidade.value,

                    hectares: edHectares.value,
                    localidade_complemento: edLocalidadeComplemento.value,
                    descricao: edDescricao.value,
                    chuva: ckChuva.value ? 1 : 0, 
                    dtsolicitacao: edDtSolicitacao.value,
                    mnsolicitados: decimalFromTimeString(edHrSolicitadas.value) * 60,

                    idusuario: globalStore.idusuario};

                const retOs = await genericPut('/cad/os_solicitacao', dadosSolicitacao);
                if (retOs.bolok==1){
                    emit('save');
                    emit('close');
                }
            }finally{isLoading.value=false;}
        }

        iniciarDados();

        return{ckChuva, edDescricao, edDtSolicitacao, edHectares, edHrSolicitadas, edLocalidadeComplemento,
            idGrupo, idImplemento, idLocalidade, idServico, isLoading, 
            pessoaSelecionada, showPessoaSelect, cancelForm, selecionarPessoa, submitForm};
    }
}
</script>