import { reactive } from 'vue';

export const criarServico = () => {
    return reactive({
        id: 0,
        idmunicipio: 0,
        cdservico: 0,
        idgruposervico: 0,
        xidgruposervico: '',
        nmservico: '',
        agendamento: 'N',
        agendamento_limitedia: 0,
        ativo: 'S',
        ckcalculofracionado: 'N',
        ckexportaoperador: 'N',
        mnhectare: 0,
        idusuario: 0
    });
};