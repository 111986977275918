<template>
    <div class="form-item">
        <label class="label-caption">Serviço</label>
        <select :value="ipservicoSelecionado" @change="atualizarObj" class="form-control" v-bind="$attrs"> 
            <option v-if="!lsDados || lsDados.length === 0" disabled>Carregando...</option>
            <option v-for="obj in lsDados" :key="obj.id" :value="obj.id">{{ obj.nmipservico }}</option>
        </select>
    </div>
</template>

<script>
import { ref, watch, onMounted } from "vue";
import { genericPost } from "@/funcoes/api";
import { globalStore } from "@/globals";

export default {
    props: {
        ckAddTodos: {type: Boolean, default: false},
        ckCidadao: {type: Boolean, default: false},
        ckPrestador: {type: Boolean, default: false},
        lsDadosProp: {type: Array, default: () => [],},
        ipservicoSelecionado: {type: Number, default: null,},
    },
    setup(props, { emit }) {
        const lsDados = ref(props.lsDadosProp);

        const fetchData = async () => {
            try {
                const response = await genericPost('/listas/ipservicos', {
                    idmunicipio: globalStore.idmunicipio, 
                    ativo: 'S',
                    intparam1: props.ckCidadao ? 1 : 0,
                    intparam2: props.ckPrestador ? 1 : 0});
                lsDados.value = response;
                if (props.ckAddTodos){lsDados.value.unshift({ id: 0, nmipservico: 'TODOS' });}
            } catch (error) {console.error("Erro de conexão com a API:", error);}
        };

        const atualizarObj = (event) => {
            const selectedId = Number(event.target.value);
            emit("update:ipservicoSelecionado", selectedId);
        };

        onMounted(() => {
            if ((!props.lsDadosProp || props.lsDadosProp.length === 0)) {
                fetchData();
            }
        });

        watch(
            () => props.lsDadosProp,
            (newVal) => {lsDados.value = newVal;}
        );

        return {lsDados, atualizarObj};
    },
};
</script>
  