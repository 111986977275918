<template>
    <div v-if="isLoading" class="loading-overlay"><p>Carregando...</p></div>

    <div class="form-edit-page">
        <form @submit.prevent="submitForm">
            <div class="row row-space">
                <div class="form-item" style="flex: 0 0 90px;">
                    <label class="label-caption">Código</label>
                    <div class="field-label field-label-end">{{ objEdicao.cdipservico }}</div>
                </div>
                
                <div class="form-item flexible">
                    <label class="label-caption">Nome</label>
                    <input type="text" v-model="objEdicao.nmipservico" required maxlength="100" v-uppercase />
                </div>
            </div>


            <div class="row row-space">
                <div class="form-item">
                    <label class="label-caption">Detalhamento</label>
                    <textarea v-model="objEdicao.detalhamento" maxlength="1000" rows="5" style="resize: none;"/>
                </div>
            </div>


            <div class="row-end">
                <label for="ckAtivo">Ativo</label>
                <input id="ckAtivo" type="checkbox" class="input-checkbox" v-model="ckAtivo" />
            </div>

            <div class="row-end">
                <label for="ckCidadao">Permitir chamados cidadão</label>
                <input id="ckCidadao" type="checkbox" class="input-checkbox" v-model="ckCidadao" />
            </div>

            <div class="row-end">
                <label for="ckPrestador">Permitir chamados prestador de serviço</label>
                <input id="ckPrestador" type="checkbox" class="input-checkbox" v-model="ckPrestador" />
            </div>


            <div class="row-end margin-top10">
                <button class="btn-confirm" type="submit">Salvar</button>
                <button class="btn-cancel" type="cancel" @click="cancelForm">Cancelar</button>
            </div>
        </form>
    </div>
</template>
  
<script>
import { ref } from 'vue';
import { criarIpServico } from '@/models/IpServico';
import { genericPut, getCadastroById } from '@/funcoes/api';
import { globalStore } from '@/globals';

export default {
    name: 'ServicoEditPage',
    emits: ['close', 'salvo'],
    props: {idEdicao: {type: Number, default: 0}},
    setup(props, { emit }) {
        const ckAtivo = ref(false);
        const ckCidadao = ref(false);
        const ckPrestador = ref(false);
        const objEdicao = criarIpServico();
        const isLoading = ref(false);

        const submitForm = async () => {
            try {
                objEdicao.ativo       = ckAtivo.value ? 'S' : 'N';
                objEdicao.ckcidadao   = ckCidadao.value ? 'S' : 'N';
                objEdicao.ckprestador = ckPrestador.value ? 'S' : 'N';
                const response        = await genericPut('/cad/ipservico', objEdicao);
                if (response.bolok === 1){
                    emit('salvo');
                    emit('close');
                }else{
                    alert(response.erro);
                }
            } catch (error) {console.error('Error:', error);}
        };

        const cancelForm = () => {emit('close');};

        const lerDados = async() => {
            try{
                isLoading.value=true;
                if (props.idEdicao > 0) {
                    try{
                        const req = await getCadastroById('ipservico', props.idEdicao);
                        Object.assign(objEdicao, req[0]);
                    }catch (error){console.error('Erro leitura dos dados', error);}
                }else{
                    objEdicao.idmunicipio = globalStore.idmunicipio;
                }
                objEdicao.idusuario = globalStore.idusuario;
                ckAtivo.value       = objEdicao.ativo === 'S';
                ckCidadao.value     = objEdicao.ckcidadao === 'S';
                ckPrestador.value   = objEdicao.ckprestador === 'S';
            }finally{isLoading.value=false;}
        };

        lerDados();

        return {ckAtivo, ckCidadao, ckPrestador, isLoading, objEdicao, submitForm, cancelForm };
    }
};
</script>