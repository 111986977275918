import { reactive } from 'vue';

export const criarEquipamentoSetor = () => {
    return reactive({
        id: 0,
        idmunicipio: 0,
        cdequipamentosetor: 0,
        nmequipamentosetor: '',
        ativo: 'S',
        idusuario: 0
    });
};