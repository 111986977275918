<template>
    <div v-if="isLoading" class="loading-overlay"><p>Carregando...</p></div>

    <div class="form-edit-page">
        <form @submit.prevent="submitForm">
            <div class="row row-space">
                <div class="form-item" style="flex: 0 0 90px;">
                    <label class="label-caption">Código</label>
                    <div class="field-label field-label-end">{{ objEdicao.cdfornecedor }}</div>
                </div>
                <div class="form-item flexible">
                    <label class="label-caption">Nome</label>
                    <input type="text" v-model="objEdicao.nmfornecedor" required maxlength="100" v-uppercase />
                </div>
                <div class="form-item" style="flex: 0 0 160px;">
                    <label class="label-caption">CPF/CNPJ</label>
                    <input type="text" class="input-center" v-model="objEdicao.cpfcnpj" maxlength="18" required />
                </div>
                <div class="form-item" style="flex: 0 0 160px;">
                    <label class="label-caption">Inscrição Estadual</label>
                    <input type="text" class="input-center" v-model="edIe" v-number maxlength="11" />
                </div>
            </div>


            <div class="row row-space">
                <div class="form-item flexible">
                    <label class="label-caption">Endereço</label>
                    <input type="text" v-model="objEdicao.endereco" maxlength="100" v-uppercase />
                </div>
                <div class="form-item" style="flex: 0 0 150px;">
                    <label class="label-caption">Número</label>
                    <input type="text" class="input-right" v-model="edNmEndereco" v-number maxlength="5" />
                </div>
                <div class="form-item">
                    <label class="label-caption">Complemento</label>
                    <input type="text" v-model="objEdicao.complemento" maxlength="100" v-uppercase />
                </div>
            </div>


            <div class="row row-space">
                <div class="form-item">
                    <label class="label-caption">Bairro</label>
                    <input type="text" v-model="objEdicao.bairro" maxlength="100" v-uppercase />
                </div>

                <div class="form-item">
                    <label class="label-caption">Município</label>
                    <input type="text" v-model="objEdicao.nmmunicipio" maxlength="100" v-uppercase />
                </div>

                <div class="form-item" style="flex: 0 0 70px;">
                    <label class="label-caption">UF</label>
                    <input type="text" v-model="objEdicao.uf" maxlength="2" v-uppercase />
                </div>

                <div class="form-item" style="flex: 0 0 100px;">
                    <label class="label-caption">CEP</label>
                    <input type="text" v-model="objEdicao.cep" v-cep maxlength="10" />
                </div>
            </div>


            <div class="row row-space">  
                <div class="form-item" style="flex: 0 0 160px;">
                    <label class="label-caption">Fone</label>
                    <input type="text" v-model="objEdicao.fone" v-phone maxlength="15" />
                </div>
                
                <div class="form-item">
                    <label class="label-caption">Email</label>
                    <input type="email" v-model="objEdicao.email" />
                </div>

                <div class="form-item">
                    <label class="label-caption">Nome Contato</label>
                    <input type="text" v-model="objEdicao.nmcontato" v-uppercase maxlength="100" />
                </div>
            </div>


            <div class="row-end">
                <label>Ativo</label>
                <input type="checkbox" class="input-checkbox" id="ativo" v-model="ckAtivo" />
            </div>

            <div class="row-end">
                <label>Prestador Serviço Iluminação Pública</label>
                <input type="checkbox" class="input-checkbox" id="ativo" v-model="ckIpPrestador" />
            </div>


            <div class="row-end margin-top10">
                <button type="submit">Salvar</button>
                <button type="cancel" @click="cancelForm">Cancelar</button>
            </div>
        </form>
    </div>
</template>
  
<script>
import { ref } from 'vue';
import { criarFornecedor } from '@/models/Fornecedor';
import { formatInteger, integerFromString } from '@/funcoes/utils';
import { genericPut, getCadastroById } from '@/funcoes/api';
import { globalStore } from '@/globals';

export default {
    emits: ['close', 'salvo'],
    props: {idEdicao: {type: Number, default: 0}},
    setup(props, { emit }) {
        const ckAtivo = ref(false);
        const ckIpPrestador = ref(false);
        const edIe = ref(false);
        const edNmEndereco = ref(null);
        const objEdicao = criarFornecedor();
        const isLoading = ref(false);

        const submitForm = async () => {
            try {
                isLoading.value=true;
                objEdicao.ativo = ckAtivo.value ? 'S' : 'N';
                objEdicao.ckipprestador = ckIpPrestador.value ? 'S' : 'N';
                objEdicao.ie = integerFromString(edIe.value);
                objEdicao.nmendereco = integerFromString(edNmEndereco.value);
                const response = await genericPut('/cad/fornecedor', objEdicao);
                if (response.bolok==1){
                    emit('salvo');
                    emit('close');
                }
            } catch (error) {console.error('Error:', error);
            } finally {isLoading.value=false;}
        };

        const cancelForm = () => {emit('close');};

        const lerDados = async() => {
            try{
                isLoading.value=true;
                if (props.idEdicao > 0) {
                    try{
                        const req = await getCadastroById('fornecedor', props.idEdicao);
                        Object.assign(objEdicao, req[0]);
                    }catch (error){console.error('Erro leitura dos dados', error);}
                }else{
                    objEdicao.idmunicipio = globalStore.idmunicipio;
                }
                objEdicao.idusuario = globalStore.idusuario;
                ckAtivo.value       = objEdicao.ativo==='S';
                ckIpPrestador.value = objEdicao.ckipprestador==='S';
                edIe.value          = formatInteger(objEdicao.ie);
                edNmEndereco.value  = formatInteger(objEdicao.nmendereco);
            }finally{isLoading.value=false;}
        };

        lerDados();

        return { ckAtivo, ckIpPrestador, edIe, edNmEndereco, objEdicao, isLoading, submitForm, cancelForm };
    }
};
</script>