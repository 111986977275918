<template>
    <div class="form-item">
        <label class="label-caption">Região</label>
        <select :value="regiaoSelecionada" @change="atualizarObj" class="form-control" v-bind="$attrs"> 
            <option v-if="isLoading" disabled>Carregando...</option>
            <option v-if="!lsDados || lsDados.length === 0" disabled>Nenhuma opção disponível</option>
            <option v-for="obj in lsDados" :key="obj.id" :value="obj.id">{{ obj.nmregiao }}</option>
        </select>
    </div>
</template>

<script>
import { ref, watch, onMounted } from "vue";
import { genericPost } from "@/funcoes/api";
import { globalStore } from "@/globals";

export default {
    props: {
        ckAddTodos:{type: Boolean, default: false},
        lsDadosProp: {type: Array, default: () => [],},
        regiaoSelecionada: {type: Number, default: null,},
    },
    setup(props, { emit }) {
        const lsDados = ref(props.lsDadosProp);
        const isLoading = ref(false);

        const fetchData = async () => {
            try {
                isLoading.value=true;
                const response = await genericPost('/listas/regioes', {
                    idmunicipio: globalStore.idmunicipio, ativo: 'S'});
                lsDados.value = response;
                if (props.ckAddTodos){lsDados.value.unshift({ id: 0, nmregiao: 'TODAS' });}
            } catch (error) {console.error("Erro de conexão com a API:", error);
            }finally{isLoading.value=false;}
        };

        const atualizarObj = (event) => {
            emit("update:regiaoSelecionada", Number(event.target.value));
        };

        onMounted(() => {
            if (!props.lsDadosProp || props.lsDadosProp.length === 0) {
                fetchData();
            }
        });

        watch(
            () => props.lsDadosProp,
            (newVal) => {lsDados.value = newVal;}
        );

        return {isLoading, lsDados, atualizarObj};
    },
};
</script>
  