<template>
    <div v-if="visible" class="modal-overlay" @click="closeModal">
        <img :src="imageSrc" alt="Imagem carregada" class="modal-image" />
    </div>
</template>

<script>
export default{
    props:{
        imageSrc:{type: String, required: true},
        visible:{type: Boolean, required: true},
    },
    emits:['close'],
    methods:{
        closeModal(){this.$emit('close')}
    }
}
</script>

<style scoped>
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8); /* Fundo semi-transparente */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    cursor: pointer; /* Fechar ao clicar no modal */
}

.modal-image {
    max-width: 90%;
    max-height: 90%;
    border-radius: 8px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
}
</style>