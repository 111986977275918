<template>
    <div v-if="isLoading" class="loading-overlay"><p>Carregando...</p></div>


    <div v-if="!modoVisualizacao">
        <div class="form-edit-page row-space">
            <div class="row">
                <div class="form-item-rangedata">
                    <DateRangeSelect v-model="dateRange" />
                </div>

                <IpOsSolicitacaoStatusSelect class="form-item" v-model:statusSelecionado="statusSelecionado" />
                
                <button type="button" class="buttom-icon--search" @click="lerDados" />
                <button type="button" class="buttom-icon--printer" @click="imprimir" />
                <button type="button" class="buttom-icon--home" @click="closeForm" />
            </div>
        </div>

        <MensagemAlerta class="row-space" v-if="errorMessage" :mensagem="errorMessage" />
        
        <table>
            <thead>
                <tr>
                    <th class="col-width-integer">Código</th>
                    <th class="col-width-date">Data</th>
                    <th>Bairro</th>
                    <th>Logradouro</th>
                    <th class="col-width-medium">Situação</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(obj, index) in lsDados" :key="obj.id" :class="{'even-row': index % 2 === 0, 'odd-row': index % 2 !== 0, 'clickabe-row': true}"  @click="setVisualizar(obj)">
                    <td class="col-width-integer right">{{ obj.cdipossolicitacao }}</td>
                    <td class="col-width-date center">{{ formatDateDMY(obj.dtsolicitacao) }}</td>
                    <td>{{ obj.nmbairro }}</td>
                    <td>{{ obj.nmlogradouro }}</td>
                    <td class="col-width-medium">{{ obj.statusnm }}</td>
                </tr>
            </tbody>
        </table>
    </div>


    <div v-if="modoVisualizacao">
        <IpOSSolicitacaoViewPage :idView="idView" @close="modoVisualizacao = false" @save="lerDados" />
    </div>
</template>


<script>
import { ref } from 'vue';
import { formatDateFromJson, strDataHoraAtual } from '@/funcoes/utils';
import { genericPost, genericPut, genericReport } from '@/funcoes/api'; 
import { globalStore } from '@/globals';
import DateRangeSelect from '@/components/DateRangeSelect.vue';
import IpOsSolicitacaoStatusSelect from '@/selects/IpOsSolicitacaoStatusSelect.vue';
import IpOSSolicitacaoViewPage from '../cadastros/visualizacoes/IpOSSolicitacaoViewPage.vue';
import MensagemAlerta from '@/components/MensagemAlerta.vue';

export default{
    emits: ['close'],
    components:{DateRangeSelect, IpOsSolicitacaoStatusSelect, IpOSSolicitacaoViewPage, MensagemAlerta},

    setup(_, { emit }){
        const dateRange = ref({ startDate: "", endDate: "", selectedPeriod: "" });
        const errorMessage = ref(null);
        const idView = ref(0);
        const isLoading = ref(false);
        const lsDados = ref([]);
        const modoVisualizacao = ref(false);
        const nmPesquisa = ref(null);
        const statusSelecionado = ref(null);

        const autorizarOs = async(obj) => {
            try{
                isLoading.value=true;
                const r = await genericPut('/cad/ipos', {
                    id: obj.id, 
                    dhautorizacao: strDataHoraAtual(), 
                    lgautorizacao: globalStore.idlogin});
                if (r.bolok === 1){
                    lerDados();
                }else{errorMessage.value = r.error;}
            }finally{isLoading.value=false;}

        }

        const closeForm = () => {emit('close');}

        const imprimir = async() => {
            try{
                if (!dateRange.value.startDate || !dateRange.value.endDate){
                    throw new Error('Necessário informar ambas as datas.');
                }
                const dataInicio = new Date(dateRange.value.startDate);
                const dataFim = new Date(dateRange.value.endDate);

                if (isNaN(dataInicio.getTime()) || isNaN(dataFim.getTime())){
                    throw new Error('Datas inválidas. Verifique o formato das datas.');
                }

                if (dataFim < dataInicio){
                    throw new Error('A data final deve ser maior ou igual à data inicial.');
                }

                errorMessage.value = null;
                isLoading.value = true;
                const dados = {
                    nmrelatorio: 'ipossolicitacoes',
                    idmunicipio: globalStore.idmunicipio,
                    idusuario: globalStore.idusuario,
                    datainicial: dateRange.value.startDate,
                    datafinal: dateRange.value.endDate,
                    intp1: statusSelecionado.value};
                const response = await genericReport(dados);
                const pdfBlob = new Blob([response], { type: 'application/pdf' });
                const pdfUrl = URL.createObjectURL(pdfBlob);
                window.open(pdfUrl, '_blank');
            } catch (error){errorMessage.value = `ATENÇÃO: ${error.message}`;
            } finally {isLoading.value = false;}
        }

        const lerDados = async() => {
            if ((!dateRange.value.startDate) || (!dateRange.value.endDate)){
                errorMessage.value = 'Informe corretamente um período';
                return;
            }
            try{
                errorMessage.value = null;
                isLoading.value = true;
                const req = await genericPost('/ip/ipossolicitacoes', {
                    idmunicipio: globalStore.idmunicipio,
                    datainicial: dateRange.value.startDate, 
                    datafinal:dateRange.value.endDate,
                    intparam1: statusSelecionado.value});
                lsDados.value = req;
            } catch (error){errorMessage.value=error;
            } finally {isLoading.value = false;}
        }

        const setVisualizar = (obj) =>{
            idView.value = obj.id;
            modoVisualizacao.value = true;
        }

        return { dateRange, errorMessage, isLoading, idView, lsDados, modoVisualizacao, nmPesquisa, statusSelecionado,
            autorizarOs, closeForm, formatDateDMY: formatDateFromJson, imprimir, lerDados, setVisualizar };
    }
}
</script>
