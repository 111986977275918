export default {
    beforeMount(el) {
        let isUpdating = false;

        el.addEventListener('input', () => {
            if (isUpdating) return;

            isUpdating = true;
            const start = el.selectionStart;
            const end = el.selectionEnd;

            // Atualiza o valor do input para maiúsculas
            el.value = el.value.toUpperCase();

            // Reposiciona o cursor
            el.setSelectionRange(start, end);

            // Dispara o evento de input para que o v-model seja atualizado
            el.dispatchEvent(new Event('input'));

            isUpdating = false;
        });
    }
};
