<template>
    <div v-if="isLoading" class="loading-overlay"><p>Carregando...</p></div>

    <div v-if="!modoEdicao">
        <div class="form-edit-page" style="display: flex; gap: 10px; align-items: end;">
            <div class="form-item flexible">
                <label class="label-caption" for="nmPesquisa">Nome Pesquisa</label>
                <input type="text" v-model="nmPesquisa" id="nmPesquisa" maxlength="80" v-uppercase />
            </div>
            <button type="button" class="buttom-icon--search" @click="lerDados" />
            <button type="button" class="buttom-icon--add" @click="novoCadastro" />
            <button type="button" class="buttom-icon--close" @click="closePage" />
        </div>

        <div class="margin-top10">
            <table>
                <thead>
                    <tr>
                        <th class="col-width-integer">Código</th>
                        <th>Nome</th>
                        <th class="col-width-char40">Email</th>
                        <th class="col-width-char20">Celular</th>
                        <th class="col-width-char10">Situação</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(obj, index) in lsDados" :key="obj.id" :class="{'even-row': index % 2 === 0, 'odd-row': index % 2 !== 0, 'clickabe-row': true}" @click="editarCadastro(obj)">
                        <td class="col-width-integer right">{{ obj.cdusuario }}</td>
                        <td>{{ obj.nmusuario }}</td>
                        <td class="col-width-char40">{{ obj.email }}</td>
                        <td class="col-width-char20 center">{{ obj.celular }}</td>
                        <td class="col-width-char10 center">{{ obj.ativonm }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <div v-if="modoEdicao">
        <UsuarioEditPage :idEdicao="idEdicao" @close="modoEdicao = false" @salvo="lerDados" />
    </div>
</template>


<script>
import { onMounted, ref } from 'vue';
import { genericPost } from '@/funcoes/api';
import { globalStore } from '@/globals';
import UsuarioEditPage from '../formularios/UsuarioEditPage.vue';

export default{
    components: { UsuarioEditPage },
    emits:['close'],
    setup(_, { emit }){
        const idEdicao = ref(0);
        const isLoading = ref(false);
        const lsDados = ref([]);
        const modoEdicao = ref(false);
        const nmPesquisa = ref('');

        const closePage = () =>{emit('close');};

        const editarCadastro = (obj) => {
            if (obj.cdusuario<0){return;}
            idEdicao.value = obj.id;
            modoEdicao.value = true;
        };

        const lerDados = async() => {
            try{
                isLoading.value = true;
                const req = await genericPost('/listas/usuarios', {idmunicipio: globalStore.idmunicipio, nmpesquisar: nmPesquisa.value});
                lsDados.value = req;
            } catch (error){console.error('Erro leitura dos dados', error);
            } finally {isLoading.value = false;}
        }

        const novoCadastro = () => {
            idEdicao.value = 0;
            modoEdicao.value = true;
        }

        onMounted(()=>{lerDados()})

        return { 
            idEdicao, isLoading, lsDados, modoEdicao, nmPesquisa, 
            closePage, editarCadastro, lerDados, novoCadastro };
    }
}
</script>
