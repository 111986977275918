<template>
    <div class="form-item">
        <label class="label-caption">{{ descCaption }}</label>
        <select :value="equipamentoSelecionado" @change="atualizarObj" class="form-control" v-bind="$attrs"> 
            <option v-if="isLoading" disabled>Carregando...</option>
            <option v-if="!lsDados || lsDados.length === 0" disabled>Nenhuma opção disponível</option>
            <option v-for="obj in lsDados" :key="obj.id" :value="obj.id">{{ obj.nmequipamento }}</option>
        </select>
    </div>
</template>

<script>
import { onMounted, ref, watch } from "vue";
import { genericPost } from "@/funcoes/api";
import { globalStore } from "@/globals";

export default {
    props: {
        ckAddTodos: {type: Boolean, default: false},
        ckPesquisar: {type: Boolean, default: true},
        descCaption: {type: String, default: 'Equipamento'},
        flagTipo: {type: Number, default: 0},
        idGrupo: {type: Number, default: 0},
        equipamentoSelecionado: {type: Number, default: null,},
    },
    setup(props, { emit }) {
        const lsDados = ref([]);
        const isLoading = ref(false);

        const fetchData = async () => {
            try {
                isLoading.value=true;
                const response = await genericPost('/listas/equipamentos', {
                    idmunicipio: globalStore.idmunicipio, 
                    idequipamentogrupo: props.idGrupo,
                    ativo: 'S',
                    tipo: props.flagTipo});
                lsDados.value = response;
                if (props.ckAddTodos){lsDados.value.unshift({ id: 0, nmequipamento: 'TODOS' });}
            } catch (error) {console.error("Erro de conexão com a API:", error);
            }finally{isLoading.value=false;}
        };

        const atualizarObj = (event) => {
            const selectedId = Number(event.target.value);
            emit("update:equipamentoSelecionado", selectedId);

            const selectedObj = lsDados.value.find((obj) => obj.id === selectedId);
            if (selectedObj) {emit("update:equipamentoSelecionadoObj", selectedObj);}
        };

        onMounted(() => {
            if (props.ckPesquisar){
                fetchData();
            }
        });

        watch(
            () => props.ckPesquisar,
            (newVal, oldVal) => {
                if (newVal !== oldVal || newVal) {
                    fetchData();
                }
            }
        );

        watch(
            () => props.idGrupo,
            (newVal, oldVal) => {
                if (newVal !== oldVal) {
                    fetchData();
                }
            }
        );

        return {lsDados, isLoading, atualizarObj};
    },
};
</script>
  