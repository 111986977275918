<template>
    <div class="layout">
        <aside class="sidebar" :class="{ collapsed: isCollapsed }">
            <ul>
                <li @click="toggleMenu">
                    <img :src="menuIcon" alt="Toggle Menu" />
                    <span>{{ isCollapsed ? 'Expandir' : 'Recolher' }}</span>
                </li>

                <li @mouseover="showCadastrosMenu = true" @mouseleave="showCadastrosMenu = false">
                    <img src="@/assets/icons/menuOpcoes.svg" />
                    <span>Cadastros</span>
                    <div v-if="showCadastrosMenu" class="submenu" :class="{ 'relative': isCollapsed }">
                        <ul>
                            <li @click="selectOption('BairrosTabPage', 'BAIRROS')">
                                <span>Bairros</span>
                            </li>
                            <li @click="selectOption('LogradourosTabPage', 'LOGRADOUROS')">
                                <span>Logradouros</span>
                            </li>
                            <li @click="selectOption('ObrasTpServicosTabPage', 'SERVIÇOS SETOR DE OBRAS')">
                                <span>Serviços</span>
                            </li>
                            <li @click="selectOption('UsuariosTabPage', 'USUÁRIOS')">
                                <span>Usuários</span>
                            </li>
                        </ul>
                    </div>
                </li>

                <li @click="setComponenteAtivo('IpOsEditPage', 'ABERTURA DE CHAMADO')">
                    <img src="@/assets/icons/menuCall.svg" />
                    <span>Abrir Chamado</span>
                </li>

                <li @click="setComponenteAtivo('IpOsSolicitacoesTabPage', 'SERVIÇOS SOLICITADOS')">
                    <img src="@/assets/icons/menuDados.svg" />
                    <span>Serviços Solicitados</span>
                </li>

                <li @click="setComponenteAtivo('IpOsAdminTabPage', 'ORDENS DE SERVIÇO')">
                    <img src="@/assets/icons/menuIpOs.svg" />
                    <span>Ordens de Serviço</span>
                </li>

                <li @click="setComponenteAtivo('IpOsMapaExecutarPage', 'MAPA - SERVIÇOS À EXECUTAR')">
                    <img src="@/assets/icons/menuMap.svg" />
                    <span>Mapa Serviços à Executar</span>
                </li>

                <li @mouseover="showCadastrosRelatorios = true" @mouseleave="showCadastrosRelatorios = false">
                    <img src="@/assets/icons/menuPrinter.svg" />
                    <span>Relatórios</span>
                    <div v-if="showCadastrosRelatorios" class="submenu" :class="{ 'relative': isCollapsed }">
                        <ul>
                            <li @click="selectOption('IpOSGeralReportPage', 'LISTAGEM GERAL DE O.S. EMITIDAS')">
                                <span>Listagem Geral</span>
                            </li>
                            <li @click="selectOption('IpOSMaterialConsumidoReportPage', 'TOTAL DE MATERIAL CONSUMIDO')">
                                <span>Total Material Consumido</span>
                            </li>
                        </ul>
                    </div>
                </li>

                <li @click="setComponenteAtivo('UsuarioViewPage', 'DADOS DO USUÁRIO')">
                    <img src="@/assets/icons/menuUser.svg" />
                    <span>Meus Dados</span>
                </li>

                <li @click="confirmLogout">
                    <img src="@/assets/icons/menuLogout.svg" />
                    <span>Desconectar</span>
                </li>

                <li @click="goHomeOS">
                    <img src="@/assets/icons/menuTransfer.svg" />
                    <span>Módulo O.S.</span>
                </li>
            </ul>
        </aside>

        <main class="content">
            <header class="header">
                <div class="header-title">{{ nmTitulo ? nmTitulo : 'GESTÃO SECRETARIA DE OBRAS' }}</div>
                <div style="align-items: center; display: flex;">
                    <div class="user-info">
                        <div class="user-name">{{ loginStore.userData.nome }}</div>
                        <div class="establishment-name">{{ loginStore.userData.nmmunicipio }}</div>
                    </div>
                    <div class="token-info">{{ timeRemaining }}</div>
                </div>
            </header>
            <div class="main-content">
                <slot>
                    <div v-if="nmComponenteAtivo=='BairrosTabPage'"><BairrosTabPage @close="setComponenteAtivo(null, null)"/></div>
                    <div v-else-if="nmComponenteAtivo=='IpOsAdminTabPage'"><IpOsAdminTabPage @close="setComponenteAtivo(null, null)"/></div>
                    <div v-else-if="nmComponenteAtivo=='IpOsEditPage'"><IpOsEditPage @close="setComponenteAtivo(null, null)"/></div>
                    <div v-else-if="nmComponenteAtivo=='IpOSGeralReportPage'"><IpOSGeralReportPage @close="setComponenteAtivo(null, null)"/></div>
                    <div v-else-if="nmComponenteAtivo=='IpOsMapaExecutarPage'"><IpOsMapaExecutarPage/></div>
                    <div v-else-if="nmComponenteAtivo=='IpOSMaterialConsumidoReportPage'"><IpOSMaterialConsumidoReportPage @close="setComponenteAtivo(null, null)"/></div>
                    <div v-else-if="nmComponenteAtivo=='IpOsSolicitacoesTabPage'"><IpOsSolicitacoesTabPage @close="setComponenteAtivo(null, null)"/></div>
                    <div v-else-if="nmComponenteAtivo=='LogradourosTabPage'"><LogradourosTabPage @close="setComponenteAtivo(null, null)"/></div>
                    <div v-else-if="nmComponenteAtivo=='ObrasTpServicosTabPage'"><ObrasTpServicosTabPage @close="setComponenteAtivo(null, null)"/></div>
                    <div v-else-if="nmComponenteAtivo=='UsuariosTabPage'"><UsuariosTabPage @close="setComponenteAtivo(null, null)"/></div>
                    <div v-else-if="nmComponenteAtivo=='UsuarioViewPage'"><UsuarioViewPage :idView=loginStore.userData.idusuario @close="setComponenteAtivo(null, null)"/></div>
                    <div v-else><IpOSAdminDash /></div>
                </slot>
            </div>
        </main>
    </div>
</template>

<script>
import { ref, onMounted, onBeforeUnmount } from 'vue';
import { jwtDecode } from 'jwt-decode';
import { useLoginStore } from '@/store/login';
import { useRouter } from 'vue-router';

import BairrosTabPage from '../cadastros/tabelas/BairrosTabPage.vue';
import IpOSAdminDash from '../dashboard/IpOSAdminDash.vue';
import IpOsAdminTabPage from '../ip/IpOsAdminTabPage.vue';
import IpOsEditPage from '../ip/IpOsEditPage.vue';
import IpOSGeralReportPage from '../reports/IpOSGeralReportPage.vue';
import IpOsMapaExecutarPage from '../ip/IpOsMapaExecutarPage.vue';
import IpOSMaterialConsumidoReportPage from '../reports/IpOSMaterialConsumidoReportPage.vue';
import IpOsSolicitacoesTabPage from '../ip/IpOsSolicitacoesTabPage.vue';
import LogradourosTabPage from '../cadastros/tabelas/LogradourosTabPage.vue';
import ObrasTpServicosTabPage from '../cadastros/tabelas/ObrasTpServicosTabPage.vue';
import UsuariosTabPage from '../cadastros/tabelas/UsuariosTabPage.vue';
import UsuarioViewPage from '../cadastros/visualizacoes/UsuarioViewPage.vue';

export default {
    components: {
        BairrosTabPage,
        IpOSAdminDash,
        IpOsAdminTabPage,
        IpOsEditPage,
        IpOSGeralReportPage,
        IpOsMapaExecutarPage,
        IpOSMaterialConsumidoReportPage,
        IpOsSolicitacoesTabPage,
        LogradourosTabPage,
        ObrasTpServicosTabPage,
        UsuariosTabPage,
        UsuarioViewPage
    },
    computed: {
        menuIcon() {
            return this.isCollapsed
                ? require('@/assets/icons/menuRight.svg')
                : require('@/assets/icons/menuLeft.svg');
        }
    },
    setup() {
        const isCollapsed = ref(true);
        const nmComponenteAtivo = ref(null);
        const nmTitulo = ref(null);
        const router = useRouter();
        const showCadastrosMenu = ref(false);
        const showCadastrosRelatorios = ref(false);
        const timeRemaining = ref(null);
        const loginStore = useLoginStore();
        let timer = null;

        const confirmLogout = () => {resetLogin();}

        const goHomeOS = () =>{
            router.push('/main-os');
        }

        const resetLogin = () => {
            loginStore.clearLogin();
            router.push('/login');
        }

        const toggleMenu = () => { isCollapsed.value = !isCollapsed.value; }

        const selectOption = (page, titulo) => {
            nmComponenteAtivo.value = page;
            nmTitulo.value = titulo;
            showCadastrosMenu.value = false; // Fecha o submenu após a seleção
            showCadastrosRelatorios.value=false;
        }

        const setComponenteAtivo = (nome, titulo) => {
          nmComponenteAtivo.value = nome;
          nmTitulo.value = titulo;
        }

        const updateTimeRemaining = () => {
            const token = localStorage.getItem('jwtToken');
            if (token){
                try{
                    const decodedToken = jwtDecode(token);
                    const expiration = decodedToken.exp * 1000;
                    const now = Date.now();
                    const difference = expiration - now;

                    if (difference > 0){
                        const hours         = Math.floor((difference / (1000 * 60 * 60)) % 24);
                        const minutes       = Math.floor((difference / (1000 * 60)) % 60);
                        const seconds       = Math.floor((difference / 1000) % 60);
                        const formattedTime = [
                            hours.toString().padStart(2, '0'),
                            minutes.toString().padStart(2, '0'),
                            seconds.toString().padStart(2, '0')
                        ].join(':');
                        timeRemaining.value = formattedTime;
                    }else{
                        timeRemaining.value = "Token expirado";
                        clearInterval(timer);
                        // TODO: REDIRECIONAR PARA TELA DE LOGIN OU EMITIR ALERTA E REATIVAR A SESSÃO
                    }
                }catch(error){
                    console.error('Erro ao decodificar o token:', error);
                    timeRemaining.value = "Erro ao verificar o token";
                    clearInterval(timer);
                }
            } else {
                timeRemaining.value = "Token não encontrado";
                clearInterval(timer);
                // TODO: REDIRECIONAR PARA TELA DE LOGIN OU EMITIR ALERTA E REATIVAR A SESSÃO
            }
        };

        onMounted(() => {
            updateTimeRemaining();
            timer = setInterval(updateTimeRemaining, 1000);
        });

        onBeforeUnmount(() => {
            clearInterval(timer);
        });

        return {
            isCollapsed,
            loginStore,
            nmComponenteAtivo,
            nmTitulo,
            showCadastrosMenu,
            showCadastrosRelatorios,
            timeRemaining,
            confirmLogout,
            goHomeOS,
            toggleMenu,
            selectOption,
            setComponenteAtivo
        };
    }
};
</script>

<style scoped src="@/assets/css/main.css"></style>
