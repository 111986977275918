import { reactive } from 'vue';

export const criarMunicipioConfig = () => {
    return reactive({
        id: 0,
        appcli_agenda_publica: 0,
        appcli_solicitar_os: 0,
        ckaguardandoexecucao: 'N',
        ckbaixa_hodometro: 'S',
        ckcalculo_horimetro: 'S',
        cklibera_execucao_pagto_parcial: 'N',
        cklibera_pagamento_encerra_os: 'N',
        cklibera_pagamento_inclusao: 'N',
        ckoperador_ossmall: 'N',
        ckpagtoaberto: 'N',
        ckpermitir_parcelar: 'N',
        ckprodutor: 'N',
        ckvendas: 'N',
        cobr_prazo_inicial: 0,
        latitude: 0,
        longitude: 0,
        mntolerancia_maior: 0,
        mntolerancia_menor: 0,
        osrecalcular_apos_encerramento: 'N',
        rptitulo: '',
        rpsubtitulo: '',
        tppagamento: 0,
        xtppagamento: '',
        idusuario: 0
    });
};