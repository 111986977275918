<template>
    <div v-if="isLoading" class="loading-overlay"><p>Carregando...</p></div>
    <MensagemAlerta class="row-space" v-if="errorMessage" :mensagem="errorMessage" />


    <div v-show="!showAgendamento">
        <div class="form-edit-page row-space">
            <div class="row">
                <KeepAlive>
                    <div class="form-item">
                        <EquipamentoGrupoSelect class="form-item" v-model:grupoSelecionado="idGrupo" />
                    </div>
                </KeepAlive>
                
                <button type="button" class="buttom-icon--search" @click="lerDados" />
                <button type="button" class="buttom-icon--close" @click="closeForm" />
            </div>
        </div>
        
        <table>
            <thead>
                <tr>
                    <th class="col-width-medium">Grupo</th>
                    <th class="col-width-integer">OS N/D</th>
                    <th class="col-width-number">Horas N/D</th>
                    <th>Equipamento</th>
                    <th class="col-width-integer">OS Equip</th>
                    <th class="col-width-number">Horas Equip</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(obj, index) in lsDados" :key="obj.id" :class="{'even-row': index % 2 === 0, 'odd-row': index % 2 !== 0, 'clickabe-row': true}"
                    @click="setAgendamento(obj)">
                    <td class="col-width-medium">{{ obj.nmgrupo }}</td>
                    <td class="col-width-integer right">{{ obj.nd_qtdd }}</td>
                    <td class="col-width-number right">{{ formatDecimal(obj.nd_horas) }}</td>
                    <td>{{ obj.nmequipamento }}</td>
                    <td class="col-width-integer right">{{ obj.agenda_qtdd }}</td>
                    <td class="col-width-number right">{{ formatDecimal(obj.agenda_horas) }}</td>
                </tr>
            </tbody>
        </table>


        <div v-if="showPagination" class="pagination">
            <button :disabled="currentPage === 1" class="buttom-pagination--first" @click="currentPage=1; updatePaginatedData();" />
            <button :disabled="currentPage === 1" class="buttom-pagination--prior" @click="currentPage--; updatePaginatedData();" />

            <div class="field-label field-label-center">{{ currentPage }} de {{ Math.ceil(lsDadosMain.length / itemsPerPage) }}</div>

            <button :disabled="currentPage === Math.ceil(lsDadosMain.length / itemsPerPage)" class="buttom-pagination--next" @click="currentPage++; updatePaginatedData();" />
            <button :disabled="currentPage === Math.ceil(lsDadosMain.length / itemsPerPage)" class="buttom-pagination--last" @click="currentPage = Math.ceil(lsDadosMain.length / itemsPerPage); updatePaginatedData();" />
        </div>
    </div>

    <div v-if="showAgendamento">
        <GaragemAgendamentoEquipamentoPage 
            :idEquipamento="idEquipamento" 
            :nmEquipamento="nmEquipamento" 
            :idEquipamentoGrupo="idGrupo" 
            :nmEquipamentoGrupo="nmGrupo" 
            @close="showAgendamento=false"
            @save="lerDados"/>
    </div>
</template>


<script>
import { ref } from 'vue';
import { formatDecimal } from '@/funcoes/utils';
import { genericPost } from '@/funcoes/api'; 
import { globalStore } from '@/globals';
import EquipamentoGrupoSelect from '@/selects/EquipamentoGrupoSelect.vue';
import GaragemAgendamentoEquipamentoPage from './GaragemAgendamentoEquipamentoPage.vue';
import MensagemAlerta from '@/components/MensagemAlerta.vue';

export default{
    emits: ['close'],
    components:{ EquipamentoGrupoSelect, GaragemAgendamentoEquipamentoPage, MensagemAlerta },

    setup(_, { emit }){
        const errorMessage = ref(null);
        const idEquipamento = ref(null);
        const nmEquipamento = ref(null);
        const idGrupo = ref(null);
        const nmGrupo = ref(null);
        const isLoading = ref(false);
        const lsDados = ref([]);
        const lsDadosMain = ref([]);
        const showAgendamento = ref(false);
        const showPagination = ref(false);

        const currentPage = ref(1);
        const itemsPerPage = ref(globalStore.itensPorPagina);

        const closeForm = () => {emit('close');}

        const lerDados = async() => {
            if (!idGrupo.value){errorMessage.value = 'Informe um grupo de equipamento'}
            else{
                try{
                    errorMessage.value = null;
                    isLoading.value = true;
                    const req = await genericPost('/os/garagem/demanda', {
                        idmunicipio: globalStore.idmunicipio,
                        idequipamentogrupo: idGrupo.value});
                    lsDadosMain.value = req;
                    currentPage.value=1;
                    showPagination.value = (lsDadosMain.value.length > itemsPerPage.value);
                    updatePaginatedData();
                } catch (error){errorMessage.value=error;
                } finally {isLoading.value = false;}
            }
        }

        const setAgendamento = (obj) =>{
            idEquipamento.value         = obj.id;
            nmEquipamento.value         = obj.nmequipamento;
            idGrupo.value               = obj.idgrupo;
            nmGrupo.value               = obj.nmgrupo;
            showAgendamento.value       = true;
        }

        const updatePaginatedData = () => {
            const startIndex = (currentPage.value - 1) * itemsPerPage.value;
            const endIndex = (startIndex + itemsPerPage.value);
            lsDados.value = lsDadosMain.value.slice(startIndex, endIndex);
        }

        return { 
            errorMessage, idEquipamento, nmEquipamento, idGrupo, nmGrupo, isLoading, lsDados, lsDadosMain, showAgendamento,
            currentPage, itemsPerPage, showPagination,
            closeForm, formatDecimal, lerDados, setAgendamento, updatePaginatedData };
    }
}
</script>
