export default {
    mounted(el) {
        el.addEventListener("input", () => {
            // Remove caracteres não numéricos
            let valor = el.value.replace(/\D/g, "");
    
            // Formata como HH:mm
            if (valor.length >= 3) {
                valor = valor.replace(/^(\d{2})(\d)/, "$1:$2");
            }
    
            // Limita ao formato HH:mm
            valor = valor.slice(0, 5);
    
            // Evita disparar eventos desnecessários (apenas se o valor mudou)
            if (el.value !== valor) {
                el.value = valor;
        
                // Dispara o evento input para atualizar o modelo no Vue
                const event = new Event("input", { bubbles: true });
                el.dispatchEvent(event);
            }
        });
    },
};
  